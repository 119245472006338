export var logEvent = function (_a) {
    var eventName = _a.eventName, params = _a.params;
    // GA4でのイベントパラメタの長さの上限は100文字
    // 収集と設定の上限（ https://support.google.com/analytics/answer/9267744?hl=ja ）
    var maxEventParamsLength = 100;
    var stringifiedParams = JSON.stringify(params);
    if (stringifiedParams.length > maxEventParamsLength) {
        throw new Error('[analytics] payload is too long');
    }
    var payload = {
        event: 'custom_event',
        custom_event_name: eventName,
        custom_event_params: stringifiedParams,
    };
    // eslint-disable-next-line no-console
    console.info(JSON.stringify(payload, null, 2));
    dataLayer.push(payload);
};
