import { footerLists } from '@main/app/features/footer/menu-list';
var Component = function () {
    return (<div>
      <div className="h-[3px] bg-orange-600"/>

      <div className="bg-gray-300">
        <div className="mx-auto px-5 pt-6 pb-12 text-xs lg:flex lg:justify-between xl:max-w-screen-xl">
          <div className="mb-5 flex flex-wrap justify-center lg:mb-0">
            {footerLists.map(function (menuList) { return (<a key={menuList.url} href={menuList.url} className="mb-1 block border-r border-solid border-gray-400 px-3 last:border-0 sm:mb-0">
                {menuList.name}
              </a>); })}
          </div>
          <div className="text-center">
            &copy;2013-{new Date().getFullYear()} 株式会社シェークハンズ
          </div>
        </div>
      </div>
    </div>);
};
export default Component;
