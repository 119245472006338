import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hook';
var Component = function (props) {
    var title = '退会手続き';
    var _a = useHook(props), submit = _a.submit, status = _a.status, isSubmitting = _a.isSubmitting;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      {/* Stripe系統：解約手続きを行っていない */}
      {status === 'stripe_require_cancel' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            サービスが有効状態であるため、退会手続きを行うことができません。
          </span>
          退会をご希望の場合、
          <a href="/billing/cancel" className="underline">
            こちら
          </a>
          からサービスの解約のお手続きをお願いいたします。
        </div>)}

      {/* Stripe系統：無料体験の解約手続きを行っていない */}
      {status === 'stripe_require_cancel_trial' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            無料体験が有効状態のため、退会手続きを行うことができません。
          </span>
          退会をご希望の場合、
          <a href="/billing/cancel" className="underline">
            こちら
          </a>
          からサービスの解約のお手続きをお願いいたします。
        </div>)}

      {/* Stripe系統：エラーがある */}
      {status === 'stripe_billing_restricted' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            ご利用料金が未払いの場合は退会手続きはできません。
          </span>
          クレジットカードへの請求が失敗しております。
          <br />
          お手数ですが、
          <a href="/billing/error" className="underline">
            こちら
          </a>
          をご確認ください
        </div>)}

      {/* GMO系統：解約手続きを行っていない */}
      {status === 'gmo_require_cancel' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            サービスが有効状態であるため、退会手続きを行うことができません。
          </span>
          退会をご希望の場合、
          <a href="/user_billings/cancel" className="underline">
            こちら
          </a>
          からサービスの解約のお手続きをお願いいたします。
        </div>)}

      {/* GMO系統：クレジットカードエラーがある */}
      {status === 'gmo_credit_card_error' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            ご利用料金が未払いの場合は退会手続きはできません。
          </span>
          クレジットカードへの請求が失敗しております。
          <br />
          お手数ですが、
          <a href="/user_billings/new" className="underline">
            こちら
          </a>
          より、クレジットカード情報の再登録をお願いいたします。
        </div>)}

      {/* 退会可能 */}
      {status === 'resignable' && (<>
          <p className="mb-4 text-base">
            退会手続きを行うことで、今月末をもってご登録いただいた情報が全て削除されます。
            <br />
            削除された情報は元には戻せませんのでご注意ください。
            <br />
            この度はシェークハンズをご利用頂きまして誠にありがとうございました。
          </p>

          <div className="flex justify-center">
            <Button type="button" onClick={submit} caption="退会手続きを行う" state={isSubmitting ? 'submitting' : 'enabled'}/>
          </div>
        </>)}

      {/* 既に手続き済み */}
      {status === 'already_resigned' && (<p className="text-base">
          退会手続き済みです。
          <br />
          退会手続きの取り消しをご希望の方は、
          <a href="/contact/new" className="underline">
            こちらのお問い合わせフォーム
          </a>
          からご連絡ください。
        </p>)}
    </PageLayout>);
};
export default Component;
