import { Accordion, AccordionItem, AccordionButton, AccordionPanel, } from '@chakra-ui/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sideMenuLists } from '@main/app/features/header/menu-list';
import classnames from 'classnames';
import { clickOpenSideMenuRootCategoryButton, clickVisitCategoryPageLink, } from '@/utils/analytics/log-event';
var Component = function (_a) {
    var sideMenuItems = _a.sideMenuItems;
    return (<>
      {sideMenuLists.map(function (menuList) { return (<a key={menuList.title} href={menuList.url} className={classnames('flex items-center justify-between sm:p-2', menuList.url && 'rounded active:bg-gray-200')}>
          <div className="flex items-center">
            <FontAwesomeIcon fixedWidth icon={menuList.icon} className="mr-3 text-xl text-orange-600"/>
            <div className="text-sm font-bold text-gray-700">
              {menuList.title}
            </div>
          </div>
        </a>); })}
      <div className="pl-11">
        {sideMenuItems.map(function (menuItem, index) { return (<Accordion allowToggle allowMultiple key={menuItem.categorySlug} defaultIndex={index === 0 ? 0 : undefined} className="border-t border-solid border-gray-300 last:border-b">
            <AccordionItem>
              {function (_a) {
                var isExpanded = _a.isExpanded;
                return (<>
                  <AccordionButton p={0} className="justify-between">
                    <div className="py-2 text-sm" onClick={function () {
                        return clickOpenSideMenuRootCategoryButton(menuItem.categorySlug);
                    }}>
                      {menuItem.caption}
                    </div>
                    <FontAwesomeIcon fixedWidth className="text-gray-600" icon={isExpanded ? faCaretUp : faCaretDown}/>
                  </AccordionButton>
                  <AccordionPanel p={0}>
                    {menuItem.subItems.map(function (subItem) { return (<a key={subItem.categorySlug} href={"/categories/".concat(subItem.categorySlug)} onClick={function () {
                            return clickVisitCategoryPageLink(subItem.categorySlug);
                        }} className="flex items-center justify-between rounded py-2 text-sm active:bg-gray-200">
                        {subItem.caption}
                        <div className="ml-3 whitespace-nowrap text-xs font-bold text-gray-500">
                          {subItem.articleCount}本
                        </div>
                      </a>); })}
                  </AccordionPanel>
                </>);
            }}
            </AccordionItem>
          </Accordion>); })}
      </div>
    </>);
};
export default Component;
