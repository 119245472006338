import { normalize, } from '@main/app/adapters/user-feature-challenge';
import Image from '@main/ui/atoms/Image';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import Triangle from '@main/ui/atoms/Triangle';
import HorizontalScrollList from '@main/ui/molecules/HorizontalScrollList';
import { clickVisitArticlePageButton, clickVisitChallengePageButton, } from '@/utils/analytics/log-event';
var Component = function (_a) {
    var props = _a.props;
    var items = normalize(props);
    return (<>
      {items.length > 0 && (<div>
          <SectionHeader caption="進行中のレッスンコース">
            <a href="/features/challenge" onClick={function () {
                clickVisitChallengePageButton();
            }} className="flex items-center p-1 text-xs font-thin leading-none text-gray-500 no-underline">
              すべて見る
              <Triangle width={8} colorClass="bg-gray-500"/>
            </a>
          </SectionHeader>

          <HorizontalScrollList items={items.map(function (item) { return (<a href={"/features/".concat(item.feature.id)} key={item.feature.id} className="block w-full no-underline" onClick={function () {
                    return clickVisitArticlePageButton('in_progress_lesson_course');
                }}>
                <div className="mb-2">
                  <Image url={item.feature.thumbnailUrl}/>
                </div>

                <div className="mb-2 text-sm font-bold text-gray-800">
                  {item.feature.title}
                </div>

                <div className="flex w-full items-center">
                  <div className="mr-2 text-xs font-thin leading-none text-gray-500">
                    {item.completeRate}% 完了
                  </div>

                  <div className="relative h-2.5 flex-1 overflow-hidden rounded-full bg-gray-200">
                    <div className="h-full bg-green-300" style={{ width: "".concat(item.completeRate, "%") }}></div>
                  </div>
                </div>
              </a>); })}/>
        </div>)}
    </>);
};
export default Component;
