import { z } from 'zod';
var optionsSchema = z.array(z.object({
    id: z.number(),
    name: z.string(),
}));
export var propsSchema = z.object({
    isFirstAnswer: z.boolean(),
    // 数値項目
    birthYear: z.number().nullable(),
    // 単一選択項目
    gender: z.number().nullable(),
    career: z.number().nullable(),
    howToKnow: z.number().nullable(),
    playStyle: z.number().nullable(),
    playerType: z.number().nullable(),
    playFrequency: z.number().nullable(),
    // 複数選択項目
    expectations: z.array(z.number()),
    // 選択肢
    genderOptions: optionsSchema,
    careerOptions: optionsSchema,
    howToKnowOptions: optionsSchema,
    playStyleOptions: optionsSchema,
    playerTypeOptions: optionsSchema,
    expectationsOptions: optionsSchema,
    playFrequencyOptions: optionsSchema,
});
