import { z } from 'zod';
export var schema = z.object({
    email: z.string().min(1, { message: '入力してください。' }),
    lastName: z
        .string()
        .min(1, { message: '入力してください。' })
        .max(14, { message: '14文字以内で入力してください。' }),
    firstName: z
        .string()
        .min(1, { message: '入力してください。' })
        .max(14, { message: '14文字以内で入力してください。' }),
    password: z
        .string()
        .min(6, { message: '6文字以上で入力してください' })
        .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/, {
        message: '半角の英数字・記号で入力してください。',
    }),
    acceptTerms: z
        .boolean()
        .refine(function (value) { return value; }, { message: '利用規約への同意は必須です。' }),
    billingPlan: z.enum([
        'billing_plan_club_standard',
        'billing_plan_club_platinum',
    ]),
});
