var Component = function (_a) {
    var id = _a.id, title = _a.title, thumbnailUrl = _a.thumbnailUrl, publishedAt = _a.publishedAt;
    return (<a href={"/posts/".concat(id)} className="flex w-full">
      <img alt="" loading="lazy" src={thumbnailUrl} className="mr-2 block aspect-square w-24 rounded-lg border border-solid border-gray-300 object-cover"/>
      <div className="flex-1">
        <div className="mb-1 text-sm font-bold text-gray-800">{title}</div>
        <div className="text-xs text-gray-500">{publishedAt}</div>
      </div>
    </a>);
};
export default Component;
