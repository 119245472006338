import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import useHook from './hook';
var Component = function (props) {
    var title = 'サービスのご解約';
    var _a = useHook(props), submit = _a.submit, control = _a.control, isCanceled = _a.isCanceled, onOpcPlan = _a.onOpcPlan, isSubmitting = _a.isSubmitting;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      {/* 既に手続き済み */}
      {isCanceled ? (<p className="text-base">
          既に解約手続き済みです。
          <br />
          解約手続きの取り消しをご希望の場合、
          <a href="/contact/new" className="underline">
            お問い合わせフォーム
          </a>
          からご連絡ください。
        </p>) : onOpcPlan ? (<p className="text-base">
          オンラインプライベートコーチサービスをご契約中のため、本フォームから解約のお手続きを行なっていただくことはできません。
          <br />
          オンラインプライベートコーチサービスの担当者にご連絡いただくか、こちらの
          <a href="/contact/new" className="underline">
            お問い合わせフォーム
          </a>
          からご連絡ください。
        </p>) : (<form onSubmit={submit}>
          <p className="mb-4 text-base">
            この度はご利用頂きまして誠にありがとうございました。
            <br />
            サービス改善のため、お手数ですが解約理由をお聞かせください。
            <br />
          </p>

          <Controller name="reason" control={control} render={function (_a) {
                var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
                return (<div className="mb-4 flex border border-solid border-gray-400">
                <p className="flex items-center border-r border-solid border-gray-400 bg-gray-100 p-4 text-base text-gray-700">
                  解約理由
                </p>

                <div className="flex-1 p-2">
                  <textarea rows={3} ref={ref} value={value} onChange={onChange} className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-200 py-1 px-2 text-base !shadow-none outline-none"/>

                  {error && (<div className="mt-1 text-sm text-red-500">
                      {error.message}
                    </div>)}
                </div>
              </div>);
            }}/>

          <div className="flex justify-center">
            <Button type="submit" caption="送信する" state={isSubmitting ? 'submitting' : 'enabled'}/>
          </div>
        </form>)}
    </PageLayout>);
};
export default Component;
