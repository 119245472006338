export var normalize = function (props) {
    return props.challenges.map(function (challenge) {
        var feature = props.features.find(function (f) { return f.id === challenge.featureId; });
        if (!feature) {
            throw new Error('feature not found');
        }
        var thumbnailUrl = props.mediumMap[feature.id];
        var allArticleIds = props.featureSectionRelations
            .filter(function (_a) {
            var featureId = _a.featureId;
            return featureId === feature.id;
        })
            .map(function (relation) {
            return props.featureSectionArticleRelations
                .filter(function (_a) {
                var featureSectionId = _a.featureSectionId;
                return featureSectionId === relation.featureSectionId;
            })
                .map(function (_a) {
                var articleId = _a.articleId;
                return articleId;
            });
        })
            .flat();
        var completedArticleIds = allArticleIds.filter(function (id) {
            return props.completedArticleIds.includes(id);
        });
        var allArticleCount = allArticleIds.length;
        var completedArticleCount = completedArticleIds.length;
        var completeRate = allArticleCount > 0
            ? Math.round((completedArticleCount * 100) / allArticleCount)
            : 0;
        return {
            feature: {
                id: feature.id,
                title: feature.title,
                thumbnailUrl: thumbnailUrl,
            },
            completeRate: completeRate,
        };
    });
};
