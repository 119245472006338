import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useState } from 'react';
import FormInputLayout from './FormInputLayout';
var Component = forwardRef(function (props, ref) {
    var _a = useState(false), isPasswordVisible = _a[0], setIsPasswordVisible = _a[1];
    return (<div>
      <FormInputLayout ref={ref} type={isPasswordVisible ? 'text' : 'password'} autoComplete="new-password" {...props}>
        <button type="button" onClick={function () { return setIsPasswordVisible(!isPasswordVisible); }} className="mr-2 w-7 flex-none text-xl text-gray-400">
          <FontAwesomeIcon fixedWidth={true} icon={isPasswordVisible ? faEye : faEyeSlash}/>
        </button>
      </FormInputLayout>
    </div>);
});
export default Component;
