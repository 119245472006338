import { Spinner } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
var Component = function (_a) {
    var icon = _a.icon, caption = _a.caption, children = _a.children, onClicked = _a.onClicked, _b = _a.classNames, classNames = _b === void 0 ? '' : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c;
    return (<button onClick={function () { return onClicked === null || onClicked === void 0 ? void 0 : onClicked(); }} className={twMerge('relative h-[38px]', caption ? 'px-4 rounded' : 'min-w-[38px] w-[38px] rounded-full', classNames)}>
      <div className={classnames('flex items-center justify-center gap-x-2 font-bold', isLoading && 'invisible')}>
        <FontAwesomeIcon icon={icon} size={caption ? '1x' : 'lg'}/>
        {caption && (<span className="select-none whitespace-nowrap">{caption}</span>)}
        {children}
      </div>

      {isLoading && (<div className="absolute inset-0 flex h-full w-full items-center justify-center">
          <Spinner size="sm"/>
        </div>)}
    </button>);
};
export default Component;
