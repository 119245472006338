import { articleSchema } from '@main/app/features/article/article.entity';
import { liveScheduleSchema } from '@main/app/features/live-schedule/live-schedule.entity';
import { newsMovieSchema } from '@main/app/features/news-movie/news-movie.entity';
import { postSchema } from '@main/app/features/post/post.entity';
import { sideMenuItemSchema } from '@main/app/features/sidemenu/sidemenu.entity';
import { z } from 'zod';
export var propsSchema = z.object({
    inProgressLessonCourseProps: z.any(),
    recommendedLessonCourseProps: z.any(),
    shandsFeedProps: z.any(),
    liveSchedules: z.array(liveScheduleSchema),
    newsMovie: newsMovieSchema.nullable(),
    recommendedArticles: z.array(articleSchema),
    recentArticles: z.array(articleSchema),
    lastWeekPopularArticles: z.array(articleSchema),
    annualPopularArticles: z.array(articleSchema),
    starterArticles: z.array(articleSchema),
    beginnerArticles: z.array(articleSchema),
    intermediateArticles: z.array(articleSchema),
    advanceArticles: z.array(articleSchema),
    recentPosts: z.array(postSchema),
    problemBasedPosts: z.array(postSchema),
    sideMenuItems: z.array(sideMenuItemSchema),
    completedArticleIds: z.array(z.number()),
    trialEndAt: z.string().nullable(),
    pendingPostModal: z
        .object({
        id: z.number(),
        title: z.string(),
        imageUrl: z.string(),
    })
        .nullable(),
});
