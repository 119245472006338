import { formatDate } from '@main/app/features/date.helper';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import Triangle from '@main/ui/atoms/Triangle';
import ArticleItem from '@main/ui/components/ArticleItem';
var Component = function (_a) {
    var url = _a.url, caption = _a.caption, articles = _a.articles, itemLogEvent = _a.itemLogEvent, isArticleCompleted = _a.isArticleCompleted;
    return (<div>
      <SectionHeader caption={caption}>
        {url && (<a href={url} className="flex items-center p-1 text-xs font-thin leading-none text-gray-500 no-underline">
            もっと見る
            <Triangle width={8} colorClass="bg-gray-500"/>
          </a>)}
      </SectionHeader>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {articles.map(function (article) { return (<ArticleItem key={article.id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={formatDate(article.publishedAt)} isCompleted={isArticleCompleted(article.id)} clicked={function () { return itemLogEvent === null || itemLogEvent === void 0 ? void 0 : itemLogEvent(); }}/>); })}
      </div>
    </div>);
};
export default Component;
