import InlineLink from '@main/ui/atoms/InlineLink';
import { ErrorCode, getErrorMessageFromCode } from '@/utils/error-message';
var Component = function (_a) {
    var errorCode = _a.errorCode;
    var errorMessage = getErrorMessageFromCode(errorCode);
    return (<div className="mb-4 rounded border-2 border-red-400 bg-red-200 p-2 text-sm md:text-base">
      <p className="text-red-600">{errorMessage}</p>
      {errorCode === ErrorCode.EmailConfirmationRequired && (<InlineLink href="/users/confirmation/new" text="メールアドレス開通確認メールの再送はこちら"/>)}
    </div>);
};
export default Component;
