var Table = function (_a) {
    var children = _a.children;
    return (<table className="w-full table-auto border-collapse">
    <tbody>{children}</tbody>
  </table>);
};
var Row = function (_a) {
    var caption = _a.caption, children = _a.children;
    return (<tr className="flex items-stretch border-b border-solid border-gray-200 first:border-t">
    <td className="w-2/6 bg-gray-100 py-4 px-5 text-sm font-bold sm:w-3/12">
      {caption}
    </td>
    <td className="flex-1 py-4 px-5 text-sm">{children}</td>
  </tr>);
};
export { Row as SectionTableRow, Table as SectionTable };
