var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react';
import { faQuestionCircle, faLightbulb, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatSentAt } from '@main/app/features/comment/comment.helper';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SideMenu from '@main/ui/components/SideMenu';
import CommentArticleOperation from '@main/ui/images/CommentArticleOperation.gif';
import CommentPageExample from '@main/ui/images/CommentPageExample.png';
import classnames from 'classnames';
import useHook from './hooks';
export var Component = function (_a) {
    var items = _a.items, totalCount = _a.totalCount, totalPage = _a.totalPage, currentPage = _a.currentPage, isModalOpen = _a.isModalOpen, onGuideButtonClicked = _a.onGuideButtonClicked, onCloseGuideButtonClicked = _a.onCloseGuideButtonClicked, sideMenuItems = _a.sideMenuItems;
    return (<>
      <PageLayout caption={"\u30E1\u30E2\u3092\u3064\u3051\u305F\u52D5\u753B ".concat(totalCount, "\u672C")} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'メモをつけた動画' },
        ]} headerContent={<button onClick={onGuideButtonClicked} type="button" className="flex items-center text-gray-500 hover:text-gray-400 active:text-gray-500">
            <FontAwesomeIcon className="mr-1" icon={faQuestionCircle} fixedWidth={true}/>
            <div className="text-sm underline">使い方を見る</div>
          </button>} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
            <SideMenu sideMenuItems={sideMenuItems}/>
          </div>}>
        <ArticleListLayout totalCount={totalCount} currentPage={currentPage} totalPage={totalPage} notFoundContent={<div className="rounded bg-gray-200 p-5 text-base text-gray-600">
              メモを書いた動画はまだありません。
            </div>}>
          {items.map(function (_a) {
            var article = _a.article, item = __rest(_a, ["article"]);
            return (<ArticleItem key={article.id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={"".concat(formatSentAt(item.updatedAt), "\u306B\u30E1\u30E2\u3092\u66F4\u65B0")} isCompleted={item.isCompleted}>
              <div className="mt-2 ml-10 rounded-md bg-[#edf2f7] py-1 px-3">
                <p className={classnames('text-xs md:text-sm', 'line-clamp-2')}>
                  {item.content}
                </p>
              </div>
            </ArticleItem>);
        })}
        </ArticleListLayout>
      </PageLayout>

      <Modal isOpen={isModalOpen} onClose={onCloseGuideButtonClicked}>
        <ModalOverlay />
        <ModalContent width="calc(100% - 20px)" maxWidth="800px">
          <ModalHeader>メモ機能の使い方</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mb-1 text-base font-bold text-gray-700">
              <FontAwesomeIcon className="mr-1" icon={faLightbulb} fixedWidth={true}/>
              動画のページで「メモする」ボタンをクリックするとメモの入力欄が表示されます。
            </div>

            <div className="pl-6">
              <img alt="" loading="lazy" src={CommentArticleOperation} className="mb-5 block w-full border border-solid border-gray-300 bg-gray-200"/>
            </div>

            <div className="mb-1 text-base font-bold text-gray-700">
              <FontAwesomeIcon className="mr-1" icon={faLightbulb} fixedWidth={true}/>
              メモを書いた動画は本ページに一覧表示されます。
            </div>

            <div className="pl-6">
              <img alt="" loading="lazy" src={CommentPageExample} className="mb-5 block w-full border border-solid border-gray-300 bg-gray-200"/>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
