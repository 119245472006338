import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import Tip from '@main/ui/atoms/Tip';
import CheckIcon from '@main/ui/components/CheckIcon';
import GradientButtonLink from '@main/ui/components/GradientButtonLink';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hook';
import thumbnailImage from './images/thumbnail.jpg';
var Component = function (props) {
    var _a;
    var articles = useHook(props).articles;
    var title = '平岡理論入門 キーポイントまとめ';
    var startFromBeginningButton = (<GradientButtonLink icon={faPlayCircle} caption="最初から見る" href={"/articles/".concat((_a = articles[0]) === null || _a === void 0 ? void 0 : _a.article.id)}/>);
    return (<PageLayout caption={title} breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: title }]}>
      <div className="mb-3 block justify-between md:mb-8 md:flex">
        <div className="flex flex-1 flex-col justify-between">
          <div className="mb-5 text-base">
            平岡理論の重要な部分をコンパクトにまとめ、より分かりやすく解説しています。理論の要点を短時間で習得できます。これから平岡理論を学ぼうとする方、復習をしたい方へおすすめです。
          </div>

          <div className="hidden max-w-[180px] md:block">
            {startFromBeginningButton}
          </div>
        </div>

        <div className="w-full overflow-hidden rounded-lg md:ml-4 md:max-w-[300px]">
          <img alt={title} loading="lazy" src={thumbnailImage} className="block w-full"/>
        </div>
      </div>

      <div className="mb-5 md:hidden">{startFromBeginningButton}</div>

      <div>
        {articles.map(function (_a) {
            var article = _a.article, isCompleted = _a.isCompleted;
            return (<a key={article.id} href={"/articles/".concat(article.id)} style={{ borderLeft: '0', borderRight: '0' }} className="flex border-t border-b-0 border-solid border-gray-200 py-4 no-underline last:border-b hover:bg-gray-100">
            <div className="mr-2 w-[160px] md:mr-5 md:w-[200px]">
              <div className="relative aspect-video overflow-hidden rounded-lg border border-solid border-gray-100 bg-gray-200">
                <img loading="lazy" alt={article.title} src={article.thumbnailUrl} className="block h-full w-full object-cover object-center"/>

                <div className="absolute top-2 right-2">
                  <CheckIcon isChecked={isCompleted}/>
                </div>

                <div className="absolute right-2 bottom-2">
                  <Tip>{article.length}</Tip>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="mb-2 text-sm font-bold text-gray-800 md:text-lg">
                {article.title}
              </div>
              <div className="max-h-[48px] overflow-hidden text-xs text-gray-600 md:max-h-full md:text-sm"></div>
            </div>
          </a>);
        })}
      </div>
    </PageLayout>);
};
export default Component;
