import { articleSchema } from '@main/app/features/article/article.entity';
import { z } from 'zod';
var itemSchema = z.object({
    article: articleSchema,
    isCompleted: z.boolean(),
});
var ownedPlaylistSchema = z.object({
    id: z.number(),
    title: z.string(),
    hasArticle: z.boolean(),
});
var playlistArticleSchema = z.object({
    id: z.number(),
    title: z.string(),
    thumbnailUrl: z.string(),
    length: z.string(),
    coachName: z.string(),
    isPublished: z.boolean(),
});
export var propsSchema = z.object({
    shandsPost: z
        .object({
        id: z.number(),
        title: z.string(),
    })
        .nullable(),
    isReachedTrialLimit: z.boolean(),
    article: articleSchema,
    isCompleted: z.boolean(),
    memo: z.string(),
    relatedItems: z.array(itemSchema),
    ownedPlaylists: z.array(ownedPlaylistSchema),
    includedLessonCourses: z.array(z.object({
        id: z.number(),
        thumbnailUrl: z.string(),
    })),
    lessonCourse: z
        .object({
        id: z.number(),
        title: z.string(),
        description: z.string(),
        thumbnailUrl: z.string(),
        isChallenging: z.boolean(),
        items: z.array(itemSchema),
        articleIdToCompletedAt: z.record(z.string().transform(function (value) { return +value; }), z.string()),
    })
        .nullable(),
    playlist: z
        .object({
        id: z.number(),
        title: z.string(),
        articles: z.array(playlistArticleSchema),
    })
        .nullable(),
});
