import PageLayout from '@main/ui/components/PageLayout';
var Component = function () {
    return (<PageLayout singleColumn caption="退会手続き完了" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: '退会手続き' },
        ]}>
      <div className="text-base">
        退会手続きが完了しました。
        <br />
        また機会がございましたら是非ご利用ください。
      </div>
    </PageLayout>);
};
export default Component;
