import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import PasswordInput from '@main/ui/components/PasswordInput';
import { Controller } from 'react-hook-form';
import useHook from './hook';
export var Component = function (props) {
    var _a = useHook(props), submit = _a.submit, control = _a.control, isSucceeded = _a.isSucceeded, isSubmitting = _a.isSubmitting, isPasswordMigrated = _a.isPasswordMigrated;
    return (<PageLayout singleColumn caption="登録情報変更" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: '登録情報変更' },
        ]}>
      <div className="mt-2 md:mt-3">
        {!isPasswordMigrated && (<div className="mb-5 rounded border border-solid border-yellow-300 bg-yellow-100 p-4 text-base text-yellow-700">
            パスワードの期限が切れましたので、再設定をお願いします。
          </div>)}

        {isSucceeded && (<div className="mb-5 rounded border border-solid border-blue-300 bg-blue-100 p-4 text-base text-blue-700">
            アカウントを更新しました。
          </div>)}

        <form onSubmit={submit} className="grid items-center gap-y-4">
          <div className="flex w-full gap-x-2 lg:gap-x-4">
            <div className="basis-1/2">
              <Controller name="lastName" control={control} render={function (_a) {
            var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
            return (<div>
                    <FormInputLayout {...field} label="姓" placeholder="卓球" type="text" error={error} isDirty={isDirty}/>
                  </div>);
        }}/>
            </div>
            <div className="basis-1/2">
              <Controller name="firstName" control={control} render={function (_a) {
            var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
            return (<div>
                    <FormInputLayout {...field} label="名" placeholder="太郎" type="text" error={error} isDirty={isDirty}/>
                  </div>);
        }}/>
            </div>
          </div>

          <div className="w-full">
            <Controller name="email" control={control} render={function (_a) {
            var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
            return (<div>
                  <FormInputLayout {...field} label="メールアドレス" placeholder="t.takkyu@shands.jp" type="email" subtitle="変更する場合、新しいメールアドレスに確認メールをお送りします" error={error} isDirty={isDirty} autoComplete="email"/>
                </div>);
        }}/>
          </div>

          <div className="w-full">
            <Controller name="password" control={control} render={function (_a) {
            var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
            return (<div>
                  <PasswordInput {...field} label="新しいパスワード（変更する場合のみ入力）" placeholder="******" subtitle="英数字6文字以上" error={error} isDirty={isDirty}/>
                </div>);
        }}/>
          </div>
          <div className="flex w-full justify-center md:mt-3">
            <div className="h-12 w-full md:w-56">
              <FormSubmitButton text="保存" isSubmitting={isSubmitting} isDisabled={isSubmitting}/>
            </div>
          </div>
        </form>
      </div>
    </PageLayout>);
};
export default Component;
