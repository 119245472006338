import { formatDate } from '@main/app/features/date.helper';
import PageLayout from '@main/ui/components/PageLayout';
var Page = function (_a) {
    var post = _a.post, blogId = _a.blogId;
    var title = post.title, date = post.date, content = post.content, featuredImageUrl = post.featuredImageUrl, categoryName = post.categoryName;
    return (<PageLayout caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: categoryName, link: "/blogs/".concat(blogId) },
        ]} singleColumn>
      <div className="mb-3 text-sm text-gray-500">{formatDate(date)}</div>

      {featuredImageUrl && (<img src={featuredImageUrl} alt={"".concat(title, "\u306E\u753B\u50CF")} className="mt-2 mb-4 block max-w-full border border-solid border-gray-300 p-1.5"/>)}

      <div 
    // eslint-disable-next-line tailwindcss/no-custom-classname
    className="auth-premium-blogs-show__content" dangerouslySetInnerHTML={{ __html: content }}/>
    </PageLayout>);
};
export default Page;
