import InlineLink from '@main/ui/atoms/InlineLink';
import { Membership } from '@/types/membership';
var Component = function (_a) {
    var membership = _a.membership;
    var content = function () {
        switch (membership) {
            case Membership.Premium:
                return (<div>
            銀行振込での決済は、6ヶ月または1年を単位とさせていただきます。
            <br />
            以下の銀行口座に6ヶ月分または1年分をお振り込みください。
            <ul className="my-2 list-disc pl-5">
              <li className="font-bold">
                月額980円 × 6ヶ月（+消費税） = 6,468円（税込）
              </li>
              <li className="font-bold">
                月額980円 × 12ヶ月 × 10%OFF （+消費税）＝
                11,642円（税込）（10%お得！）
              </li>
            </ul>
          </div>);
        }
    };
    return (<div className="grid gap-y-3 text-base">
      {content()}

      <div className="font-bold">
        楽天銀行 第二営業支店（252） 普通口座7107426 株式会社シェークハンズ
      </div>

      <div>
        入金確認後、メールにてご連絡させて頂きます。ご登録いただいたお名前でお振込をお願いします。
        <br />
        念のため、
        <InlineLink target="_blank" href="/contact/new" text="お問い合わせフォーム"/>
        より事前にご連絡いただくと確実です。
        <br />
        途中解約は承っておりません。ご注意ください。
      </div>
    </div>);
};
export default Component;
