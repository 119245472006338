import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import dayjs from '@/utils/dayjs';
import useHook from './hook';
var Component = function (props) {
    var title = 'サービスのアップグレード';
    var _a = useHook(props), submit = _a.submit, trialEndAt = _a.trialEndAt, isSubmitting = _a.isSubmitting, isCanceled = _a.isCanceled;
    return (<PageLayout caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      <div className="mb-6 text-base leading-relaxed">
        シェークハンズをご利用いただきありがとうございます。
        <br />
        お客様の無料体験期間は
        <span className="font-bold">
          {dayjs(trialEndAt).tz().format('YYYY年M月D日 H:mm')}
        </span>
        までとなっております。
        <br />
        {!isCanceled && (<>
            期限までに解約手続きを行わない場合、自動的に有料プランに移行されます。
          </>)}
      </div>

      <div className="mb-2 rounded bg-gray-200 py-2 px-3 text-base font-bold text-gray-700">
        いますぐアップグレード
      </div>

      <div className="mb-3 text-base leading-relaxed">
        サービスにご満足いただけている場合、無料体験期間の終了前に有料プランにアップグレードしていただくこともできます。
        <br />
        その場合、ご登録いただいたお支払い方法に初月分の利用料が請求され、直ちに有料プランが開始されます。
      </div>

      <div className="mb-12 flex">
        <Button type="button" onClick={submit} caption="いますぐアップグレード" state={isSubmitting ? 'submitting' : 'enabled'}/>
      </div>

      <div className="mb-2 rounded bg-gray-200 py-2 px-3 text-base font-bold text-gray-700">
        ご解約手続き
      </div>

      <div className="mb-10 text-base leading-relaxed">
        {isCanceled ? (<>
            既にご解約手続き済みです。無料体験期間終了後、サービスの利用が停止されます。
            <br />
            解約お手続きの取り消し、有料会員へのアップグレードをご希望の方は、上の「いますぐアップグレード」をご利用ください。
          </>) : (<>
            無料体験期間の終了後、有料プランへの移行をご希望されない場合は、有効期限までに
            <a className="underline" href="/billing/cancel">
              こちら
            </a>
            から手続きを行なってください。
          </>)}
      </div>
    </PageLayout>);
};
export default Component;
