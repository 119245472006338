import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import Triangle from '@main/ui/atoms/Triangle';
var Component = function (_a) {
    var posts = _a.posts;
    return (<div>
      <SectionHeader caption="課題・悩みから動画を探す">
        <a href="/category/problem-based" className="flex items-center p-1 text-xs font-thin leading-none text-gray-500 no-underline">
          もっと見る
          <Triangle width={8} colorClass="bg-gray-500"/>
        </a>
      </SectionHeader>

      <div className="grid gap-4 md:grid-cols-2">
        {posts.map(function (post) { return (<a key={post.id} href={"/posts/".concat(post.id)} className="flex items-center rounded bg-orange-50 p-3 text-base leading-tight text-orange-900 ring-1 ring-[#E1A478] active:bg-orange-200">
            <div className="line-clamp-2 mr-4  flex-1">{post.title}</div>
            <FontAwesomeIcon fixedWidth icon={faArrowCircleRight}/>
          </a>); })}
      </div>
    </div>);
};
export default Component;
