import { IconButton, MenuButton, Menu, MenuList, MenuItem, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, Button, } from '@chakra-ui/react';
import { faBars, faMinusCircle, faEllipsisH, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArticleListItem from '@main/ui/components/ArticleListItem';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
var Component = function (_a) {
    var showingArticle = _a.article, playlistId = _a.playlistId, title = _a.title, control = _a.control, isShownTitleForm = _a.isShownTitleForm, isSortable = _a.isSortable, sortableItems = _a.sortableItems, isSubmitting = _a.isSubmitting, isAlertOpen = _a.isAlertOpen, cancelRef = _a.cancelRef, setIsShownTitleForm = _a.setIsShownTitleForm, showAlert = _a.showAlert, closeAlert = _a.closeAlert, onStartSorting = _a.onStartSorting, onCancelSorting = _a.onCancelSorting, setSortableItems = _a.setSortableItems, removeItem = _a.removeItem, submitUpdatePlaylistTitle = _a.submitUpdatePlaylistTitle, submitUpdateArticlePlaylistRelations = _a.submitUpdateArticlePlaylistRelations, submitDeletePlaylist = _a.submitDeletePlaylist, reset = _a.reset;
    return (<>
      <div className="mb-6 w-full rounded border border-gray-200 md:border-solid md:p-4">
        <div className="mb-6 flex-1">
          {!isShownTitleForm ? (<div className="mb-1 text-base font-bold leading-tight text-gray-900">
              {title}
            </div>) : (<form onSubmit={submitUpdatePlaylistTitle} className="flex-col text-right">
              <p className="text-left text-base font-bold">名前</p>
              <Controller control={control} name="title" render={function (_a) {
                var field = _a.field, error = _a.fieldState.error;
                return (<>
                    <div className="mb-1 border-b-[1px] border-solid">
                      <input {...field} type="text" placeholder="プレイリストの名前を入力..." className="w-full !border-none !bg-transparent focus:!shadow-none"/>
                    </div>
                    {error && (<div className="mt-1.5 text-xs text-red-500">
                        {error.message}
                      </div>)}
                  </>);
            }}/>
              <div>
                <button disabled={isSubmitting} onClick={function () {
                setIsShownTitleForm(false);
                reset();
            }} className={classnames('rounded px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200', isSubmitting && 'cursor-default text-gray-300')}>
                  キャンセル
                </button>
                <button type="submit" disabled={isSubmitting} className={classnames('px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200', isSubmitting && 'cursor-default text-gray-300')}>
                  保存
                </button>
              </div>
            </form>)}

          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-500">
              全{sortableItems.length}動画
            </div>
            {!isShownTitleForm && !isSortable && (<Menu>
                <MenuButton fontSize="xl" as={IconButton} icon={<FontAwesomeIcon icon={faEllipsisH} className="text-gray-500"/>} bgColor="transparent" _hover={{ bgColor: 'gray.100' }}/>
                <MenuList paddingX={1} border="1px" borderColor="gray.100">
                  <MenuItem onClick={function () { return setIsShownTitleForm(true); }}>
                    タイトルを変更する
                  </MenuItem>
                  <MenuItem onClick={onStartSorting}>
                    動画の並び順を変更/削除する
                  </MenuItem>
                  <MenuItem onClick={showAlert}>
                    このプレイリストを削除する
                  </MenuItem>
                </MenuList>
              </Menu>)}
          </div>
        </div>

        <div className="-my-2">
          {!isSortable ? (<>
              {sortableItems.map(function (article, index) { return (<div key={article.id} className={classnames('[&>a]:lg:!py-2', {
                    '[&>a]:border-none': index === sortableItems.length - 1,
                    'bg-neutral-200': !article.isPublished,
                })}>
                  <ArticleListItem id={article.id} href={"/articles/".concat(article.id, "?playlist=").concat(playlistId)} index={index} length={article.length} title={article.title} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl} isSelected={article.id === showingArticle.id} isPublished={article.isPublished}/>
                </div>); })}
            </>) : (<>
              <div className="mb-3 flex-col text-right">
                <button disabled={isSubmitting} onClick={onCancelSorting} className={classnames('rounded px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200', isSubmitting && 'cursor-default text-gray-300')}>
                  キャンセル
                </button>
                <button onClick={submitUpdateArticlePlaylistRelations} disabled={isSubmitting} className={classnames('rounded px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200', isSubmitting && 'cursor-default text-gray-300')}>
                  保存
                </button>
              </div>
              <ReactSortable list={sortableItems} setList={setSortableItems} className="grid gap-x-2 rounded" handle=".sortable-handle">
                {sortableItems.map(function (article, index) { return (<div key={article.id} className={classnames('flex items-center rounded px-2 [&>a]:pointer-events-none', {
                    'bg-neutral-200': !article.isPublished,
                    'bg-gray-100': article.id === showingArticle.id,
                    '[&>a]:border-none': index === sortableItems.length - 1,
                })}>
                    <FontAwesomeIcon className="sortable-handle cursor-grab pr-2 text-gray-700" size="2x" icon={faBars}/>
                    <ArticleListItem id={article.id} href={"/articles/".concat(article.id, "?playlist=").concat(playlistId)} index={index} length={article.length} title={article.title} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl} isSelected={article.id === showingArticle.id} isPublished={article.isPublished}/>
                    <FontAwesomeIcon size="2x" color="red" icon={faMinusCircle} className="cursor-pointer pl-2" onClick={function () { return removeItem(article.id); }}/>
                  </div>); })}
              </ReactSortable>
            </>)}
        </div>
      </div>

      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={closeAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              プレイリスト「{title}」を削除しますか？
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                キャンセル
              </Button>
              <Button colorScheme="red" onClick={submitDeletePlaylist} ml={3}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>);
};
export default Component;
