var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
var Component = forwardRef(function (_a, ref) {
    var children = _a.children, className = _a.className, spinnerColorClass = _a.spinnerColorClass, _b = _a.state, state = _b === void 0 ? 'enabled' : _b, rest = __rest(_a, ["children", "className", "spinnerColorClass", "state"]);
    return (<button {...rest} ref={ref} disabled={state !== 'enabled'} className={twMerge(className, classnames('relative', {
            'cursor-wait': state === 'submitting',
            'cursor-pointer': state === 'enabled',
            'cursor-not-allowed': state === 'disabled',
        }))}>
      <div className={classnames({ invisible: state === 'submitting' })}>
        {children}
      </div>

      {state === 'submitting' && (<div className="absolute inset-0 flex items-center justify-center">
          <FontAwesomeIcon spin fixedWidth icon={faSpinner} className={spinnerColorClass}/>
        </div>)}
    </button>);
});
export default Component;
