var Component = function (_a) {
    var children = _a.children, totalCount = _a.totalCount, notFoundContent = _a.notFoundContent;
    if (totalCount === 0) {
        return <>{notFoundContent}</>;
    }
    return (<div className="mb-8 grid grid-cols-2 gap-x-4 gap-y-10 sm:mb-16 md:grid-cols-4">
      {children}
    </div>);
};
export default Component;
