import { formatPublishedAt } from '@main/app/features/post/post.helper';
import PageLayout from '@main/ui/components/PageLayout';
import PostListItem from '@main/ui/components/PostListItem';
import PostListLayout from '@main/ui/components/PostListLayout';
import SideMenu from '@main/ui/components/SideMenu';
import noImage from '@main/ui/images/ShandsClub.png';
import useHook from './hooks';
export var Component = function (_a) {
    var currentPage = _a.currentPage, totalPage = _a.totalPage, posts = _a.posts, categoryName = _a.categoryName, sideMenuItems = _a.sideMenuItems, blogId = _a.blogId;
    return (<PageLayout caption={categoryName} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: categoryName },
        ]} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      <PostListLayout currentPage={currentPage} totalPage={totalPage}>
        {posts.map(function (post) {
            var id = post.id, title = post.title, thumbnailUrl = post.thumbnailUrl;
            return (<PostListItem key={id} id={id} title={title} thumbnailUrl={thumbnailUrl || noImage} publishedAt={formatPublishedAt(post)} blogId={blogId}/>);
        })}
      </PostListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
