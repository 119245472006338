import Image from '@main/ui/atoms/Image';
import Text, { FontSize, LineHeight } from '@main/ui/atoms/Text';
import PageLayout from '@main/ui/components/PageLayout';
import SideMenu from '@main/ui/components/SideMenu';
import HorizontalScrollList from '@main/ui/molecules/HorizontalScrollList';
import useHook from './hooks';
export var Component = function (_a) {
    var collections = _a.collections, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption="レッスンコース" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'レッスンコース' },
        ]} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      {collections.map(function (collection) { return (<div key={collection.id} className="mb-6">
          <div className="mb-2 rounded bg-gray-200 p-3 text-base font-bold">
            {collection.title}
          </div>

          <div className="mb-8">
            <HorizontalScrollList items={collection.features.map(function (feature) { return (<a key={feature.id} href={"/features/".concat(feature.id)} className="block no-underline">
                  <div className="mb-2">
                    <Image url={feature.thumbnailUrl}/>
                  </div>
                  <div>
                    <Text text={feature.title} fontSize={FontSize.Small} lineHeight={LineHeight.Base}/>
                  </div>
                </a>); })}/>
          </div>
        </div>); })}
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
