import Image from '@main/ui/atoms/Image';
import TextOverImage from '@main/ui/atoms/TextOverImage';
import playlistHasNoArticle from '@main/ui/images/PlaylistHasNoArticle.jpg';
var Component = function (_a) {
    var id = _a.id, title = _a.title, comment = _a.comment, articlesCount = _a.articlesCount, thumbnailArticleId = _a.thumbnailArticleId, thumbnailUrl = _a.thumbnailUrl;
    return (<a href={articlesCount > 0
            ? "/articles/".concat(thumbnailArticleId, "?playlist=").concat(id)
            : undefined} className="block w-full">
      <div className="relative mb-2">
        <Image url={articlesCount > 0 ? thumbnailUrl : playlistHasNoArticle}/>
        <TextOverImage text={"\u5168".concat(articlesCount, "\u672C")}/>
      </div>
      <div className="mb-1 text-sm font-bold text-gray-800 md:text-base">
        {title}
      </div>
      <div className="text-xs text-gray-500 md:text-sm">{comment}</div>
    </a>);
};
export default Component;
