import { z } from 'zod';
export var sideMenuItemSchema = z.object({
    categorySlug: z.string(),
    caption: z.string(),
    subItems: z.array(z.object({
        categorySlug: z.string(),
        caption: z.string(),
        articleCount: z.number(),
    })),
});
