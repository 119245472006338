var _a, _b;
import classnames from 'classnames';
export var ButtonState = {
    NotStarted: 'NotStarted',
    InProgress: 'InProgress',
};
var ButtonCaption = (_a = {},
    _a[ButtonState.NotStarted] = 'このコースに取り組む',
    _a[ButtonState.InProgress] = 'コースを進行中',
    _a);
var ButtonClasses = (_b = {},
    _b[ButtonState.NotStarted] = 'hover:opacity-90 text-white active:opacity-100 bg-orange-400 border-orange-400',
    _b[ButtonState.InProgress] = 'bg-white text-orange-600 border-orange-600 hover:text-orange-400 hover:border-orange-400 active:bg-orange-100 active:border-orange-600',
    _b);
var Component = function (_a) {
    var state = _a.state, click = _a.click;
    var caption = ButtonCaption[state];
    return (<button type="button" className={classnames('w-[160px] rounded border border-solid py-2 px-1 text-sm font-bold', ButtonClasses[state])} onClick={click}>
      {caption}
    </button>);
};
export default Component;
