import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import useHook from './hook';
var Component = function (props) {
    var title = 'サービスのご解約';
    var _a = useHook(props), status = _a.status, submit = _a.submit, control = _a.control, isSubmitting = _a.isSubmitting;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      {/* 既に手続き済み */}
      {status === 'already_canceled' && (<p className="text-base">
          既に解約手続き済みです。
          <br />
          解約手続きの取り消しをご希望の場合、
          <a href="/contact/new" className="underline">
            お問い合わせフォーム
          </a>
          からご連絡ください。
        </p>)}

      {/* クレジットカードエラーがある */}
      {status === 'credit_card_error' && (<div className="text-base">
          <span className="block pb-2 font-bold">
            ご利用料金が未払いの場合は解約手続きはできません。
          </span>
          クレジットカードへの請求が失敗しております。
          <br />
          お手数ですが、
          <a href="/user_billings/new" className="underline">
            こちら
          </a>
          より、クレジットカード情報の再登録をお願いいたします。
        </div>)}

      {/* クレジットカードが登録されていない or 振込が有効でもない */}
      {status === 'no_payment' && (<div className="text-base">
          クレジットカードのご登録またはお振込でのお支払いが確認できておりません。
        </div>)}

      {/* 解約可能 */}
      {status === 'cancelable' && (<form onSubmit={submit}>
          <p className="mb-4 text-base">
            この度はご利用頂きまして誠にありがとうございました。
            <br />
            サービス改善のため、お手数ですが解約理由をお聞かせください。
            <br />
          </p>

          <Controller name="reason" control={control} render={function (_a) {
                var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
                return (<div className="mb-4 flex border border-solid border-gray-400">
                <p className="flex items-center border-r border-solid border-gray-400 bg-gray-100 p-4 text-base text-gray-700">
                  解約理由
                </p>

                <div className="flex-1 p-2">
                  <textarea rows={3} ref={ref} value={value} onChange={onChange} className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-200 py-1 px-2 text-base !shadow-none outline-none"/>

                  {error && (<div className="mt-1 text-sm text-red-500">
                      {error.message}
                    </div>)}
                </div>
              </div>);
            }}/>

          <div className="flex justify-center">
            <Button type="submit" caption="送信する" state={isSubmitting ? 'submitting' : 'enabled'}/>
          </div>
        </form>)}
    </PageLayout>);
};
export default Component;
