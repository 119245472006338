import { formatElapsedDate } from '@main/app/libs/format-elapsed-date';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SideMenu from '@main/ui/components/SideMenu';
import dayjs from 'dayjs';
import useHook from './hooks';
export var Component = function (_a) {
    var totalCount = _a.totalCount, currentPage = _a.currentPage, totalPage = _a.totalPage, histories = _a.histories, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption="視聴履歴" breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: '視聴履歴' }]} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      <ArticleListLayout totalCount={totalCount} currentPage={currentPage} totalPage={totalPage} notFoundContent={<div className="rounded bg-yellow-100 p-5 text-base text-yellow-900">
            💡
            視聴した動画はまだありません。動画を視聴するとこのページに履歴が表示されます。
          </div>}>
        {histories.map(function (_a) {
            var id = _a.id, article = _a.article, viewedAt = _a.viewedAt, isCompleted = _a.isCompleted;
            return (<ArticleItem key={id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={"".concat(formatElapsedDate(dayjs(viewedAt)), "\u306B\u8996\u8074")} isCompleted={isCompleted}/>);
        })}
      </ArticleListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
