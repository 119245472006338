import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import GradientButtonLink from '@main/ui/components/GradientButtonLink';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hook';
import thumbnailImage from './images/thumbnail.png';
var Component = function (props) {
    var _a;
    var features = useHook(props).features;
    var title = '平岡理論まとめ';
    var startFromBeginningButton = (<GradientButtonLink icon={faPlayCircle} caption="最初から見る" href={"/features/".concat((_a = features[0]) === null || _a === void 0 ? void 0 : _a.id)}/>);
    return (<PageLayout caption={title} breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: title }]}>
      <div className="mb-3 block justify-between md:mb-8 md:flex">
        <div className="flex flex-1 flex-col justify-between">
          <div className="mb-5 text-base">
            シェークハンズ技術顧問
            <span className="font-bold">平岡義博監督</span>
            による卓球理論シリーズです。全ての打法・全ての戦型の基本となる、
            <span className="font-bold">卓球における原理原則を理論的に</span>
            解説しています。どのレベルのプレーヤーの方にも参考にしていただける理論なので、指導者の方や初心者の方にもおすすめです。
          </div>

          <div className="hidden max-w-[180px] md:block">
            {startFromBeginningButton}
          </div>
        </div>
        <div className="w-full overflow-hidden rounded-lg md:ml-4 md:max-w-[300px]">
          <img alt={title} loading="lazy" src={thumbnailImage} className="block w-full"/>
        </div>
      </div>

      <div className="mb-5 md:hidden">{startFromBeginningButton}</div>

      <div>
        {features.map(function (feature) { return (<a key={feature.id} href={"/features/".concat(feature.id)} style={{ borderLeft: '0', borderRight: '0' }} className="flex border-t border-b-0 border-solid border-gray-200 py-4 no-underline last:border-b hover:bg-gray-100">
            <div className="mr-2 w-[160px] md:mr-5 md:w-[200px]">
              <div className="aspect-video overflow-hidden rounded-lg border border-solid border-gray-100 bg-gray-200">
                <img loading="lazy" alt={feature.title} src={feature.thumbnailUrl} className="block h-full w-full object-cover object-center"/>
              </div>
            </div>

            <div className="flex-1">
              <div className="mb-2 text-sm font-bold text-gray-800 md:text-lg">
                {feature.title}
              </div>

              <div className="max-h-[48px] overflow-hidden text-xs text-gray-600 md:max-h-full md:text-sm">
                {feature.description}
              </div>
            </div>
          </a>); })}
      </div>
    </PageLayout>);
};
export default Component;
