import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquare as faSolidSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from '@main/ui/atoms/Image';
import RoundImage from '@main/ui/atoms/RoundImage';
import TextOverImage from '@main/ui/atoms/TextOverImage';
var Component = function (_a) {
    var id = _a.id, title = _a.title, clicked = _a.clicked, comment = _a.comment, children = _a.children, coachName = _a.coachName, movieLength = _a.movieLength, isCompleted = _a.isCompleted, thumbnailUrl = _a.thumbnailUrl, coachImageUrl = _a.coachImageUrl;
    return (<a href={"/articles/".concat(id)} className="block w-full" onClick={function () { return clicked && clicked(); }}>
      <div className="relative mb-2">
        {typeof isCompleted !== 'undefined' && (<div className="absolute top-2 right-2">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faSolidSquare} size="lg" className="text-white"/>
              <FontAwesomeIcon icon={isCompleted ? faCheckSquare : faSquare} size="lg" className="text-red-500"/>
            </span>
          </div>)}
        <Image url={thumbnailUrl}/>
        <TextOverImage text={movieLength}/>
      </div>
      <div className="flex">
        <div className="mr-2 h-8 w-8 overflow-hidden rounded-full md:h-9 md:w-9">
          <RoundImage url={coachImageUrl}/>
        </div>
        <div className="flex-1">
          <div className="mb-1 text-sm font-bold text-gray-800 md:text-base">
            {title}
          </div>
          <div className="text-xs text-gray-500 md:text-sm">
            {coachName}
            {comment && "\u30FB".concat(comment)}
          </div>
        </div>
      </div>
      {children}
    </a>);
};
export default Component;
