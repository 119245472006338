var Component = function (_a) {
    var list = _a.list;
    return (<div className="flex w-full flex-col divide-y border-b border-solid border-gray-200">
      {list.map(function (item) { return (<div className="flex flex-col gap-y-3 py-4 text-sm" key={item.question}>
          <div className="flex items-baseline gap-x-3">
            <div className="text-[28px] font-bold text-[#ff4b19]">Q</div>
            <div className="font-bold leading-relaxed">{item.question}</div>
          </div>
          <div className="flex items-baseline gap-x-3">
            <div className="text-[28px] font-bold text-[#f3debf]">A</div>
            <div className="leading-relaxed">{item.answer}</div>
          </div>
        </div>); })}
    </div>);
};
export default Component;
