import InlineLink from '@main/ui/atoms/InlineLink';
import PageLayout from '@main/ui/components/PageLayout';
import HelpSectionHeader from './components/HelpSectionHeader';
import QAndAList from './components/QAndAList';
var Page = function () {
    return (<PageLayout caption="ヘルプ" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'ヘルプ', link: '/help' },
        ]}>
      <div className="mt-10 flex w-full flex-col gap-y-12">
        <div>
          <HelpSectionHeader text="会員登録について"/>
          <QAndAList list={[
            {
                question: '会員登録は有料ですか',
                answer: (<div>
                    「動画でまなぶ卓球教室」シェークハンズ会員サービスは有料のサービスとなります。月額980円（税込1,078円）を毎月月初に課金させていただきます。
                    <br />
                    2024年2月15日以降にご登録いただいた方につきましては、契約日から1ヶ月ごとに980円（税込1,078円）を課金させていただきます。
                  </div>),
            },
            {
                question: 'レッスン動画の視聴には制限はありますか',
                answer: '視聴の制限はありません。 会員登録中は何度でも視聴することが可能です。 マスターしたい技術や繰り返し見たい動画はお気に入りに登録して何度でもご覧ください。',
            },
            {
                question: '会員登録の方法と流れついて',
                answer: (<div>
                    <a href="https://www.shands.jp/lp/base">こちら</a>
                    から会員登録を行なってください。
                    <br />
                    会員登録後、案内に従って利用料をお支払いいただくことでサービスが利用可能となります。
                  </div>),
            },
            {
                question: 'メールアドレスの確認メールが届かない',
                answer: (<div>
                    新規会員登録画面より必要事項を送信いただくと、直ちに入力いただいたメールアドレスに確認メールが自動送信されます。
                    <br />
                    メールが確認できない場合は以下の点をご確認ください。
                    <ul className="pl-4">
                      <li>迷惑メール等に分類されている</li>
                      <li>キャリア等でメールが止められている</li>
                      <li>入力したアドレスが間違っている</li>
                    </ul>
                  </div>),
            },
            {
                question: '退会方法について',
                answer: 'シェークハンズ会員サイトに一度ログインいただき、設定画面よりお手続きください。 画面一番下の「退会手続きを行う」ボタンを押していただき、必要事項を入力のうえ送信ボタンを押してください。',
            },
        ]}/>
        </div>
        <div>
          <HelpSectionHeader text="ログインについて"/>
          <QAndAList list={[
            {
                question: 'ログイン方法が分かりません',
                answer: (<div>
                    こちらの
                    <InlineLink target="_blank" href="/users/sign_in" text="ログインページ"/>
                    からログインしてください。
                    <br />
                    ログイン画面が表示されない場合、下記を行って下さい。
                    <ul className="pl-5">
                      <li>ブラウザのキャッシュをクリアして下さい。</li>
                    </ul>
                  </div>),
            },
            {
                question: 'パスワードの変更方法について',
                answer: 'シェークハンズ会員サイトに一度ログインいただき、設定画面よりお手続きください。［登録情報の変更］ボタンを押していただき、新しいパスワードを設定してください。',
            },
            {
                question: 'パスワードを忘れてしまった',
                answer: 'パスワード再発行手続きをご利用いただけます。 ご登録のメールアドレスを入力し、［新しいパスワードを取得］ボタンをおしてください。 メールにて新しいパスワードの発行に必要なURLを送信致します。',
            },
            {
                question: '会員ID（メールアドレス）をわすれてしまった、会員ID／パスワードを両方とも忘れてしまった',
                answer: '事務局にお問い合せください。出来うる限りお調べ致します。',
            },
        ]}/>
        </div>
        <div>
          <HelpSectionHeader text="利用料（月額料金）のお支払について"/>
          <QAndAList list={[
            {
                question: 'クレジットカード情報の保管と請求時期について',
                answer: 'ご入力いただいたクレジットカード情報は、カード決済会社に直接送信され、安全に保管されます。 ',
            },
            {
                question: '支払方法にはどのようなものがありますか',
                answer: 'クレジットカードのみの対応となります。',
            },
            {
                question: '支払方法の変更について',
                answer: 'クレジットカードの請求先情報の変更は、シェークハンズ会員サイトに一度ログインいただき、設定画面よりお手続きください。 ［クレジットカードを登録・編集する］ボタンを押していただき、新しいカード情報を上書きしてください。 変更いただいた月の月末より、新しく入力いただいたクレジットカードに課金され、変更前のカードへの課金は自動的に停止されます。',
            },
            {
                question: 'クレジットカード利用明細での請求名について',
                answer: '請求名は弊社名「卓球情報シェークハンズ」もしくは「Shakehands Inc.」と記載されます。',
            },
            {
                question: '学割はありますか',
                answer: 'ご用意しておりません。',
            },
            {
                question: '途中解約した場合、振込済みの料金の返金はありますか',
                answer: '途中解約した場合の返金は行なっておりません。',
            },
        ]}/>
        </div>
        <div>
          <HelpSectionHeader text="操作"/>
          <QAndAList list={[
            {
                question: '音声の小さい場合　または大きい場合の対処方法',
                answer: (<div>
                    動画にカーソルを乗せた際に、動画下部にコントロールパネルが現れるので、歯車マークの左にあるグラフマークの上にマウスカーソルを置くと、矢印キーの上下で音量を調整することができるようになります。
                    <br />
                    もしくは、Windowsの場合は
                    <InlineLink target="_blank" href="https://support.microsoft.com/ja-jp/search?query=windows10%20%E9%9F%B3%E9%87%8F" text="こちら"/>
                    、 macOSの場合は
                    <InlineLink target="_blank" href="https://support.apple.com/ja-jp/guide/mac-help/mchl1f98810b/mac" text="こちら"/>
                    のページを参考にしてください。
                    <br />
                    対処してもダメな場合には、ページURLを添付しお問合わせより問い合わせください。
                  </div>),
            },
        ]}/>
        </div>
        <div>
          <HelpSectionHeader text="スマートフォンでのご利用について"/>
          <div className="mt-5 text-sm leading-relaxed">
            <ul className="pl-5">
              <li>
                コンテンツの視聴(一部を除きストリーミング形式による配信となります)などには別途パケット通信料がかかります。通信料が高額となりますので、パケット定額サービスへのご加入を強くおすすめします。
              </li>
              <li>
                コンテンツをご利用の際、データ量が多くなり、回線の状況によっては視聴が制限される場合があります。無線LAN(Wi-Fi)でのご利用をおすすめします。
              </li>
            </ul>
          </div>
        </div>
        <div>
          <HelpSectionHeader text="ご利用環境について"/>
          <div className="mt-5 text-sm leading-relaxed">
            デバイスによっては一部視聴できないコンテンツがあります。
            <br />
            当サイトでは、快適に閲覧・ご利用していただくために、以下バージョンのOSおよびWebブラウザーを推奨しています。
            <br />
            Windows：Microsoft Edge（&#8251;）、Mozilla
            Firefox（&#8251;）、Google
            <br />
            Chrome（&#8251;） macOS：Safari（&#8251;）、Mozilla
            Firefox（&#8251;）、Google
            <br />
            Chrome（&#8251;）
            <br />
            &#8251; 最新版を動作保証といたします。
          </div>
        </div>

        <div>
          <HelpSectionHeader text="動画の視聴に問題がある場合"/>
          <div className="mt-5 text-sm leading-relaxed">
            動画の視聴に問題がある場合、
            <InlineLink text="こちら" target="_blank" href="https://shands.notion.site/e37f4b586cd240a9a548d2ccc071cd70"/>
            のチェックリストをご確認ください。
          </div>
        </div>

        <div>
          <HelpSectionHeader text="お問い合わせ"/>
          <div className="mt-5 text-sm leading-relaxed">
            お問い合わせは
            <InlineLink target="_blank" href="/contact/new" text="こちら"/>
            からご連絡ください。
          </div>
        </div>
      </div>
    </PageLayout>);
};
export default Page;
