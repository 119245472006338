import { twentyFourDate } from '@main/app/features/live-schedule/live-schesule.helper';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import classnames from 'classnames';
var Component = function (_a) {
    var isLiveInProgress = _a.isLiveInProgress, liveSchedules = _a.liveSchedules;
    return (<div>
      <SectionHeader caption="本日のライブ配信スケジュール"/>
      <div className="mb-4">
        {liveSchedules.map(function (liveSchedule) { return (<a href={liveSchedule.url} target="_blank" key={liveSchedule.id} className="block border-b-2 border-dotted border-gray-400 first:border-t-2 hover:bg-gray-100 active:bg-gray-200" rel="noreferrer">
            <div className="flex flex-row items-center gap-x-3 py-3 px-2.5">
              <div className={classnames('flex min-w-[70px] items-center justify-center rounded border-2 border-solid py-1 px-2 text-xs font-semibold', isLiveInProgress(liveSchedule)
                ? 'border-blue-500 text-blue-700'
                : 'border-orange-600 bg-orange-600 text-white')}>
                {isLiveInProgress(liveSchedule) ? '配信中' : '配信予定'}
              </div>
              <div className="min-w-[100px] text-center text-sm font-bold text-gray-600">
                {"".concat(twentyFourDate(liveSchedule.startAt), "\u301C").concat(twentyFourDate(liveSchedule.endAt))}
              </div>
              <div className="text-sm font-bold text-gray-600">
                {liveSchedule.title}
              </div>
            </div>
          </a>); })}
      </div>
      <div className="flex items-center justify-center">
        <a href="https://my.shands.jp/posts/290" target="_blank" className="block rounded border border-solid border-gray-600 bg-white py-2 px-12 text-center text-base text-gray-700 hover:bg-gray-100 active:bg-gray-200" rel="noreferrer">
          ライブ配信の視聴方法はこちら！
        </a>
      </div>
    </div>);
};
export default Component;
