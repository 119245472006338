import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLayout from '@main/ui/components/PageLayout';
import dayjs from '@/utils/dayjs';
import useHook from './hook';
var Component = function (props) {
    var title = 'お友達紹介キャンペーン';
    var _a = useHook(props), url = _a.url, copyUrl = _a.copyUrl, referrals = _a.referrals;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: title }]}>
      <p className="mb-5 text-base">
        お友達にシェークハンズをご紹介いただくことで、紹介する方（あなた）と紹介される方（お友達）の両方に特典をプレゼントさせていただきます。
      </p>

      <table className="mb-8 w-full table-fixed">
        <thead>
          <tr>
            <th className="border border-solid border-gray-300 bg-gray-100 py-2 text-base font-bold text-gray-700">
              紹介する方（あなた）
            </th>
            <th className="border border-solid border-gray-300 bg-gray-100 py-2 text-base font-bold text-gray-700">
              紹介される方
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-solid border-gray-300 py-4 text-center text-lg font-bold text-orange-500">
              次回以降のご請求額が500円OFF
            </td>
            <td className="border border-solid border-gray-300 py-4 text-center text-lg font-bold text-orange-500">
              初月の利用料が50%OFF
            </td>
          </tr>
        </tbody>
      </table>

      <p className="mb-3 text-center text-lg font-bold text-orange-500">
        以下のリンクをお友達にお送りください！
      </p>

      <div className="mb-8 flex items-stretch overflow-hidden rounded border border-solid border-gray-300">
        <input readOnly type="text" value={url} className="block flex-1 rounded-none !border-none !bg-gray-100 !p-3 !shadow-none outline-none"/>

        <button type="button" onClick={copyUrl} className="flex items-center bg-white px-2 active:bg-gray-100">
          コピーする
        </button>
      </div>

      <div className="mb-5">
        <p className="mb-2 text-base">注意事項</p>

        <ul className="!list-disc pl-5 text-base">
          <li>
            紹介される方が無料体験期間にご解約された場合は、特典の対象外となります。
          </li>
          <li>
            紹介される方が上記のリンク以外から会員登録された場合は、特典の対象外となります。
          </li>
          <li>
            紹介される方が既にシェークハンズの会員の場合は、本キャンペーンの対象外となります。
          </li>
          <li>何人でもご紹介いただけます。</li>
          <li>
            複数名をご紹介いただいたことにより、ご請求額が0円となった場合、特典はその次のご請求額に反映されます。
          </li>
          <li>
            特典が全て適用される前にサービスをご解約いただいた場合、特典の権利を失うものとします。
          </li>
        </ul>
      </div>

      <p className="mb-3 text-center text-lg font-bold text-gray-700">
        ご紹介履歴
      </p>

      {referrals.length > 0 ? (<table className="w-full">
          <thead>
            <tr>
              <th className="border border-solid border-gray-300 bg-gray-100 py-2 text-base text-gray-700"></th>
              <th className="border border-solid border-gray-300 bg-gray-100 py-2 text-base text-gray-700">
                特典付与日時
              </th>
            </tr>
          </thead>
          <tbody>
            {referrals.map(function (referral, index) { return (<tr key={referral.id}>
                <td className="border border-solid border-gray-300 py-2 text-center text-base text-gray-700">
                  {index + 1}
                </td>
                <td className="border border-solid border-gray-300 py-2 text-center text-base text-gray-700">
                  {dayjs(referral.rewardedAt).format('YYYY/M/D H:mm')}
                </td>
              </tr>); })}
          </tbody>
        </table>) : (<div className="rounded border border-solid border-gray-300 bg-gray-200 py-3 px-5 text-base text-gray-600">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-gray-500"/>
          成立したご紹介はまだありません。
        </div>)}
    </PageLayout>);
};
export default Component;
