var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema, } from '@main/ui/pages/AuthPremiumArticlesShow/form-schema';
import { createPlaylistQuery, addArticleToPlaylistQuery, removeArticleFromPlaylistQuery, updatePlaylistQuery, deletePlaylistQuery, } from '@main/ui/pages/AuthPremiumArticlesShow/queries';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
export var usePlaylistSave = function (_a) {
    var articleId = _a.articleId, ownedPlaylists = _a.ownedPlaylists, closeModal = _a.closeModal;
    var _b = useState(false), isShownTitleForm = _b[0], setIsShownTitleForm = _b[1];
    var _c = useState(ownedPlaylists.map(function (playlist) { return ({
        playlistId: playlist.id.toString(),
        title: playlist.title,
        isChecked: playlist.hasArticle,
        isLoading: false,
    }); })), checkboxItems = _c[0], setCheckboxItems = _c[1];
    var toast = useToast();
    var showToast = useCallback(function (toastTitle) {
        toast({
            title: toastTitle,
            position: 'bottom-left',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    }, [toast]);
    var _d = useMutation(createPlaylistQuery), createPlaylist = _d[0], isCreating = _d[1].loading;
    var _e = useMutation(addArticleToPlaylistQuery), addArticleToPlaylist = _e[0], isAdding = _e[1].loading;
    var _f = useMutation(removeArticleFromPlaylistQuery), removeArticleFromPlaylist = _f[0], isRemoving = _f[1].loading;
    var isSubmitting = isCreating || isAdding || isRemoving;
    var _g = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            title: '',
        },
    }), control = _g.control, handleSubmit = _g.handleSubmit, reset = _g.reset, setError = _g.setError;
    var startLoading = useCallback(function (index) {
        var newCheckboxItems = checkboxItems.map(function (item, i) {
            if (i === index) {
                return __assign(__assign({}, item), { isLoading: true });
            }
            return item;
        });
        setCheckboxItems(newCheckboxItems);
    }, [checkboxItems]);
    // 対象のcheckboxのisChecked?の状態値を反転させる
    var updateCheckState = useCallback(function (index) {
        var newCheckboxItems = checkboxItems.map(function (item, i) {
            if (i === index) {
                return __assign(__assign({}, item), { isLoading: false, isChecked: !item.isChecked });
            }
            return item;
        });
        setCheckboxItems(newCheckboxItems);
    }, [checkboxItems]);
    var submitCreatePlaylist = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (checkboxItems.some(function (item) { return item.title === data.title; })) {
                        setError('title', {
                            type: 'manual',
                            message: '既に存在するプレイリスト名です。',
                        });
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, createPlaylist({
                            variables: {
                                input: {
                                    title: data.title,
                                    articleIds: [articleId],
                                    clientMutationId: null,
                                },
                            },
                            onCompleted: function (response) {
                                var playlist = response.createPlaylist.playlist;
                                var newCheckboxItems = __spreadArray([
                                    {
                                        playlistId: playlist.id,
                                        title: playlist.title,
                                        isChecked: true,
                                        isLoading: false,
                                    }
                                ], checkboxItems, true);
                                setCheckboxItems(newCheckboxItems);
                                showToast("[".concat(playlist.title, "]\u306B\u8FFD\u52A0\u3057\u307E\u3057\u305F"));
                                reset();
                                setIsShownTitleForm(false);
                                closeModal();
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    var submitAddArticleToPlaylist = useCallback(function (playlistId, playlistTitle, index) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    startLoading(index);
                    return [4 /*yield*/, addArticleToPlaylist({
                            variables: {
                                input: {
                                    playlistId: playlistId,
                                    articleId: articleId,
                                    clientMutationId: null,
                                },
                            },
                            onCompleted: function () {
                                updateCheckState(index);
                                showToast("[".concat(playlistTitle, "]\u306B\u8FFD\u52A0\u3057\u307E\u3057\u305F"));
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        addArticleToPlaylist,
        articleId,
        showToast,
        startLoading,
        updateCheckState,
    ]);
    var submitRemoveArticleFromPlaylist = useCallback(function (playlistId, playlistTitle, index) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    startLoading(index);
                    return [4 /*yield*/, removeArticleFromPlaylist({
                            variables: {
                                input: {
                                    playlistId: playlistId,
                                    articleId: articleId,
                                    clientMutationId: null,
                                },
                            },
                            onCompleted: function () {
                                updateCheckState(index);
                                showToast("[".concat(playlistTitle, "]\u304B\u3089\u524A\u9664\u3057\u307E\u3057\u305F"));
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        articleId,
        removeArticleFromPlaylist,
        showToast,
        startLoading,
        updateCheckState,
    ]);
    return {
        isShownTitleForm: isShownTitleForm,
        setIsShownTitleForm: setIsShownTitleForm,
        isSubmitting: isSubmitting,
        checkboxItems: checkboxItems,
        control: control,
        submitCreatePlaylist: submitCreatePlaylist,
        submitAddArticleToPlaylist: submitAddArticleToPlaylist,
        submitRemoveArticleFromPlaylist: submitRemoveArticleFromPlaylist,
    };
};
export var usePlaylistView = function (_a) {
    var playlistId = _a.playlistId, title = _a.title, articleIds = _a.articleIds, setIsShownTitleForm = _a.setIsShownTitleForm, setIsSortable = _a.setIsSortable;
    var toast = useToast();
    var showToast = useCallback(function (toastTitle) {
        toast({
            title: toastTitle,
            position: 'bottom-left',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    }, [toast]);
    var _b = useMutation(updatePlaylistQuery), updatePlaylist = _b[0], isUpdating = _b[1].loading;
    var _c = useMutation(deletePlaylistQuery), deletePlaylist = _c[0], isDeleting = _c[1].loading;
    var isSubmitting = isUpdating || isDeleting;
    var _d = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            title: title,
        },
    }), control = _d.control, handleSubmit = _d.handleSubmit, watch = _d.watch, reset = _d.reset;
    var submitUpdatePlaylistTitle = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePlaylist({
                        variables: {
                            input: {
                                id: playlistId.toString(),
                                title: data.title,
                                articleIds: undefined,
                                clientMutationId: null,
                            },
                        },
                        onCompleted: function () {
                            showToast('タイトルを更新しました');
                            setIsShownTitleForm(false);
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    var submitUpdateArticlePlaylistRelations = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePlaylist({
                        variables: {
                            input: {
                                id: playlistId.toString(),
                                articleIds: articleIds,
                                title: undefined,
                                clientMutationId: null,
                            },
                        },
                        onCompleted: function () {
                            showToast('動画の並び順を更新しました');
                            setIsSortable(false);
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [articleIds, playlistId, setIsSortable, showToast, updatePlaylist]);
    var submitDeletePlaylist = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deletePlaylist({
                        variables: {
                            input: {
                                id: playlistId.toString(),
                                clientMutationId: null,
                            },
                        },
                        onCompleted: function () {
                            showToast("[".concat(title, "]\u3092\u524A\u9664\u3057\u307E\u3057\u305F"));
                            Turbolinks.visit('/playlists');
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [deletePlaylist, playlistId, showToast, title]);
    return {
        isSubmitting: isSubmitting,
        control: control,
        title: watch('title'),
        reset: reset,
        submitUpdatePlaylistTitle: submitUpdatePlaylistTitle,
        submitUpdateArticlePlaylistRelations: submitUpdateArticlePlaylistRelations,
        submitDeletePlaylist: submitDeletePlaylist,
    };
};
