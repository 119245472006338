import dayjs from 'dayjs';
export var twentyFourDate = function (date) {
    return dayjs(date).format('H:mm');
};
export var isOnAir = function (liveSchedule) {
    var now = dayjs();
    var endAt = dayjs(liveSchedule.endAt);
    var startAt = dayjs(liveSchedule.startAt);
    return now.isAfter(startAt) && now.isBefore(endAt);
};
