import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import { Link, Button as ButtonBase } from '@main/ui/components/SectionButton';
import { SectionTable, SectionTableRow, } from '@main/ui/components/SectionTable';
import classnames from 'classnames';
import dayjs from '@/utils/dayjs';
import Section from './components/Section';
import useHook from './hook';
var SectionLink = function (props) { return (<div className="flex">
    <Link {...props}/>
  </div>); };
var SectionButton = function (props) { return (<div className="flex">
    <ButtonBase {...props}/>
  </div>); };
var Component = function (props) {
    var _a = useHook(props), email = _a.email, lastName = _a.lastName, firstName = _a.firstName, formatDate = _a.formatDate, formatTime = _a.formatTime, trialEndAt = _a.trialEndAt, billingPlan = _a.billingPlan, billingStatus = _a.billingStatus, isBillByStripe = _a.isBillByStripe, unconfirmedEmail = _a.unconfirmedEmail, isBillingStarted = _a.isBillingStarted, transferExpireAt = _a.transferExpireAt, isPremiumJoinUser = _a.isPremiumJoinUser, creditCardRegisteredAt = _a.creditCardRegisteredAt, unconfirmedAccessExpireAt = _a.unconfirmedAccessExpireAt, startCustomerPortalSession = _a.startCustomerPortalSession, isPremiumReferralAvailable = _a.isPremiumReferralAvailable, customerPortalSessionState = _a.customerPortalSessionState, isUpdateCreditCardAvailable = _a.isUpdateCreditCardAvailable, resendEmailUpdateConfirmationEmail = _a.resendEmailUpdateConfirmationEmail, isResendingEmailUpdateConfirmationEmail = _a.isResendingEmailUpdateConfirmationEmail;
    return (<PageLayout caption="設定" breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: '設定' }]}>
      <div className="mb-8 flex flex-col gap-y-10">
        <Section caption="アンケート">
          <SectionLink caption="アンケートに回答する" href="/attribute/edit"/>
        </Section>

        {isPremiumReferralAvailable && (<Section caption="お友達紹介">
            <SectionLink href="/referral/new" caption="お友達紹介キャンペーンを利用する"/>
          </Section>)}

        <Section caption="ログアウト">
          <form method="post" action="/users/sign_out">
            <input type="hidden" name="_method" value="DELETE"/>
            <SectionButton caption="ログアウトする" type="submit"/>
          </form>
        </Section>

        <Section caption="登録情報">
          <div className="flex flex-col gap-y-2">
            <SectionTable>
              <SectionTableRow caption="氏名">
                {lastName} {firstName}
              </SectionTableRow>

              <SectionTableRow caption="メールアドレス">
                <div className="grid gap-y-1">
                  <div>{email}</div>

                  {unconfirmedEmail && (<div>
                      <div>
                        変更手続き中のメールアドレス：{unconfirmedEmail}
                      </div>

                      <button type="button" onClick={resendEmailUpdateConfirmationEmail} disabled={isResendingEmailUpdateConfirmationEmail} className={classnames('underline', isResendingEmailUpdateConfirmationEmail
                ? 'text-gray-500'
                : 'text-gray-700')}>
                        確認メールを再送する
                      </button>
                    </div>)}

                  {unconfirmedAccessExpireAt && (<div>
                      {dayjs(unconfirmedAccessExpireAt).format('YYYY/M/D H:mm')}
                      までにメールアドレスの確認を完了してください。
                    </div>)}
                </div>
              </SectionTableRow>
            </SectionTable>

            <SectionLink caption="登録情報の変更" href="/user/edit"/>
          </div>
        </Section>

        <Section caption="課金情報">
          {isBillByStripe ? (<div className="grid gap-y-4">
              {billingStatus !== 'billing_not_started' && (<SectionTable>
                  <SectionTableRow caption="契約中のプラン">
                    {(function () {
                    switch (billingPlan) {
                        case 'billing_plan_premium':
                            return 'プレミアム会員';
                        case 'billing_plan_club_standard':
                            return 'クラブ会員';
                        case 'billing_plan_club_platinum':
                            return 'クラブプラチナ会員';
                        default:
                            return '-';
                    }
                })()}
                  </SectionTableRow>
                  <SectionTableRow caption="お支払い状態">
                    {(function () {
                    var formattedTrialEndAt = trialEndAt
                        ? dayjs(trialEndAt).tz().format('YYYY/M/D H:mm')
                        : null;
                    switch (billingStatus) {
                        case 'billing_active':
                            return '有効（自動更新）';
                        case 'billing_canceled':
                            return '有効（自動更新停止済み）';
                        case 'billing_trial':
                            return "\u8A66\u7528\u671F\u9593\uFF08".concat(formattedTrialEndAt, "\u307E\u3067\u30FB\u305D\u306E\u5F8C\u8AB2\u91D1\u958B\u59CB\uFF09");
                        case 'billing_trial_canceled':
                            return "\u8A66\u7528\u671F\u9593\uFF08".concat(formattedTrialEndAt, "\u307E\u3067\u30FB\u305D\u306E\u5F8C\u81EA\u52D5\u89E3\u7D04\uFF09");
                        case 'billing_restricted':
                            return '更新エラー';
                    }
                })()}
                  </SectionTableRow>
                </SectionTable>)}

              <div>
                <Button type="button" caption="お支払い方法の管理" state={customerPortalSessionState} onClick={startCustomerPortalSession}/>
              </div>
            </div>) : (<>
              <SectionTable>
                {transferExpireAt ? (<>
                    <SectionTableRow caption="振込有効期限">
                      {formatDate(transferExpireAt)}
                    </SectionTableRow>
                  </>) : (<SectionTableRow caption="クレジットカード登録日時">
                    {creditCardRegisteredAt
                    ? formatTime(creditCardRegisteredAt)
                    : '-'}
                  </SectionTableRow>)}
              </SectionTable>

              {transferExpireAt && (<div className="my-2 text-sm text-gray-700">
                  振込でのお支払いの方は有効期限後にクレジットカードでのお支払に変更ができます。
                </div>)}

              {isUpdateCreditCardAvailable && (<div className="mt-2">
                  <SectionLink caption="クレジットカードを変更する" href="/stripe_migrations"/>
                </div>)}
            </>)}
        </Section>

        {isBillByStripe ? (<>
            {isBillingStarted && (<Section caption="サービス解約">
                <SectionLink caption="サービス解約手続き" href="/billing/cancel"/>
              </Section>)}
          </>) : (<>
            {isPremiumJoinUser && (<Section caption="サービス解約">
                <SectionLink caption="サービス解約手続き" href="/user_billings/cancel"/>
              </Section>)}
          </>)}

        <Section caption="退会">
          <SectionLink caption="退会手続き" href="/resign"/>
        </Section>
      </div>
    </PageLayout>);
};
export default Component;
