var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDisclosure } from '@chakra-ui/react';
import { usePlaylistView } from '@main/app/features/playlist/playlist.hook';
import { useState, useRef } from 'react';
import PlaylistOverview from './components/PlaylistOverview';
var Component = function (_a) {
    var playlist = _a.playlist, article = _a.article, sortableItems = _a.sortableItems, setSortableItems = _a.setSortableItems;
    var _b = useState(false), isShownTitleForm = _b[0], setIsShownTitleForm = _b[1];
    var _c = useState(false), isSortable = _c[0], setIsSortable = _c[1];
    var _d = useState(playlist.articles), preSortableItems = _d[0], setPreSortableItems = _d[1];
    var _e = useDisclosure(), isAlertOpen = _e.isOpen, showAlert = _e.onOpen, closeAlert = _e.onClose;
    var cancelRef = useRef(null);
    var playlistId = playlist.id;
    var usePlaylistViewProps = usePlaylistView({
        playlistId: playlistId,
        title: playlist.title,
        articleIds: sortableItems.map(function (a) { return a.id.toString(); }),
        setIsShownTitleForm: setIsShownTitleForm,
        setIsSortable: setIsSortable,
    });
    var onStartSorting = function () {
        setIsSortable(true);
        setPreSortableItems(sortableItems);
    };
    var onCancelSorting = function () {
        setIsSortable(false);
        setSortableItems(preSortableItems);
    };
    var removeItem = function (articleId) {
        var newSortableItems = sortableItems.filter(function (sortableItem) { return sortableItem.id !== articleId; });
        setSortableItems(newSortableItems);
    };
    var playlistOverviewProps = __assign({ article: article, playlistId: playlistId, isShownTitleForm: isShownTitleForm, isSortable: isSortable, sortableItems: sortableItems, isAlertOpen: isAlertOpen, cancelRef: cancelRef, closeAlert: closeAlert, showAlert: showAlert, setIsShownTitleForm: setIsShownTitleForm, onStartSorting: onStartSorting, onCancelSorting: onCancelSorting, setSortableItems: setSortableItems, removeItem: removeItem }, usePlaylistViewProps);
    return <PlaylistOverview {...playlistOverviewProps}/>;
};
export default Component;
