import { Modal, ModalBody, ModalOverlay, ModalContent } from '@chakra-ui/react';
import useHook from './hook';
var Component = function (props) {
    var _a = useHook(props), close = _a.close, isOpen = _a.isOpen, title = _a.title, visit = _a.visit, imageUrl = _a.imageUrl, isSubmitting = _a.isSubmitting;
    return (<Modal isCentered isOpen={isOpen} onClose={function () { return close(false); }}>
      <ModalOverlay />

      <ModalContent maxWidth="680px" width="calc(100% - 20px)" maxHeight="calc(100% - 20px)" className="overflow-hidden">
        <ModalBody className="overflow-y-scroll">
          <div className="py-4">
            <div className="mb-3 aspect-[29/12] overflow-hidden border border-solid border-gray-300 bg-gray-300">
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <img src={imageUrl} className="block w-full"/>
            </div>

            <p className="mb-4 text-center text-lg font-bold">{title}</p>

            <div className="mb-5 flex justify-center">
              <button type="button" onClick={visit} disabled={isSubmitting} className="block w-full max-w-[240px] rounded bg-blue-400 py-3 text-center text-base font-bold text-white active:bg-blue-500">
                詳細を見る
              </button>
            </div>

            <div className="grid gap-y-1">
              <button disabled={isSubmitting} onClick={function () { return close(false); }} className="mx-auto block py-1 px-4 text-sm text-gray-500 underline outline-none">
                閉じる
              </button>

              <button disabled={isSubmitting} onClick={function () { return close(true); }} className="mx-auto block py-1 px-4 text-sm text-gray-500 underline outline-none">
                閉じる（今後は表示しない）
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>);
};
export default Component;
