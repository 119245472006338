import SectionHeader from '@main/ui/atoms/SectionHeader';
import Breadcrumb from '@main/ui/components/Breadcrumb';
import MainLayout from '@main/ui/layouts/MainLayout';
import classnames from 'classnames';
var Component = function (_a) {
    var caption = _a.caption, children = _a.children, headerContent = _a.headerContent, sidebarContent = _a.sidebarContent, _b = _a.breadcrumbs, breadcrumbs = _b === void 0 ? [] : _b, _c = _a.singleColumn, singleColumn = _c === void 0 ? false : _c;
    return (<MainLayout>
      <div className="flex justify-center">
        {sidebarContent && (<div className="mr-[30px] hidden w-[280px] lg:block">
            {sidebarContent}
          </div>)}
        <div className={classnames('flex-1', singleColumn ? 'max-w-screen-sm' : 'max-w-full', {
            'lg:w-[calc(100%_-_310px)] lg:max-w-none lg:flex-none': sidebarContent,
        })}>
          {breadcrumbs.length > 0 && (<div className="mb-1">
              <Breadcrumb items={breadcrumbs}/>
            </div>)}

          <SectionHeader caption={caption}>{headerContent}</SectionHeader>

          <div>{children}</div>
        </div>
      </div>
    </MainLayout>);
};
export default Component;
