import { useBreakpointValue } from '@chakra-ui/react';
var Component = function (_a) {
    var items = _a.items;
    var width = useBreakpointValue({ base: '180px', md: '240px' });
    return (<div className="overflow-hidden">
      <div className="overflow-y-hidden overflow-x-scroll">
        <div className="-mx-2.5 flex">
          {items.map(function (item, index) { return (<div key={index} className="mx-2.5 block no-underline" style={{ width: width, minWidth: width }}>
              {item}
            </div>); })}
        </div>
      </div>
    </div>);
};
export default Component;
