import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Spinner, } from '@chakra-ui/react';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { clickOpenSuggestionBoxButton } from '@/utils/analytics/log-event';
var Component = function (_a) {
    var _b;
    var isModalOpen = _a.isModalOpen, register = _a.register, errors = _a.errors, onCloseModal = _a.onCloseModal, showModal = _a.showModal, onSubmit = _a.onSubmit, handleSubmit = _a.handleSubmit, isLoading = _a.isLoading, isCompleted = _a.isCompleted;
    return (<>
      <button className="fixed right-[20px] bottom-5 h-16 w-16 rounded-full bg-blue-400 shadow-md shadow-neutral-500 outline-none hover:opacity-90 active:bg-blue-500 active:opacity-100 md:bottom-9 md:right-16" type="button" onClick={function () {
            showModal();
            clickOpenSuggestionBoxButton();
        }}>
        <div className="flex flex-col items-center justify-center text-white">
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="mb-0.5" fixedWidth/>
          <div className="text-xs font-bold leading-none">目安箱</div>
        </div>
      </button>
      <Modal isOpen={isModalOpen} onClose={onCloseModal} isCentered blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent width="calc(100% - 20px)" maxWidth="600px" className="py-6">
          <ModalHeader>
            <div className="text-center text-orange-600">
              シェークハンズへのご意見・ご要望
            </div>
          </ModalHeader>
          <ModalBody>
            <p className="mb-4 font-bold">
              いつもシェークハンズをご利用いただきありがとうございます。サービスや動画コンテンツについてのご意見・ご要望がありましたらお気軽にお寄せください。
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <textarea className="h-28 w-full resize-none rounded border-none bg-gray-100 p-4 md:h-36" placeholder="例：左利きの選手の動画も増やしてほしい。" disabled={isCompleted} {...register('message', {
        required: 'メッセージを入力してください。',
    })}/>
              {errors.message && (<p className="text-sm text-red-600">
                  {(_b = errors.message) === null || _b === void 0 ? void 0 : _b.message}
                </p>)}
              <p className="text-gray-500">
                ※ 送信内容に対する返信はできかねます。
                <br />※ 全てのご要望にお答えするものではありません。
              </p>
              {isLoading ? (<div className="mx-auto mt-3 flex h-12 w-40 items-center justify-center rounded bg-orange-600 text-white">
                  <Spinner size="sm"/>
                </div>) : (<input type="submit" value={isCompleted ? '送信しました！' : '送信する'} disabled={isCompleted} className={classnames('mx-auto mt-3 block h-12 w-40 rounded text-base font-bold text-white', isCompleted
                ? 'pointer-events-none bg-gray-400'
                : 'bg-orange-600')}/>)}
            </form>
            <button className="mx-auto mt-6 block py-2 px-4 text-sm text-gray-500 underline" onClick={onCloseModal}>
              閉じる
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
export default Component;
