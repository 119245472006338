var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Image from '@main/ui/atoms/Image';
import PageLayout from '@main/ui/components/PageLayout';
import SideMenu from '@main/ui/components/SideMenu';
import useHook from './hooks';
export var Component = function (_a) {
    var items = _a.items, totalCount = _a.totalCount, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption="進行中のレッスンコース" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '進行中のレッスンコース' },
        ]} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      {totalCount > 0 ? (<div className="mb-8 grid grid-cols-2 gap-x-4 gap-y-10 sm:mb-16 md:grid-cols-4">
          {items.map(function (_a) {
                var feature = _a.feature, item = __rest(_a, ["feature"]);
                return (<a key={feature.id} href={"/features/".concat(feature.id)} className="block w-full">
              <div className="relative mb-2">
                <Image url={feature.thumbnailUrl}/>
              </div>
              <div className="mb-2 text-sm font-bold text-gray-800 md:text-base">
                {feature.title}
              </div>
              <div className="flex w-full items-center">
                <div className="mr-2 text-xs font-thin leading-none text-gray-500">
                  {item.completeRate}% 完了
                </div>

                <div className="relative h-2.5 flex-1 overflow-hidden rounded-full bg-gray-200">
                  <div className="h-full bg-green-300" style={{ width: "".concat(item.completeRate, "%") }}></div>
                </div>
              </div>
            </a>);
            })}
        </div>) : (<div className="rounded bg-gray-200 p-5 text-base text-gray-600">
          進行中のレッスンコースはまだありません。
        </div>)}
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
