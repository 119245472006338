import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hook';
var Component = function () {
    var title = 'お支払いエラー';
    var _a = useHook(), errorMessage = _a.errorMessage, isCreatingCustomerPortalSession = _a.isCreatingCustomerPortalSession, startCreateCustomerPortalSession = _a.startCreateCustomerPortalSession;
    return (<PageLayout caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      {errorMessage && (<div className="mb-5 rounded border border-red-400 bg-red-200 p-3 text-base text-red-700">
          {errorMessage}
        </div>)}

      <div className="grid gap-y-8">
        <div>
          <p className="mb-2 text-base leading-relaxed">
            サービス利用料をご登録いただいているお支払い方法に請求させていただきましたが、請求が失敗しました。
            <br />
            失敗理由は弊社側では確認することができませんので、詳細につきましてはお手数ですがご利用のクレジットカード会社へご確認ください。
            <br />
            よくある事例として、以下のような状況が挙げられます。
          </p>

          <ul className="list-disc pl-5 text-base">
            <li>クレジットカードの有効期限が切れている</li>
            <li>クレジットカードの限度額に達している</li>
            <li>
              不正利用の疑いがあり、ご本人の確認が必要な保留状態となっている
            </li>
          </ul>
        </div>

        <div>
          <p className="mb-2 text-base leading-relaxed">
            以下のボタンからお支払い方法のご確認をお願いいたします。
          </p>

          <div className="w-56">
            <Button caption="お支払い方法の管理" onClick={startCreateCustomerPortalSession} state={isCreatingCustomerPortalSession ? 'submitting' : 'enabled'}/>
          </div>
        </div>
      </div>
    </PageLayout>);
};
export default Component;
