var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Drawer, DrawerBody, DrawerHeader, DrawerContent, DrawerOverlay, } from '@chakra-ui/react';
import { faCog, faBars, faTimes, faSearch, faArrowLeft, faChevronCircleRight, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { menuLists } from '@main/app/features/header/menu-list';
import { isOnAir, twentyFourDate, } from '@main/app/features/live-schedule/live-schesule.helper';
import LabelledButton from '@main/ui/components/LabelledButton';
import Logo from '@main/ui/images/ShakehandsLogo.png';
import classnames from 'classnames';
import { clickHeaderLogo, clickOpenSideMenuButton, } from '@/utils/analytics/log-event';
import dayjs from '@/utils/dayjs';
import useHook from './hook';
var Component = function (props) {
    var _a = useHook(props), isPwa = _a.isPwa, isOpen = _a.isOpen, onOpen = _a.onOpen, onClose = _a.onClose, canBack = _a.canBack, headerRef = _a.headerRef, trialEndAt = _a.trialEndAt, historyBack = _a.historyBack, headerHeight = _a.headerHeight, searchKeyword = _a.searchKeyword, headerMenuLists = _a.headerMenuLists, nextLiveSchedule = _a.nextLiveSchedule, setSearchKeyword = _a.setSearchKeyword, isServiceMenuAvailable = _a.isServiceMenuAvailable, resendConfirmationEmail = _a.resendConfirmationEmail, unconfirmedAccessExpireAt = _a.unconfirmedAccessExpireAt;
    return (<>
      {/* 固定ヘッダーが収まるスペース */}
      <div className="mb-4 bg-white" style={{ height: headerHeight }}/>

      {/* 固定ヘッダー */}
      <div ref={headerRef} className="fixed top-0 z-10 w-full">
        <div className="h-[3px] bg-orange-600"></div>
        <div className="h-[54px] border-b border-solid border-gray-300 bg-white">
          <div className="flex h-full items-center justify-between py-1 px-3 sm:mx-auto sm:max-w-screen-xl sm:px-5">
            {/* 左揃えメニュー */}
            <div className="flex flex-1 items-center">
              {isPwa && canBack && (<div className="mr-2">
                  <LabelledButton type="button" icon={faArrowLeft} onClick={historyBack}/>
                </div>)}

              <a href="/" onClick={clickHeaderLogo} className="block">
                <img alt="" loading="lazy" className="mr-3 block w-32 lg:w-40" src={Logo}/>
              </a>

              {isServiceMenuAvailable && (<form method="get" action="/articles/search" className="ml-5 hidden max-w-[420px] flex-1 md:block">
                  <div className="flex h-9 justify-between rounded border border-solid border-gray-200">
                    <div className="relative flex flex-1 items-center rounded-l bg-gray-100">
                      <FontAwesomeIcon fixedWidth icon={faSearch} className="absolute left-2 my-0 h-[32px] w-[32px] text-gray-400"/>
                      <input className="h-full w-full pl-8 text-sm leading-none placeholder:text-gray-400 focus:outline-none focus:placeholder:text-gray-400" type="search" name="keyword" value={searchKeyword} placeholder="動画を検索" onChange={function (event) { return setSearchKeyword(event.target.value); }}/>
                    </div>
                    <button type="submit" className="border-l border-solid border-gray-200 px-3 text-sm font-bold text-gray-500 active:bg-gray-100">
                      検索
                    </button>
                  </div>
                </form>)}
            </div>

            {/* 右揃えメニュー */}
            <div className="hidden items-center md:flex">
              {headerMenuLists.map(function (menuList) { return (<a key={menuList.url} href={menuList.url} className="flex items-center rounded py-2 px-3 text-gray-500 hover:text-orange-500 active:text-orange-700" onClick={menuList.clicked}>
                  <FontAwesomeIcon fixedWidth icon={menuList.icon} className="mr-1 text-sm lg:text-base"/>
                  <div className="whitespace-nowrap text-xs font-bold lg:text-sm">
                    {menuList.title}
                  </div>
                </a>); })}
            </div>

            {/* スマホ版メニュー */}
            <div className="md:hidden">
              {isServiceMenuAvailable ? (<div className="flex h-full items-center space-x-4">
                  {trialEndAt && (<a href="/billing/trial" className="flex items-center rounded bg-yellow-200 py-1 px-2 text-xs font-bold leading-none text-yellow-600 ring-1 ring-yellow-300">
                      無料体験中
                      <FontAwesomeIcon fixedWidth size="1x" className="ml-0.5" icon={faChevronCircleRight}/>
                    </a>)}

                  <LabelledButton icon={faBars} type="button" caption="メニュー" onClick={function () {
                onOpen();
                clickOpenSideMenuButton();
            }}/>
                </div>) : (<a href="/user" className="block">
                  <LabelledButton icon={faCog} caption="設定" type="button"/>
                </a>)}
            </div>
          </div>
        </div>

        {isServiceMenuAvailable && (<>
            {unconfirmedAccessExpireAt ? (<button type="button" onClick={resendConfirmationEmail} className="flex w-full items-center bg-slate-500 py-1 px-2 hover:bg-slate-400 active:bg-gray-600">
                <div className="mx-auto block w-full max-w-screen-lg text-center text-xs font-bold text-white underline sm:text-sm">
                  {dayjs(unconfirmedAccessExpireAt).format('YYYY/M/D H:mm')}
                  までにメールアドレスの開通確認を行なってください。確認メールの再送はこちらから
                </div>
              </button>) : (<>
                {nextLiveSchedule && (<a target="_blank" href={isOnAir(nextLiveSchedule)
                        ? nextLiveSchedule.url
                        : undefined} className={classnames('flex items-center py-1 px-2', isOnAir(nextLiveSchedule)
                        ? 'bg-blue-500 hover:bg-blue-400 active:bg-blue-600'
                        : 'bg-blue-400')} rel="noreferrer">
                    <div className="mx-auto block w-full max-w-screen-lg text-center text-xs font-bold text-white underline sm:text-sm">
                      {isOnAir(nextLiveSchedule)
                        ? "\u300C".concat(nextLiveSchedule.title, "\u300D\u30E9\u30A4\u30D6\u914D\u4FE1\u4E2D\uFF01\u3054\u8996\u8074\u306F\u3053\u3061\u3089\u304B\u3089\uFF01")
                        : "\u3010\u672C\u65E5".concat(twentyFourDate(nextLiveSchedule.startAt), "\u301C").concat(twentyFourDate(nextLiveSchedule.endAt), "\u3011\u300C").concat(nextLiveSchedule.title, "\u300D\u30E9\u30A4\u30D6\u914D\u4FE1\u4E88\u5B9A\uFF01")}
                    </div>
                  </a>)}
              </>)}
          </>)}
      </div>

      {/* ドロワーメニュー */}
      <Drawer autoFocus={false} isOpen={isOpen} onClose={onClose} placement="right" size="xs">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>
              <div className="flex items-center justify-between">
                <img alt="" loading="lazy" className="block w-40" src={Logo}/>
                <button onClick={onClose} className="flex h-8 w-8 items-center">
                  <FontAwesomeIcon fixedWidth icon={faTimes} className="rounded-full text-3xl text-gray-400 active:bg-gray-200"/>
                </button>
              </div>
            </DrawerHeader>
            <DrawerBody>
              <form action="/articles/search" method="get">
                <input type="hidden" name="device" value="sp"/>
                <div className="mb-10 flex justify-between rounded border border-solid border-gray-400">
                  <div className="relative flex w-4/5 items-center rounded-l bg-gray-100">
                    <FontAwesomeIcon fixedWidth icon={faSearch} className="absolute bottom-3 left-2 text-gray-400"/>
                    <input className="h-full w-full pl-8 leading-tight placeholder:text-gray-400 focus:outline-none focus:placeholder:text-gray-400" type="search" name="keyword" value={searchKeyword} placeholder="動画を検索" onChange={function (event) { return setSearchKeyword(event.target.value); }}/>
                  </div>
                  <button type="submit" className="w-1/5 border-l border-solid border-gray-400 p-2 text-center text-gray-700 active:bg-gray-200">
                    検索
                  </button>
                </div>
              </form>
              <div className="mb-12">
                {menuLists.map(function (_a) {
            var themeLists = _a.themeLists, menuList = __rest(_a, ["themeLists"]);
            return (<div key={menuList.title} className="border-t border-solid border-gray-300 last:border-b">
                    <a href={menuList.url} onClick={menuList.clicked} className={classnames('flex items-center justify-between py-5 px-3', menuList.url && 'active:bg-gray-200')}>
                      <div className="flex items-center">
                        <FontAwesomeIcon fixedWidth icon={menuList.icon} className="mr-3 text-xl text-orange-400"/>
                        <div className="text-lg font-bold text-gray-700">
                          {menuList.title}
                        </div>
                      </div>
                      <FontAwesomeIcon fixedWidth icon={menuList.arrow} className="text-base text-gray-700"/>
                    </a>
                    {themeLists && (<div className="mb-5 pl-12">
                        {themeLists.map(function (themeList) { return (<a key={themeList.theme} href={themeList.themeUrl} onClick={themeList.clicked} className="block rounded py-2 text-lg text-gray-700 active:bg-gray-200">
                            {themeList.theme}
                          </a>); })}
                      </div>)}
                  </div>);
        })}
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>);
};
export default Component;
