import classnames from 'classnames';
export var Theme = {
    Default: 'border-orange-600',
    Accented: 'border-blue-900',
};
var Component = function (_a) {
    var caption = _a.caption, children = _a.children, _b = _a.theme, theme = _b === void 0 ? Theme.Default : _b;
    return (<div className="mb-3 w-full border-b border-solid border-gray-300">
      <div className="flex w-full items-center justify-between">
        <div className={classnames([
            'border-l-8 border-solid py-1 px-2 text-xl font-bold text-gray-600',
            theme,
        ])}>
          {caption}
        </div>
        {children && <div className="h-full">{children}</div>}
      </div>
    </div>);
};
export default Component;
