import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useController } from 'react-hook-form';
var Component = function (_a) {
    var options = _a.options, control = _a.control, fieldName = _a.name, _b = _a.vertical, vertical = _b === void 0 ? false : _b;
    var _c = useController({ control: control, name: fieldName }), error = _c.fieldState.error, _d = _c.field, value = _d.value, onChange = _d.onChange;
    return (<div className="mt-2">
      <div className={classnames('flex flex-col gap-2', {
            'md:flex-row': !vertical,
        })}>
        {options.map(function (_a) {
            var id = _a.id, name = _a.name;
            var isChecked = value === id;
            return (<button key={id} type="button" onClick={function () { return onChange(id); }} className={classnames('flex h-12 w-full cursor-pointer items-center rounded px-4 ring-inset', isChecked ? 'ring-2 ring-blue-900' : 'ring-1 ring-gray-300')}>
              <FontAwesomeIcon fixedWidth icon={isChecked ? faDotCircle : faCircle} className={classnames('text-lg', isChecked ? 'text-blue-900' : 'text-gray-300')}/>
              <div className="ml-2 flex h-full w-full items-center">{name}</div>
            </button>);
        })}
      </div>

      {error && (<div className="mt-1.5 text-xs text-red-500">{error.message}</div>)}
    </div>);
};
export default Component;
