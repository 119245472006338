var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
/**
 * ToDo: モーダルを閉じる際にアニメーションが反映されない不具合を修正する
 * アニメーションが動作する前にコンポーネントが再レンダリングされてしまっている可能性が高い
 */
export var useModal = function (BaseComponent) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(null), props = _b[0], setProps = _b[1];
    var _c = useState(null), options = _c[0], setOptions = _c[1];
    var closeModal = useCallback(function () {
        setIsOpen(false);
    }, []);
    var showModal = useCallback(function (modalProps, modalOptions) {
        if (modalOptions === void 0) { modalOptions = {}; }
        setProps(__assign(__assign({}, modalProps), { close: closeModal }));
        setOptions(modalOptions);
        setIsOpen(true);
    }, [closeModal]);
    var ModalComponent = useCallback(function () { return (<Modal isOpen={isOpen} onClose={closeModal} blockScrollOnMount>
        <ModalOverlay />
        <ModalContent maxWidth="600px" width="calc(100% - 20px)">
          <ModalBody className="!p-0">
            {props && <BaseComponent {...props}/>}
          </ModalBody>
        </ModalContent>
      </Modal>); }, [props, isOpen, options, closeModal, BaseComponent]);
    return [showModal, ModalComponent];
};
