import { init, captureException } from '@sentry/react';
import { useCallback, useState } from 'react';
import { ErrorBoundary, } from 'react-error-boundary';
var isProduction = !!process.env.IS_PRODUCTION;
var sentryRelease = process.env.SENTRY_RELEASE;
var sentryEnvironment = process.env.SENTRY_ENVIRONMENT;
init({
    tracesSampleRate: 0,
    enabled: isProduction,
    release: sentryRelease,
    environment: sentryEnvironment,
    dsn: 'https://f170951fdbde47f098177beb95c0db89@o1244440.ingest.sentry.io/6474892',
});
export var SentryErrorBoundary = function (_a) {
    var children = _a.children;
    var onError = function (error) {
        captureException(error);
    };
    return (<ErrorBoundary onError={onError} 
    // 子孫のコンポーネントでエラーが発生した際のフォールバックUI
    FallbackComponent={function () { return (<div className="mx-auto max-w-screen-sm rounded border border-solid border-yellow-500 bg-yellow-100 p-4 text-base text-yellow-700">
          システムで一時的な不具合が発生しております。ご不便をおかけして申し訳ございません。現在技術者が状況の調査・対応を行なっております。状況が改善されない場合、大変お手数ですが
          info@shands.jp までご連絡を頂けますと幸いです。
        </div>); }}>
      {children}
    </ErrorBoundary>);
};
// React内の非同期処理で発生した例外は通常ErrorBoundaryで検知できない
// useStateのセット関数内で例外を投げることでErrorBoundaryで検知できるようにする
export var useThrowException = function () {
    var _a = useState(), setState = _a[1];
    return useCallback(function (error) {
        return setState(function () {
            throw error;
        });
    }, []);
};
