import { z } from 'zod';
export var schema = z
    .object({
    email: z
        .string()
        .min(1, { message: 'メールアドレスを入力してください。' })
        .email({ message: '正しいメールアドレスを入力してください。' }),
    lastName: z.string().min(1, { message: '姓を入力してください。' }),
    firstName: z.string().min(1, { message: '名を入力してください。' }),
    password: z
        .string()
        .min(6, { message: 'パスワードは6文字以上で入力してください。' })
        .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/, {
        message: 'パスワードは半角の英数字・記号で入力してください。',
    })
        .or(z.literal('')),
    isPasswordMigrated: z.boolean(),
})
    .refine(function (value) { return value.isPasswordMigrated || value.password !== ''; }, {
    path: ['password'],
    message: '新しいパスワードを入力してください。',
});
