import { formatDate } from '@main/app/features/date.helper';
import Image from '@main/ui/atoms/Image';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hooks';
export var Component = function (_a) {
    var posts = _a.posts, currentPage = _a.currentPage, totalPage = _a.totalPage, categoryName = _a.categoryName, totalCount = _a.totalCount;
    return (<PageLayout caption={categoryName} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: categoryName },
        ]}>
      <ArticleListLayout currentPage={currentPage} totalPage={totalPage} totalCount={totalCount}>
        {posts.map(function (post) { return (<a key={post.id} href={"/posts/".concat(post.id)}>
            <Image url={post.thumbnailUrl}/>
            <p className="mt-2 mb-1 font-bold text-gray-800">{post.title}</p>
            <p className="text-xs leading-none text-gray-500 sm:text-sm">
              {formatDate(post.publishedAt)}
            </p>
          </a>); })}
      </ArticleListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
