import { formatDate } from '@main/app/features/date.helper';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SearchResult from '@main/ui/components/SearchResult';
import SideMenu from '@main/ui/components/SideMenu';
import useHook from './hooks';
export var Component = function (_a) {
    var totalCount = _a.totalCount, currentPage = _a.currentPage, totalPage = _a.totalPage, keyword = _a.keyword, items = _a.items, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption={"\u691C\u7D22\u7D50\u679C\uFF1A".concat(keyword)} breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: '検索' }]} headerContent={totalCount > 0 && (<SearchResult totalCount={totalCount} currentPage={currentPage} totalPage={totalPage}/>)} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      <ArticleListLayout totalCount={totalCount} currentPage={currentPage} totalPage={totalPage} notFoundContent={<div className="pb-10 text-base text-gray-600">
            キーワード「{keyword}
            」に関連する動画が見つかりませんでした。別のキーワードでお試しください。
          </div>}>
        {items.map(function (_a) {
            var article = _a.article, isCompleted = _a.isCompleted;
            return (<ArticleItem key={article.id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={"".concat(formatDate(article.publishedAt), "\u306B\u516C\u958B")} isCompleted={isCompleted}/>);
        })}
      </ArticleListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
