import InlineLink from '@main/ui/atoms/InlineLink';
import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import useHook from './hook';
export var Component = function () {
    var _a = useHook(), submit = _a.submit, control = _a.control, isSubmitting = _a.isSubmitting, isSubmitSuccessful = _a.isSubmitSuccessful;
    return (<PageLayout singleColumn caption="メールアドレスの確認メール再送" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'メールアドレスの確認' },
        ]}>
      <div className="mt-3 w-full rounded-lg sm:mt-5 sm:border sm:p-10">
        {!isSubmitSuccessful ? (<form onSubmit={submit} className="grid items-center gap-y-4">
            <div className="w-full">
              <Controller name="email" control={control} render={function (_a) {
                var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
                return (<div>
                    <FormInputLayout {...field} type="email" error={error} isDirty={isDirty} label="メールアドレス" autoComplete="email" placeholder="t.takkyu@shands.jp"/>
                  </div>);
            }}/>
            </div>

            <div className="flex w-full justify-center md:mt-3">
              <div className="h-12 w-full md:w-56">
                <FormSubmitButton text="次へ" isDisabled={isSubmitting} isSubmitting={isSubmitting}/>
              </div>
            </div>

            <div className="mt-10 flex flex-col space-y-2 text-center">
              <InlineLink href="/users/sign_in" text="ログインはこちら"/>
            </div>
          </form>) : (<div className="mb-5 rounded border border-solid border-blue-300 bg-blue-100 p-4 text-center text-base text-blue-700">
            メールアドレスの確認メールを再送しました。
          </div>)}
      </div>
    </PageLayout>);
};
export default Component;
