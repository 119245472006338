var _a;
export var ErrorCode = {
    UserNotFound: 'USER_NOT_FOUND',
    UserHasResigned: 'USER_HAS_RESIGNED',
    UserHasAlreadySignedIn: 'USER_HAS_ALREADY_SIGNED_IN',
    EmailOrPasswordInvalid: 'EMAIL_OR_PASSWORD_INVALID',
    EmailConfirmationRequired: 'EMAIL_CONFIRMATION_REQUIRED',
    SignInNotAllowed: 'SIGN_IN_NOT_ALLOWED',
};
var errorMessageMap = (_a = {},
    _a[ErrorCode.UserNotFound] = 'ユーザーが見つかりません。',
    _a[ErrorCode.UserHasResigned] = 'ユーザーが退会済みです。',
    _a[ErrorCode.UserHasAlreadySignedIn] = 'ユーザーは既にログイン済みです。',
    _a[ErrorCode.EmailOrPasswordInvalid] = 'メールアドレスまたはパスワードが間違っています。',
    _a[ErrorCode.EmailConfirmationRequired] = 'メールアドレスの確認が必要です。',
    _a[ErrorCode.SignInNotAllowed] = 'お客様のアカウントは現在ログインできない状態です。お手数をおかけしますが、事務局までお問い合わせください。',
    _a);
export var getErrorMessageFromCode = function (code) {
    if (code in errorMessageMap) {
        return errorMessageMap[code];
    }
    return '不明なエラーが発生しました。';
};
