import InlineLink from '@main/ui/atoms/InlineLink';
import Error from '@main/ui/components/Error';
import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import PasswordInput from '../../components/PasswordInput';
import useHook from './hooks';
export var Component = function (_a) {
    var submit = _a.submit, control = _a.control, errorCode = _a.errorCode, isSignInInProgress = _a.isSignInInProgress, isConfirmedMessageShown = _a.isConfirmedMessageShown, isSignedOutMessageShown = _a.isSignedOutMessageShown, isConfirmationFailedMessageShown = _a.isConfirmationFailedMessageShown, isConfirmationRequiredMessageShown = _a.isConfirmationRequiredMessageShown, isConfirmationInstructionResentMessageShown = _a.isConfirmationInstructionResentMessageShown;
    return (<PageLayout caption="ログイン" breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: 'ログイン' }]} singleColumn>
      <div className="flex justify-center">
        <div className="mt-3 w-full rounded-lg sm:mt-5 sm:border sm:p-10">
          {!isSignInInProgress && errorCode && <Error errorCode={errorCode}/>}

          <div className="mb-4 grid gap-y-3 empty:hidden">
            {isSignedOutMessageShown && (<div className="rounded border border-blue-400 bg-blue-200 p-2 text-sm text-blue-900 md:text-base">
                ログアウトしました。
              </div>)}

            {isConfirmationInstructionResentMessageShown && (<div className="rounded border border-blue-400 bg-blue-200 p-2 text-sm text-blue-900 md:text-base">
                メールアドレスの開通確認メールを再送しました。
              </div>)}

            {isConfirmedMessageShown && (<div className="rounded border border-blue-400 bg-blue-200 p-2 text-sm text-blue-900 md:text-base">
                メールアドレスの開通確認が完了しました。
              </div>)}

            {isConfirmationRequiredMessageShown && (<div className="rounded border border-red-400 bg-red-200 p-2 text-sm text-red-600 md:text-base">
                メールアドレスの開通確認が必要です。
              </div>)}

            {isConfirmationFailedMessageShown && (<div className="rounded border border-red-400 bg-red-200 p-2 text-sm text-red-600 md:text-base">
                メールアドレスの開通確認が失敗しました。開通確認リンクが不正です。
              </div>)}
          </div>

          <form noValidate onSubmit={submit}>
            <div className="space-y-5">
              <Controller control={control} name="email" render={function (_a) {
            var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
            return (<FormInputLayout {...field} type="email" error={error} isDirty={isDirty} label="メールアドレス" autoComplete="current-email" placeholder="example@shands.jp"/>);
        }}/>
              <Controller control={control} name="password" render={function (_a) {
            var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
            return (<PasswordInput {...field} label="パスワード" placeholder="******" error={error} isDirty={isDirty}/>);
        }}/>
            </div>
            <div className="m-auto mt-8 h-12 w-1/3">
              <FormSubmitButton text="ログイン" isSubmitting={isSignInInProgress} isDisabled={isSignInInProgress}/>
            </div>
          </form>
          <div className="mt-10 flex flex-col space-y-2 text-center">
            <InlineLink href="/users/password/new" text="パスワードをお忘れの方はこちら"/>
            <InlineLink text="新規ユーザー登録はこちら" href="https://www.shands.jp/lp/base"/>
          </div>
        </div>
      </div>
    </PageLayout>);
};
var Container = function () { return <Component {...useHook()}/>; };
export default Container;
