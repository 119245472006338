import { gmoFormPropsSchema } from '@main/ui/components/GmoForm';
import { z } from 'zod';
export var propsSchema = z.object({
    email: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    isPremiumJoinUser: z.boolean(),
    unconfirmedEmail: z.string().nullable(),
    transferExpireAt: z.string().nullable(),
    creditCardRegisteredAt: z.string().nullable(),
    unconfirmedAccessExpireAt: z.string().nullable(),
    trialEndAt: z.string().nullable(),
    isBillByStripe: z.boolean(),
    isBillingStarted: z.boolean(),
    isPremiumReferralAvailable: z.boolean(),
    billingStatus: z.enum([
        'billing_active',
        'billing_canceled',
        'billing_restricted',
        'billing_not_started',
        'billing_trial',
        'billing_trial_canceled',
    ]),
    billingPlan: z
        .enum([
        'billing_plan_premium',
        'billing_plan_club_standard',
        'billing_plan_club_platinum',
    ])
        .nullable(),
    gmoFormProps: gmoFormPropsSchema,
});
