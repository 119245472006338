import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import IconButton from '@main/ui/components/IconButton';
import classnames from 'classnames';
var Component = function (_a) {
    var isCompleted = _a.isCompleted, onClicked = _a.onClicked, isLoading = _a.isLoading;
    return (<IconButton onClicked={onClicked} icon={faCheckCircle} isLoading={isLoading} caption={isCompleted ? '完了済み' : '完了にする'} classNames={classnames('min-w-[130px] text-white', {
            'bg-orange-400': !isCompleted,
            'bg-gray-400': isCompleted,
            'hover:opacity-90 active:opacity-100': !isLoading,
        })}/>);
};
export default Component;
