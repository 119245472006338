import { formatDate } from '@main/app/features/date.helper';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SearchResult from '@main/ui/components/SearchResult';
import SideMenu from '@main/ui/components/SideMenu';
export var Component = function (_a) {
    var items = _a.items, totalPage = _a.totalPage, totalCount = _a.totalCount, currentPage = _a.currentPage, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption="新着動画" breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: '新着動画' }]} headerContent={totalCount > 0 && (<SearchResult totalCount={totalCount} currentPage={currentPage} totalPage={totalPage}/>)} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      <ArticleListLayout totalCount={totalCount} currentPage={currentPage} totalPage={totalPage}>
        {items.map(function (_a) {
            var article = _a.article, isCompleted = _a.isCompleted;
            return (<ArticleItem id={article.id} key={article.id} title={article.title} isCompleted={isCompleted} movieLength={article.length} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl} coachImageUrl={article.coachImageUrl} comment={"".concat(formatDate(article.publishedAt), "\u306B\u516C\u958B")}/>);
        })}
      </ArticleListLayout>
    </PageLayout>);
};
export default Component;
