import Error from '@main/ui/components/Error';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import PasswordInput from '../../components/PasswordInput';
import useHook from './hooks';
export var Component = function (_a) {
    var submit = _a.submit, control = _a.control, isPasswordResetInProgress = _a.isPasswordResetInProgress, errorCode = _a.errorCode, tokenErrorMessage = _a.tokenErrorMessage;
    return (<PageLayout caption="パスワードの再設定" breadcrumbs={[{ caption: 'トップ' }, { caption: 'パスワード再設定' }]} singleColumn>
      <div className="flex justify-center">
        <div className="mt-3 w-full rounded-lg sm:mt-5 sm:border sm:p-10">
          {!isPasswordResetInProgress && errorCode && (<Error errorCode={errorCode}/>)}
          {tokenErrorMessage && (<div className="mb-4 rounded border-2 border-red-400 bg-red-200 p-2 text-sm md:text-base">
              <p className="text-red-600">{tokenErrorMessage}</p>
            </div>)}
          <form onSubmit={submit}>
            <div className="space-y-5">
              <Controller control={control} name="newPassword" render={function (_a) {
            var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
            return (<PasswordInput {...field} label="新しいパスワード" placeholder="******" subtitle="英数字6文字以上" error={error} isDirty={isDirty}/>);
        }}/>
            </div>
            <div className="m-auto mt-8 h-12 w-3/5 md:w-2/5">
              <FormSubmitButton isSubmitting={isPasswordResetInProgress} isDisabled={isPasswordResetInProgress} text="パスワードを再設定する"/>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>);
};
var Container = function () { return <Component {...useHook()}/>; };
export default Container;
