import { z } from 'zod';
import dayjs from '@/utils/dayjs';
export var schema = z.object({
    birthYear: z.preprocess(function (value) { return Number(value); }, z
        .number({
        required_error: '入力してください。',
        invalid_type_error: '入力してください。',
    })
        .min(1900, { message: '1900以上の数値を入力してください。' })
        .max(dayjs().tz().year(), {
        message: "".concat(dayjs().tz().year(), "\u4EE5\u4E0B\u306E\u6570\u5024\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
    })),
    gender: z.number({ required_error: '選択してください。' }),
    career: z.number({ required_error: '選択してください。' }),
    howToKnow: z.number({ required_error: '選択してください。' }),
    playStyle: z.number({ required_error: '選択してください。' }),
    playerType: z.number({ required_error: '選択してください。' }),
    playFrequency: z.number({ required_error: '選択してください。' }),
    expectations: z.array(z.number()),
});
