import classnames from 'classnames';
import { useMemo } from 'react';
var Component = function (_a) {
    var categories = _a.categories, articleCountMap = _a.articleCountMap;
    // 総件数
    var itemCount = useMemo(function () { return categories.length; }, [categories]);
    // 総件数が偶数
    var hasEvenItems = useMemo(function () { return itemCount % 2 === 0; }, [itemCount]);
    // 総件数が奇数
    var hasOddItems = useMemo(function () { return !hasEvenItems; }, [hasEvenItems]);
    // 最初からn番目の要素の要素か判定（最初の要素を0とする）
    var isFirstItem = function (index, offset) {
        if (offset === void 0) { offset = 0; }
        return index === offset;
    };
    // 最後からn番目の要素か判定（最後の要素を0とする）
    var isLastItem = function (index, offset) {
        if (offset === void 0) { offset = 0; }
        return index === itemCount - offset - 1;
    };
    // 偶数番目の要素か判定
    var isEvenItem = function (index) { return index % 2 === 0; };
    // 奇数番目の要素か判定
    var isOddItem = function (index) { return !isEvenItem(index); };
    // 左上の角丸：先頭から1番目の要素
    var hasTopLeftRadius = function (index) { return isFirstItem(index); };
    // 右上の角丸：総件数が1件なら先頭の要素・総件数が2件以上なら2番目の要素
    var hasTopRightRadius = function (index) {
        return itemCount === 1 || isFirstItem(index, 1);
    };
    // 左下の角丸：総件数が奇数なら後ろから1番目の要素・総件数が偶数なら後ろから2番目の要素
    var hasBottomLeftRadius = function (index) {
        return (isLastItem(index) && hasOddItems) ||
            (isLastItem(index, 1) && hasEvenItems);
    };
    // 右下の角丸：後ろから1番目の要素・総件数が奇数なら後ろから2番目の要素
    var hasBottomRightRadius = function (index) {
        return isLastItem(index) || (hasOddItems && isLastItem(index, 1));
    };
    // margin-topを-1px
    var decreaseMarginTop = function (index) { return index > 1; };
    // margin-leftを-1px
    var decreaseMarginLeft = function (index) { return isOddItem(index); };
    return (<div className="grid grid-cols-2">
      {categories.map(function (category, index) { return (<a key={category.slug} href={category.slug} className={classnames('flex h-[42px] items-center border border-solid border-gray-300 px-3 text-gray-700 active:bg-gray-100', {
                'rounded-tl': hasTopLeftRadius(index),
                'rounded-tr': hasTopRightRadius(index),
                'rounded-bl': hasBottomLeftRadius(index),
                'rounded-br': hasBottomRightRadius(index),
                'mt-[-1px]': decreaseMarginTop(index),
                'ml-[-1px]': decreaseMarginLeft(index),
            })}>
          <div className="flex h-full flex-1 items-center overflow-hidden text-sm font-bold leading-tight">
            {category.name.slice(0, 20)}
          </div>
          {category.id in articleCountMap && (<div className="text-xs leading-none text-gray-500">
              {articleCountMap[category.id]}本
            </div>)}
        </a>); })}
    </div>);
};
export default Component;
