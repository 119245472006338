var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDisclosure } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import { propsSchema } from './props';
var useHook = function (props) {
    var _a = propsSchema.parse(props), pendingPostModal = _a.pendingPostModal, completedArticleIds = _a.completedArticleIds, restProps = __rest(_a, ["pendingPostModal", "completedArticleIds"]);
    var isModalOpened = useRef(false);
    var _b = useDisclosure(), showPasswordResetModal = _b.onOpen, isPasswordResetModalOpen = _b.isOpen, closePasswordResetModal = _b.onClose;
    var _c = useDisclosure(), showAttributeUpdatedModal = _c.onOpen, isAttributeUpdatedModalOpen = _c.isOpen, closeAttributeUpdatedModal = _c.onClose;
    var _d = useDisclosure(), showCreditCardRegisteredModal = _d.onOpen, isCreditCardRegisteredModalOpen = _d.isOpen, closeCreditCardRegisteredModal = _d.onClose;
    var _e = useDisclosure(), showConfirmationInstructionResentModal = _e.onOpen, isConfirmationInstructionResentModalOpen = _e.isOpen, closeConfirmationInstructionResentModal = _e.onClose;
    var _f = useDisclosure(), showConfirmedModal = _f.onOpen, isConfirmedModalOpen = _f.isOpen, closeConfirmedModal = _f.onClose;
    var _g = useDisclosure(), showCancelCompletedModal = _g.onOpen, isCancelCompletedModalOpen = _g.isOpen, closeCancelCompletedModal = _g.onClose;
    var _h = useDisclosure(), showThanksToUpgradeModal = _h.onOpen, isThanksToUpgradeModalOpen = _h.isOpen, closeThanksToUpgradeModal = _h.onClose;
    var _j = useDisclosure(), showConfirmationFailedModal = _j.onOpen, isConfirmationFailedModalOpen = _j.isOpen, closeConfirmationFailedModal = _j.onClose;
    var _k = useDisclosure(), showPendingPostModalModal = _k.onOpen, isPendingPostModalModalOpen = _k.isOpen, closePendingPostModalModal = _k.onClose;
    var isLiveInProgress = useCallback(function (liveSchedule) {
        var currentTime = dayjs();
        var startAt = dayjs(liveSchedule.startAt);
        return startAt.isBefore(currentTime);
    }, []);
    var isArticleCompleted = useCallback(function (articleId) { return completedArticleIds.includes(articleId); }, [completedArticleIds]);
    // URLにクエリパラメタが指定されていたら、対応するモーダルを表示する
    // 2個以上同時に出ないようにする
    useEffect(function () {
        if (isModalOpened.current) {
            return;
        }
        // 通知メッセージのモーダル
        {
            var url = new URL(window.location.href);
            var items = [
                // クレジットカード登録完了
                {
                    key: 'credit_card_registered',
                    handler: showCreditCardRegisteredModal,
                },
                // パスワード再設定完了
                {
                    key: 'password_reset_completed',
                    handler: showPasswordResetModal,
                },
                // アンケート回答完了
                {
                    key: 'attribute_update_completed',
                    handler: showAttributeUpdatedModal,
                },
                // メールアドレスの開通確認メール再送完了
                {
                    key: 'confirmation_instructions_resent',
                    handler: showConfirmationInstructionResentModal,
                },
                // メールアドレスの開通確認完了
                {
                    key: 'email_confirmed',
                    handler: showConfirmedModal,
                },
                // メールアドレスの開通確認失敗
                {
                    key: 'email_confirmation_failed',
                    handler: showConfirmationFailedModal,
                },
                // 解約手続き完了
                {
                    key: 'cancel_completed',
                    handler: showCancelCompletedModal,
                },
                // 試用期間を途中終了してプレミアムサービス開始
                {
                    key: 'upgrade_completed',
                    handler: showThanksToUpgradeModal,
                },
            ];
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                if (url.searchParams.has(item.key)) {
                    // URLのクエリパラメタを削除する
                    url.searchParams.delete(item.key);
                    window.history.replaceState(null, '', url.toString());
                    item.handler();
                    isModalOpened.current = true;
                    return;
                }
            }
        }
        // お知らせモーダル
        if (pendingPostModal) {
            showPendingPostModalModal();
            isModalOpened.current = true;
            return;
        }
    }, [
        pendingPostModal,
        showConfirmedModal,
        showPasswordResetModal,
        showThanksToUpgradeModal,
        showAttributeUpdatedModal,
        showPendingPostModalModal,
        showCreditCardRegisteredModal,
        showConfirmationInstructionResentModal,
    ]);
    return __assign(__assign({}, restProps), { pendingPostModal: pendingPostModal, isLiveInProgress: isLiveInProgress, isArticleCompleted: isArticleCompleted, closeConfirmedModal: closeConfirmedModal, isConfirmedModalOpen: isConfirmedModalOpen, closePasswordResetModal: closePasswordResetModal, isPasswordResetModalOpen: isPasswordResetModalOpen, closeCancelCompletedModal: closeCancelCompletedModal, closeThanksToUpgradeModal: closeThanksToUpgradeModal, isThanksToUpgradeModalOpen: isThanksToUpgradeModalOpen, isCancelCompletedModalOpen: isCancelCompletedModalOpen, closeAttributeUpdatedModal: closeAttributeUpdatedModal, closePendingPostModalModal: closePendingPostModalModal, isAttributeUpdatedModalOpen: isAttributeUpdatedModalOpen, isPendingPostModalModalOpen: isPendingPostModalModalOpen, closeConfirmationFailedModal: closeConfirmationFailedModal, isConfirmationFailedModalOpen: isConfirmationFailedModalOpen, closeCreditCardRegisteredModal: closeCreditCardRegisteredModal, isCreditCardRegisteredModalOpen: isCreditCardRegisteredModalOpen, closeConfirmationInstructionResentModal: closeConfirmationInstructionResentModal, isConfirmationInstructionResentModalOpen: isConfirmationInstructionResentModalOpen });
};
export default useHook;
