import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { propsSchema } from './props';
var useHook = function (props) {
    var _a = propsSchema.parse(props), code = _a.code, referrals = _a.referrals;
    var toast = useToast();
    var url = "https://www.shands.jp/lp/base?coupon=".concat(code);
    var copyUrl = useCallback(function () {
        navigator.clipboard
            .writeText(url)
            .then(function () {
            toast({
                duration: 3000,
                isClosable: true,
                status: 'success',
                position: 'bottom-left',
                title: 'リンクをコピーしました！',
            });
        })
            .catch(function () {
            alert('コピーに失敗しました。お手数ですが、リンクを選択してコピーをお試しください。');
        });
    }, [url]);
    return { url: url, copyUrl: copyUrl, referrals: referrals };
};
export default useHook;
