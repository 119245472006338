var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
var useCustomHook = function (serverProps) {
    var breadcrumbs = useMemo(function () {
        var itemCount = serverProps.ancestorCategories.length;
        return __spreadArray([
            { caption: 'トップ', link: '/' }
        ], serverProps.ancestorCategories.map(function (category, index) {
            return index !== itemCount - 1
                ? {
                    caption: category.name,
                    link: "/categories/".concat(category.slug),
                }
                : {
                    caption: category.name,
                };
        }), true);
    }, [serverProps]);
    var parentCategory = useMemo(function () {
        var ancestorCategoryCount = serverProps.ancestorCategories.length;
        if (ancestorCategoryCount < 2) {
            return null;
        }
        return serverProps.ancestorCategories[ancestorCategoryCount - 2];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverProps.ancestorCategories]);
    return __assign(__assign({}, serverProps), { breadcrumbs: breadcrumbs, parentCategory: parentCategory });
};
export default useCustomHook;
