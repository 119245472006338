import { faCheckSquare, faSquare as faSquareRegular, } from '@fortawesome/free-regular-svg-icons';
import { faSquare as faSquareSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * 背景を白塗りにしたチェックアイコン
 */
var Component = function (_a) {
    var isChecked = _a.isChecked;
    return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon size="lg" className="text-white" icon={faSquareSolid}/>

      <FontAwesomeIcon size="lg" className="text-red-500" icon={isChecked ? faCheckSquare : faSquareRegular}/>
    </span>);
};
export default Component;
