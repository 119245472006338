import { z } from 'zod';
export var propsSchema = z.object({
    status: z.enum([
        'resignable',
        'already_resigned',
        // Stripe系統
        'stripe_require_cancel',
        'stripe_billing_restricted',
        'stripe_require_cancel_trial',
        // GMO系統
        'gmo_require_cancel',
        'gmo_credit_card_error',
    ]),
});
