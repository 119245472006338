import { z } from 'zod';
export var gmoFormPropsSchema = z.object({
    gmoSiteId: z.string(),
    gmoRetUrl: z.string(),
    gmoShopId: z.string(),
    gmoPostUrl: z.string(),
    gmoDateTime: z.string(),
    gmoMemberId: z.number(),
    gmoCancelUrl: z.string(),
    gmoMemberName: z.string(),
    gmoMemberPassString: z.string(),
});
