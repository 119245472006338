import PageLayout from '@main/ui/components/PageLayout';
import Markdown from '@main/ui/molecules/Markdown';
import content from './content.md';
var Page = function () {
    return (<PageLayout caption="利用規約" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '利用規約', link: '/rules' },
        ]}>
      <div className="mt-5">
        <Markdown content={content}/>
      </div>
    </PageLayout>);
};
export default Page;
