var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import { forwardRef } from 'react';
var Component = forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, isDirty = _a.isDirty, children = _a.children, rest = __rest(_a, ["label", "error", "isDirty", "children"]);
    return (<div>
        <fieldset className="flex w-full flex-col">
          <label>{label}</label>
          <div className={classnames('mt-1 flex h-40 w-full rounded bg-gray-100', {
            'border border-red-500 bg-white': error,
            'border border-gray-300 bg-white': !error && isDirty,
        })}>
            <textarea ref={ref} className="w-full appearance-none bg-transparent p-4 focus:outline-none focus-visible:outline-none" rows={10} {...rest}/>
            {children}
          </div>
        </fieldset>
        {error && (<div className="mt-1.5 text-xs text-red-500">{error.message}</div>)}
      </div>);
});
export default Component;
