import { Spinner } from '@chakra-ui/spinner';
import classnames from 'classnames';
var Component = function (_a) {
    var text = _a.text, onClick = _a.onClick, isDisabled = _a.isDisabled, isSubmitting = _a.isSubmitting, _b = _a.type, type = _b === void 0 ? 'submit' : _b;
    return (<button type={type} onClick={onClick} disabled={isDisabled} className={classnames('h-full w-full rounded py-3 font-bold text-white', isSubmitting
            ? 'cursor-default bg-gray-300'
            : 'cursor-pointer bg-blue-900')}>
      {isSubmitting ? <Spinner /> : text}
    </button>);
};
export default Component;
