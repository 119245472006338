import { useBreakpointValue } from '@chakra-ui/react';
import { faEllipsisH, faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useMemo } from 'react';
var Item = function (_a) {
    var href = _a.href, children = _a.children, _b = _a.active, active = _b === void 0 ? false : _b, _c = _a.noBorder, noBorder = _c === void 0 ? false : _c;
    var Tag = href ? 'a' : 'div';
    return (<Tag href={href} className={classnames('flex h-[32px] w-[32px] items-center justify-center rounded', {
            'border border-solid border-gray-300': !noBorder,
            'active:bg-gray-100': href,
            'bg-gray-100': active,
        })}>
      {children}
    </Tag>);
};
var Component = function (_a) {
    var currentPage = _a.currentPage, totalPage = _a.totalPage;
    var outerWindowSize = 1;
    var windowSize = useBreakpointValue({ base: 1, sm: 2 });
    var parser = new URL(window.location.href);
    parser.searchParams.set('page', (currentPage - 1).toString());
    var prevPageLink = parser.toString();
    parser.searchParams.set('page', (currentPage + 1).toString());
    var nextPageLink = parser.toString();
    var getPageLink = function (page) {
        var url = new URL(window.location.href);
        url.searchParams.set('page', page.toString());
        return url.toString();
    };
    var pageItems = useMemo(function () { return new Array(totalPage).fill(null).map(function (_, i) { return i + 1; }); }, [totalPage]);
    // 中央分
    var centerPageItems = useMemo(function () {
        var startNum = Math.max(1, currentPage - windowSize);
        var endNum = Math.min(totalPage, currentPage + windowSize);
        return pageItems.filter(function (page) { return startNum <= page && page <= endNum; });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, windowSize, pageItems]);
    // 先頭
    var firstOuterItems = useMemo(function () {
        return pageItems
            .filter(function (page) { return page < centerPageItems[0]; })
            .slice(0, outerWindowSize);
    }, [centerPageItems, pageItems, outerWindowSize]);
    // 末尾
    var lastOuterItems = useMemo(function () {
        return pageItems
            .filter(function (page) { return page > centerPageItems[centerPageItems.length - 1]; })
            .slice(-outerWindowSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outerWindowSize, centerPageItems]);
    // 戻るボタン
    var isPrevButtonShown = useMemo(function () { return currentPage > 1; }, [currentPage]);
    // 次へボタン
    var isNextButtonShown = useMemo(function () { return currentPage < totalPage; }, [currentPage, totalPage]);
    if (totalPage < 2) {
        return <></>;
    }
    return (<div className="flex justify-center gap-x-1">
      {isPrevButtonShown && (<Item href={prevPageLink}>
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth/>
        </Item>)}

      {firstOuterItems.length > 0 && (<>
          {firstOuterItems.map(function (page) { return (<Item key={page} href={getPageLink(page)}>
              <div className="text-sm font-bold text-gray-700">{page}</div>
            </Item>); })}
          <Item noBorder>
            <FontAwesomeIcon icon={faEllipsisH} fixedWidth/>
          </Item>
        </>)}

      {centerPageItems.map(function (page) { return (<Item key={page} active={page === currentPage} href={page !== currentPage ? getPageLink(page) : undefined}>
          <div className="text-sm font-bold text-gray-700">{page}</div>
        </Item>); })}

      {lastOuterItems.length > 0 && (<>
          <Item noBorder>
            <FontAwesomeIcon icon={faEllipsisH} fixedWidth/>
          </Item>
          {lastOuterItems.map(function (page) { return (<Item key={page} href={getPageLink(page)}>
              <div className="text-sm font-bold text-gray-700">{page}</div>
            </Item>); })}
        </>)}

      {isNextButtonShown && (<Item href={nextPageLink}>
          <FontAwesomeIcon icon={faChevronRight} fixedWidth/>
        </Item>)}
    </div>);
};
export default Component;
