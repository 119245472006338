import { faChevronRight, faChevronDown, faHome, faTableTennis, faHistory, faCheckCircle, faEdit, faCog, faStream, } from '@fortawesome/free-solid-svg-icons';
import { clickVisitPlaylistPageButton, clickVisitCompletePageButton, clickVisitCommentPageButton, clickVisitHistoryPageButton, clickVisitUsersPageButton, clickVisitLessonPageButton, } from '@/utils/analytics/log-event';
export var menuLists = [
    {
        icon: faHome,
        title: 'トップ',
        url: '/',
        arrow: faChevronRight,
    },
    {
        icon: faTableTennis,
        title: '卓球動画を探す',
        arrow: faChevronDown,
        themeLists: [
            {
                theme: 'フォアハンドに関する動画',
                themeUrl: '/categories/forehand',
            },
            {
                theme: 'サーブに関する動画',
                themeUrl: '/categories/serve',
            },
            {
                theme: 'テーマから探す',
                themeUrl: '/categories/level',
            },
            {
                theme: 'コーチ・選手で探す',
                themeUrl: '/categories/coaches',
            },
            {
                theme: 'レッスンコースから探す',
                themeUrl: '/lesson_course',
                clicked: function () {
                    clickVisitLessonPageButton('sidemenu');
                },
            },
            {
                theme: 'その他のテクニックで探す',
                themeUrl: '/categories/technique',
            },
        ],
    },
    {
        icon: faStream,
        title: 'プレイリスト',
        url: '/playlists',
        arrow: faChevronRight,
        clicked: function () {
            clickVisitPlaylistPageButton('sidemenu');
        },
    },
    {
        icon: faHistory,
        title: '視聴履歴',
        url: '/articles/history',
        arrow: faChevronRight,
        clicked: function () {
            clickVisitHistoryPageButton('sidemenu');
        },
    },
    {
        icon: faCheckCircle,
        title: '完了した動画',
        url: '/articles/complete',
        arrow: faChevronRight,
        clicked: function () {
            clickVisitCompletePageButton('sidemenu');
        },
    },
    {
        icon: faEdit,
        title: 'メモをした動画',
        url: '/articles/comment',
        arrow: faChevronRight,
        clicked: function () {
            clickVisitCommentPageButton('sidemenu');
        },
    },
    {
        icon: faCog,
        title: '設定',
        url: '/user',
        arrow: faChevronRight,
        clicked: function () {
            clickVisitUsersPageButton('sidemenu');
        },
    },
];
export var chargeMenuLists = [
    {
        icon: faStream,
        title: 'プレイリスト',
        url: '/playlists',
        clicked: function () {
            clickVisitPlaylistPageButton('header');
        },
    },
    {
        icon: faCheckCircle,
        title: '完了した動画',
        url: '/articles/complete',
        clicked: function () {
            clickVisitCompletePageButton('header');
        },
    },
    {
        icon: faEdit,
        title: 'メモをした動画',
        url: '/articles/comment',
        clicked: function () {
            clickVisitCommentPageButton('header');
        },
    },
    {
        icon: faHistory,
        title: '視聴履歴',
        url: '/articles/history',
        clicked: function () {
            clickVisitHistoryPageButton('header');
        },
    },
    {
        icon: faCog,
        title: '設定',
        url: '/user',
        clicked: function () {
            clickVisitUsersPageButton('header');
        },
    },
];
export var noChargeMenuLists = [
    {
        icon: faCog,
        title: '設定',
        url: '/user',
        clicked: function () {
            clickVisitUsersPageButton('header');
        },
    },
];
export var sideMenuLists = [
    {
        icon: faHome,
        title: 'トップ',
        url: '/',
        arrow: faChevronRight,
    },
    {
        icon: faTableTennis,
        title: '卓球動画を探す',
        arrow: faChevronDown,
    },
];
