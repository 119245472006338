import PageLayout from '@main/ui/components/PageLayout';
var Component = function () {
    return (<PageLayout singleColumn caption="解約手続き完了" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: '解約手続き' },
        ]}>
      <div className="text-base">
        解約手続きが完了しました。
        <br />
        来月初旬にプレミアムサービス関連のデータを削除させていただきます。
        <br />
        解約手続きの取り消しをご希望の場合、
        <a href="/contact/new" className="underline">
          お問い合わせフォーム
        </a>
        からご連絡ください。
      </div>
    </PageLayout>);
};
export default Component;
