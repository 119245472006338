export var footerLists = [
    {
        name: 'ヘルプ',
        url: '/help',
    },
    {
        name: '特定商取引法に基づく表示',
        url: '/tokusyo',
    },
    {
        name: 'プライバシーポリシー',
        url: '/privacy',
    },
    {
        name: '会員規約',
        url: '/member_rules',
    },
    {
        name: '利用規約',
        url: '/rules',
    },
];
