import Image from '@main/ui/atoms/Image';
var Component = function (_a) {
    var id = _a.id, title = _a.title, thumbnailUrl = _a.thumbnailUrl, publishedAt = _a.publishedAt, blogId = _a.blogId;
    return (<a href={"/blogs/".concat(blogId, "/").concat(id)} className="block w-full">
      <div className="relative mb-2">
        <Image url={thumbnailUrl}/>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="mb-1 text-sm font-bold text-gray-800 md:text-base">
            {title}
          </div>
          <div className="text-xs text-gray-500 md:text-sm">{publishedAt}</div>
        </div>
      </div>
    </a>);
};
export default Component;
