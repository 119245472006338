import classnames from 'classnames';
export var FontSize = {
    Small: 'text-sm',
    Base: 'text-base',
    Large: 'text-lg',
};
export var LineHeight = {
    None: 'leading-none',
    Base: 'leading-normal',
};
var Component = function (_a) {
    var text = _a.text, fontSize = _a.fontSize, lineHeight = _a.lineHeight;
    var classes = [fontSize, lineHeight, 'text-gray-800', 'font-bold'];
    return <div className={classnames(classes)}>{text}</div>;
};
export default Component;
