import dayjs from 'dayjs';
import { z } from 'zod';
export var schema = z.object({
    articles: z.array(z.object({
        isCompleted: z.boolean(),
        article: z.object({
            id: z.number(),
            title: z.string(),
            thumbnailUrl: z.string(),
            vimeoId: z.string(),
            publishedAt: z.string().transform(function (value) { return dayjs(value); }),
            length: z.string(),
            coachName: z.string(),
            coachImageUrl: z.string(),
            coachDescription: z.string(),
        }),
    })),
});
