var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LessonCourseChallengeButton, { ButtonState, } from '@main/ui/atoms/LessonCourseChallengeButton';
import VimeoPlayer from '@main/ui/atoms/VimeoPlayer';
import { useState } from 'react';
import { request } from '@/utils/request';
var Component = function (initialProps) {
    var _a = useState(initialProps), props = _a[0], setProps = _a[1];
    var _b = useState(false), isUpdating = _b[0], setIsUpdating = _b[1];
    var _c = useDisclosure(), isModalOpen = _c.isOpen, showModal = _c.onOpen, closeModal = _c.onClose;
    var onButtonClicked = function (endpoint) { return __awaiter(void 0, void 0, void 0, function () {
        var path, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isUpdating) {
                        return [2 /*return*/];
                    }
                    path = "/features/".concat(props.featureId, "/").concat(endpoint);
                    setIsUpdating(true);
                    return [4 /*yield*/, request({ path: path })];
                case 1:
                    data = (_a.sent()).data;
                    setProps(data);
                    setIsUpdating(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onQuestionButtonClicked = function () {
        showModal();
    };
    return (<>
      <div className="flex items-center">
        {!props.isChallenging && (<LessonCourseChallengeButton state={ButtonState.NotStarted} click={function () { return onButtonClicked('start_challenge'); }}/>)}
        {props.isChallenging && (<LessonCourseChallengeButton state={ButtonState.InProgress} click={function () { return onButtonClicked('cancel_challenge'); }}/>)}
        <button onClick={onQuestionButtonClicked} className="flex items-center py-2 px-3 leading-none underline">
          <FontAwesomeIcon className="mr-1 text-gray-500" icon={faQuestionCircle} fixedWidth={true}/>
          使い方を見る
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent width="calc(100% - 20px)" maxWidth="800px">
          <ModalHeader>レッスンコースの進行中機能とは？</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="pb-4">
              <VimeoPlayer videoId="652911600" aspectRatio={66.66}/>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
export default Component;
