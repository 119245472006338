import PageLayout from '@main/ui/components/PageLayout';
import { Link } from '@main/ui/components/SectionButton';
import TransferAnnounce from '@main/ui/components/TransferAnnounce';
import { Membership } from '@/types/membership';
var Component = function (_a) {
    var membership = _a.membership;
    return (<PageLayout singleColumn caption="お振込期限切れ" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: 'お振込期限切れ' },
        ]}>
      <div className="mb-6 text-base">
        振込でのお支払い期間を過ぎております。
        <br />
        現在、サービスのご利用ができない状態になっております。
      </div>

      {membership === Membership.Premium && (<div className="mb-8">
          <TransferAnnounce membership={membership}/>
        </div>)}

      <div className="mb-2 text-base">
        また、
        <span className="font-bold text-red-600">
          お振込みからクレジットカードでのお支払いへのご変更を希望される場合
        </span>
        は、以下のボタンよりクレジットカードのご登録をお願いします。
      </div>

      <div className="flex">
        <Link caption="クレジットカードを登録する" href={"/stripe_migrations?success_path=".concat(encodeURIComponent('/?credit_card_registered=1'))}/>
      </div>
    </PageLayout>);
};
export default Component;
