var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import { forwardRef } from 'react';
var Component = forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, isDirty = _a.isDirty, subtitle = _a.subtitle, children = _a.children, rest = __rest(_a, ["label", "error", "isDirty", "subtitle", "children"]);
    return (<div>
        <fieldset className="flex w-full flex-col">
          <label>{label}</label>
          <div 
    // TODO: remove auth-premium-user-edit class once conflicting stylesheets are gone
    className={classnames('auth-premium-user-edit mt-1 flex h-12 w-full rounded', {
            'border border-solid border-red-500': error,
            'border border-solid border-gray-300': !error && isDirty,
        }, error || isDirty ? 'bg-white' : 'bg-gray-100')}>
            <input ref={ref} className="w-full appearance-none bg-transparent pl-4 focus:outline-none focus-visible:outline-none" {...rest}/>
            {children}
          </div>
          {subtitle && !error && (<div className="mt-1 text-xs text-gray-400">{subtitle}</div>)}
        </fieldset>
        {error && (<div className="mt-1.5 text-xs text-red-500">{error.message}</div>)}
      </div>);
});
export default Component;
