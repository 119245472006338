import { formatDate } from '@main/app/features/date.helper';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import Triangle from '@main/ui/atoms/Triangle';
import PostItem from '@main/ui/components/PostItem';
var Component = function (_a) {
    var caption = _a.caption, url = _a.url, posts = _a.posts;
    return (<div>
      <SectionHeader caption={caption}>
        {url && (<a href={url} className="flex items-center p-1 text-xs font-thin leading-none text-gray-500 no-underline">
            もっと見る
            <Triangle width={8} colorClass="bg-gray-500"/>
          </a>)}
      </SectionHeader>
      <div className="grid gap-y-2 md:grid-cols-2">
        {posts.map(function (post) { return (<PostItem key={post.id} id={post.id} title={post.title} thumbnailUrl={post.thumbnailUrl} publishedAt={formatDate(post.publishedAt)}/>); })}
      </div>
    </div>);
};
export default Component;
