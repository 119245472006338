import PageLayout from '@main/ui/components/PageLayout';
import TransferAnnounce from '@main/ui/components/TransferAnnounce';
import { Membership } from '@/types/membership';
var Page = function () {
    return (<PageLayout caption="銀行振込のご案内" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '銀行振込のご案内', link: '/bank_account' },
        ]}>
      <div className="mt-5">
        <TransferAnnounce membership={Membership.Premium}/>
      </div>
    </PageLayout>);
};
export default Page;
