import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import FormTextAreaLayout from '@main/ui/components/FormTextAreaLayout';
import PageLayout from '@main/ui/components/PageLayout';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import useHook from './hooks';
export var Component = function (_a) {
    var submit = _a.submit, control = _a.control, isSignedIn = _a.isSignedIn, isSucceeded = _a.isSucceeded, isSubmitting = _a.isSubmitting;
    return (<PageLayout caption="お問い合わせ" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'お問い合わせ', link: '/contact/new' },
        ]} singleColumn>
      <div className="mb-5 flex w-full flex-col items-center justify-center">
        <div className="mt-5 w-full md:w-[630px]">
          {isSucceeded ? (<div className="my-2 rounded border border-blue-700 bg-blue-100 p-4 text-center leading-relaxed text-blue-700">
              お問い合わせの送信が完了しました。
              <br />
              確認のメールが自動送信されますので、ご確認ください。
              <br />
              お問い合わせ頂き、ありがとうございました。
            </div>) : (<>
              <ul className={classnames('pl-5 text-base leading-loose text-zinc-700', isSignedIn ? 'mb-12' : 'mb-8')}>
                <li>
                  こちらのお問い合わせからは、卓球の技術的なご質問は一切受けつけておりません。
                </li>
                <li>
                  退会、サービス解除の申請はこちらからは受け付けておりません。
                  申請頂いても、退会、サービス解除にはなりませんのでご注意下さい。
                  申請方法は、ヘルプをご参照下さい。
                </li>
                <li>土日祝日のお問い合わせは翌営業日以降の対応になります。</li>
                <li>
                  「shands.jp」からのメールを受信できるようにお願いします。
                </li>
                <li>
                  自動返信メールが届かないお客様は内容をご確認の上、再度お問い合わせください。
                </li>
                <li>
                  ログインできない方は以下を記載下さい。
                  <br />
                  ①機種：Mac/Win or iPhone/Android or タブレット
                  <br />
                  ②ブラウザの種類：（IE/Chrome/Firefox）
                  <br />
                  ③ログイン失敗時：画面に表示されている内容
                </li>
              </ul>
              <form onSubmit={submit} className="flex flex-col items-center gap-y-2">
                {!isSignedIn && (<>
                    <Controller control={control} name="email" render={function (_a) {
                    var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
                    return (<div className="w-full">
                          <FormInputLayout {...field} label="メールアドレス" type="email" placeholder="example@shands.jp" error={error} isDirty={isDirty}/>
                        </div>);
                }}/>
                    <Controller control={control} name="name" render={function (_a) {
                    var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
                    return (<div className="w-full">
                          <FormInputLayout {...field} label="お名前" placeholder="卓球一郎" type="text" error={error} isDirty={isDirty}/>
                        </div>);
                }}/>
                  </>)}
                <Controller control={control} name="content" render={function (_a) {
                var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
                return (<div className="w-full">
                      <FormTextAreaLayout {...field} label="お問い合わせ内容" error={error} isDirty={isDirty}/>
                    </div>);
            }}/>
                <div className="mb-2 text-sm text-zinc-400 md:mt-7">
                  このフォームを送信することで、プライバシーポリシーに同意したものとみなします。
                </div>
                <div>
                  <div className="h-12 w-56">
                    <FormSubmitButton text="送信する" isSubmitting={isSubmitting} isDisabled={isSubmitting}/>
                  </div>
                </div>
              </form>
            </>)}
        </div>
      </div>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
