import { formatDate } from '@main/app/features/date.helper';
import Image from '@main/ui/atoms/Image';
import SectionHeader, { Theme } from '@main/ui/atoms/SectionHeader';
var Component = function (_a) {
    var hiraokaBlogFeeds = _a.hiraokaBlogFeeds, itagakiBlogFeeds = _a.itagakiBlogFeeds, shandsClubFeeds = _a.shandsClubFeeds;
    return (<>
      <div className="mt-9 bg-[#f7fafc] py-9 md:py-11">
        <div className=" md:mx-auto md:max-w-7xl">
          <div className="mx-2.5 md:flex md:pt-2">
            <div className="md:mr-5 md:w-6/12">
              <SectionHeader caption="平岡義博ブログ" theme={Theme.Accented}>
                <a className="text-sm text-gray-400 underline" href="/blogs/hiraoka-yoshihiro">
                  もっと見る
                </a>
              </SectionHeader>
              {hiraokaBlogFeeds.map(function (feed) {
            return (<a key={feed.url} href={feed.url} className="flex">
                    {feed.thumbnailUrl && (<div className="w-5/12 pr-3 pb-2">
                        <Image url={feed.thumbnailUrl}/>
                      </div>)}
                    <div className="mb-1 w-7/12 text-sm text-gray-800 md:text-base">
                      <p className="mb-1 font-bold text-gray-800">
                        {feed.title}
                      </p>
                      <p className="text-xs leading-none text-gray-500 sm:text-sm">
                        {formatDate(feed.publishedAt)}
                      </p>
                    </div>
                  </a>);
        })}
            </div>
            <div className="pt-9 md:w-6/12 md:py-0">
              <SectionHeader caption="板垣孝司ブログ" theme={Theme.Accented}>
                <a className="text-sm text-gray-400 underline" href="/blogs/itagaki-koji">
                  もっと見る
                </a>
              </SectionHeader>
              {itagakiBlogFeeds.map(function (feed) {
            return (<a key={feed.url} href={feed.url} className="flex">
                    {feed.thumbnailUrl && (<div className="w-5/12 pr-3 pb-2">
                        <Image url={feed.thumbnailUrl}/>
                      </div>)}
                    <div className="mb-1 w-7/12 text-sm text-gray-800 md:text-base">
                      <p className="mb-1 font-bold text-gray-800">
                        {feed.title}
                      </p>
                      <p className="text-xs leading-none text-gray-500 sm:text-sm">
                        {formatDate(feed.publishedAt)}
                      </p>
                    </div>
                  </a>);
        })}
            </div>
          </div>

          <div className="mx-2.5 pt-11">
            <SectionHeader caption="卓球クラブニュース" theme={Theme.Accented}>
              <a className="text-sm text-gray-400 underline" href="https://club.shands.jp/categories/news" target="_blank" rel="noreferrer">
                もっと見る
              </a>
            </SectionHeader>
            <div className="md:flex">
              {shandsClubFeeds.map(function (feed) {
            return (<a key={feed.url} href={feed.url} target="_blank" className="mx-1.5 flex w-full md:block" rel="noreferrer">
                    {feed.thumbnailUrl && (<div className="mb-2 w-5/12 pr-3 md:w-full md:pr-0">
                        <Image url={feed.thumbnailUrl}/>
                      </div>)}
                    <div className="w-7/12 md:w-full">
                      <div className="mb-1 font-bold text-gray-800 md:text-base">
                        <p className="line-clamp-2 text-sm">{feed.title}</p>
                      </div>
                      <p className="text-xs text-gray-500 md:text-sm">
                        {formatDate(feed.publishedAt)}
                      </p>
                    </div>
                  </a>);
        })}
            </div>
          </div>
        </div>
      </div>
    </>);
};
export default Component;
