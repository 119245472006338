import { Modal, ModalBody, ModalHeader, ModalOverlay, ModalContent, } from '@chakra-ui/react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Component = function (_a) {
    var close = _a.close, isOpen = _a.isOpen;
    return (<Modal isCentered isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent width="calc(100% - 20px)" maxWidth="800px" className="py-10">
        <ModalHeader className="text-center text-orange-500">
          メールアドレスの変更確認が失敗しました。確認URLが不正です。
        </ModalHeader>
        <ModalBody>
          <FontAwesomeIcon size="4x" icon={faTriangleExclamation} className="mx-auto block text-orange-500"/>
          <button onClick={close} className="mx-auto mt-6 block py-2 px-4 text-sm text-gray-500 underline">
            閉じる
          </button>
        </ModalBody>
      </ModalContent>
    </Modal>);
};
export default Component;
