import { formatDate } from '@main/app/features/date.helper';
import ArticleListItem from '@main/ui/components/ArticleListItem';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hooks';
export var Component = function (_a) {
    var post = _a.post, ancestorCategories = _a.ancestorCategories, popularArticles = _a.popularArticles;
    var breadcrumbs = [{ caption: 'トップ', link: '/' }];
    ancestorCategories.forEach(function (category) {
        breadcrumbs.push({
            caption: category.name,
            link: "/category/".concat(category.slug),
        });
    });
    breadcrumbs.push({ caption: post.title, link: '' });
    return (<PageLayout caption={post.title} breadcrumbs={breadcrumbs}>
      <div className="flex flex-col gap-x-7 lg:flex-row">
        <div className="lg:basis-[70%]">
          <div className="mt-2 text-right text-xs font-bold text-gray-400">
            {formatDate(post.publishedAt)}
          </div>
          <div className="auth-premium-posts-show__content mt-3 mb-7" dangerouslySetInnerHTML={{ __html: post.content }}/>
        </div>
        <div className="mb-3 lg:basis-[30%]">
          <div className="mt-2 mb-1 flex h-10 items-center bg-gray-100 pl-3 font-bold">
            今週の人気動画
          </div>
          {popularArticles.map(function (article) { return (<ArticleListItem id={article.id} href={"/articles/".concat(article.id)} key={article.id} length={article.length} title={article.title} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl}/>); })}
        </div>
      </div>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
