import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import useHook from './hook';
var Component = function (props) {
    var title = 'お支払い';
    var _a = useHook(props), plan = _a.plan, submit = _a.submit, isSubmitting = _a.isSubmitting, hasAvailableDiscount = _a.hasAvailableDiscount;
    return (<PageLayout caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: title },
        ]}>
      <p className="mb-3 text-base">
        {plan === 'billing_plan_premium' && (<>
            プレミアムサービスの利用料1,078円を1ヶ月ごとに課金させていただきます。
            <br />
            有効期間内に自動更新の停止手続きを行わない限り、サービスは自動的に更新されます。
            {hasAvailableDiscount && (<div className="mt-2">
                <strong className="font-bold">
                  特典により利用料の割引が適用されます。
                </strong>
                詳細は「お支払いに進む」ボタンを押して進んだ画面でご確認いただけます。
              </div>)}
          </>)}

        {plan === 'billing_plan_club_standard' && (<>
            クラブ一般会員の利用料9,000円を毎月月初に課金させていただきます。
            <br />
            有効期間内に自動更新の停止手続きを行わない限り、サービスは自動的に更新されます。
            <br />
            今月分の利用料が直ちに決済され、今月から会員向けサービスのご利用が可能となります。
          </>)}

        {plan === 'billing_plan_club_platinum' && (<>
            クラブプラチナ会員の利用料11,000円を毎月月初に課金させていただきます。
            <br />
            有効期間内に自動更新の停止手続きを行わない限り、サービスは自動的に更新されます。
            <br />
            今月分の利用料が直ちに決済され、今月から会員向けサービスのご利用が可能となります。
          </>)}
      </p>

      <div className="w-56">
        <Button type="button" onClick={submit} caption="お支払いに進む" state={isSubmitting ? 'submitting' : 'enabled'}/>
      </div>
    </PageLayout>);
};
export default Component;
