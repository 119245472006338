import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from '@main/ui/atoms/Image';
import Tip from '@main/ui/atoms/Tip';
import CheckIcon from '@main/ui/components/CheckIcon';
import classnames from 'classnames';
var Component = function (_a) {
    var id = _a.id, href = _a.href, title = _a.title, index = _a.index, length = _a.length, children = _a.children, coachName = _a.coachName, isCompleted = _a.isCompleted, thumbnailUrl = _a.thumbnailUrl, _b = _a.isSelected, isSelected = _b === void 0 ? false : _b, _c = _a.isPublished, isPublished = _c === void 0 ? true : _c;
    return isPublished ? (<a href={href !== null && href !== void 0 ? href : "/articles/".concat(id)} className={classnames('flex border-b border-solid border-gray-200 py-2 first:border-t lg:first:border-t-0 lg:first:pt-0', isSelected
            ? 'cursor-default bg-gray-100'
            : 'hover:bg-gray-100 active:bg-gray-200 ')}>
      <div className="mr-2 w-[47%] max-w-[170px]">
        <div className="relative w-full">
          {typeof isCompleted !== 'undefined' && (<div className="absolute top-2 right-2">
              <CheckIcon isChecked={isCompleted}/>
            </div>)}

          <Image url={thumbnailUrl}/>

          <div className="absolute right-2 bottom-2">
            <Tip>{length}</Tip>
          </div>

          {typeof index !== 'undefined' && (<div className="absolute top-2 left-2">
              <Tip>#{index + 1}</Tip>
            </div>)}
        </div>
      </div>
      <div className="flex-1">
        <div className="mb-1 text-sm font-bold">{title}</div>
        <div className="text-sm">{coachName}</div>
        {children && <div className="mt-[1px]">{children}</div>}
      </div>
    </a>) : (<div className="relative flex h-24 w-full items-center justify-center border-b  border-solid border-gray-200 bg-neutral-200 py-2 first:border-t lg:first:border-t-0 lg:first:pt-0">
      <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="mr-2 text-neutral-500"/>
      この動画は非公開です
      {typeof index !== 'undefined' && (<div className="absolute top-2 left-2">
          <Tip>#{index + 1}</Tip>
        </div>)}
    </div>);
};
export default Component;
