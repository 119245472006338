import axios from 'axios';
export var request = function (_a) {
    var path = _a.path, data = _a.data;
    var metatag = document.querySelector('meta[name="csrf-token"]');
    if (!metatag) {
        throw new Error('metatag does not exist');
    }
    var token = metatag.getAttribute('content');
    return axios.post(path, data, {
        headers: {
            'X-CSRF-Token': token,
        },
    });
};
