import { z } from 'zod';
export var articleSchema = z.object({
    id: z.number(),
    title: z.string(),
    thumbnailUrl: z.string(),
    vimeoId: z.string(),
    publishedAt: z.string(),
    length: z.string(),
    coachName: z.string(),
    coachImageUrl: z.string(),
    coachDescription: z.string(),
});
