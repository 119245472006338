import { faEdit } from '@fortawesome/free-regular-svg-icons';
import IconButton from '@main/ui/components/IconButton';
var Component = function (_a) {
    var onClicked = _a.onClicked, hasMemo = _a.hasMemo;
    return (<IconButton icon={faEdit} caption="メモする" onClicked={onClicked} classNames="text-gray-700 hover:bg-gray-200 active:bg-gray-200">
      {hasMemo && (<div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-orange-500 text-xs font-bold text-white">
          1
        </div>)}
    </IconButton>);
};
export default Component;
