import { Checkbox, Modal, ModalCloseButton, ModalContent, ModalOverlay, Spinner, } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
var Component = function (_a) {
    var isModalOpen = _a.isModalOpen, closeModal = _a.closeModal, isShownTitleForm = _a.isShownTitleForm, setIsShownTitleForm = _a.setIsShownTitleForm, isSubmitting = _a.isSubmitting, control = _a.control, checkboxItems = _a.checkboxItems, submitAddArticleToPlaylist = _a.submitAddArticleToPlaylist, submitRemoveArticleFromPlaylist = _a.submitRemoveArticleFromPlaylist, submitCreatePlaylist = _a.submitCreatePlaylist;
    return (<Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <div className="p-5 text-gray-700">
          <div className="mb-3 px-2 text-base font-bold">保存先...</div>
          <div className="mb-3">
            {checkboxItems.map(function (item, index) { return (<button className="flex w-full items-center rounded-sm p-2 text-left text-sm hover:bg-gray-100 active:bg-gray-200" key={item.playlistId} onClick={checkboxItems[index].isChecked
                ? function () {
                    return submitRemoveArticleFromPlaylist(item.playlistId, item.title, index);
                }
                : function () {
                    return submitAddArticleToPlaylist(item.playlistId, item.title, index);
                }}>
                {!checkboxItems[index].isLoading ? (<Checkbox isChecked={checkboxItems[index].isChecked} isDisabled={isSubmitting} borderColor="orange.500" colorScheme="orange"/>) : (<Spinner color="orange.500" size="sm"/>)}
                <p className="ml-3 font-bold">{item.title}</p>
              </button>); })}
          </div>
          <div>
            {!isShownTitleForm ? (<button onClick={function () { return setIsShownTitleForm(true); }} className="flex w-full cursor-pointer items-center space-x-4 rounded-sm p-2 text-sm font-bold hover:bg-gray-100 active:bg-gray-200">
                <FontAwesomeIcon icon={faPlus}/>
                <p>新しいプレイリストを作成</p>
              </button>) : (<form onSubmit={submitCreatePlaylist} className="flex-col p-2 text-right">
                <p className="text-left text-sm font-bold">名前</p>
                <Controller control={control} name="title" render={function (_a) {
                var field = _a.field, error = _a.fieldState.error;
                return (<>
                      <div className="mb-1 border-b-[1px] border-solid">
                        <input {...field} type="text" placeholder="プレイリストの名前を入力..." className="w-full !border-none !bg-transparent focus:!shadow-none"/>
                      </div>
                      {error && (<div className="mt-1.5 text-xs text-red-500">
                          {error.message}
                        </div>)}
                    </>);
            }}/>
                <button type="submit" disabled={isSubmitting} className={classnames('mt-2 rounded px-4 py-2 text-sm font-bold hover:bg-orange-100 active:bg-orange-200', isSubmitting
                ? 'cursor-default text-gray-300'
                : 'cursor-pointer text-orange-600')}>
                  作成
                </button>
              </form>)}
          </div>
        </div>
      </ModalContent>
    </Modal>);
};
export default Component;
