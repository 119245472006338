import { EventName } from './event-name';
// GA4でのイベントパラメタの長さの上限は100文字
// 収集と設定の上限（ https://support.google.com/analytics/answer/9267744?hl=ja ）
var maxEventParamsLength = 100;
var logEvent = function (_a) {
    var name = _a.name, _b = _a.params, params = _b === void 0 ? {} : _b;
    var stringifiedParams = JSON.stringify(params);
    if (stringifiedParams.length > maxEventParamsLength) {
        throw new Error('[analytics] payload is too long');
    }
    var payload = {
        event: 'custom_event',
        custom_event_name: name,
        custom_event_params: stringifiedParams,
    };
    // eslint-disable-next-line no-console
    console.info(payload);
    dataLayer.push(payload);
};
export var clickHeaderLogo = function () {
    logEvent({ name: EventName.ClickHeaderLogo });
};
export var clickOpenSuggestionBoxButton = function () {
    logEvent({ name: EventName.ClickOpenSuggestionBoxButton });
};
export var clickVisitPlaylistPageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitPlaylistPageButton,
        params: { place: place },
    });
};
export var clickVisitCompletePageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitCompletePageButton,
        params: { place: place },
    });
};
export var clickVisitCommentPageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitCommentPageButton,
        params: { place: place },
    });
};
export var clickVisitHistoryPageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitHistoryPageButton,
        params: { place: place },
    });
};
export var clickVisitUsersPageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitUsersPageButton,
        params: { place: place },
    });
};
export var clickVisitHiraokaPageButton = function () {
    logEvent({ name: EventName.ClickVisitHiraokaPageButton });
};
export var clickVisitChallengePageButton = function () {
    logEvent({ name: EventName.ClickVisitChallengePageButton });
};
export var clickVisitLessonPageButton = function (place) {
    logEvent({ name: EventName.ClickVisitLessonPageButton, params: { place: place } });
};
export var clickVisitFacebookGroupButton = function () {
    logEvent({ name: EventName.ClickVisitFacebookGroupButton });
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export var startLPSignUpFormInput = function (inputName) {
    logEvent({
        name: EventName.StartLPSignUpFormInput,
        params: { inputName: inputName },
    });
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export var clickLPSignUpFormSubmit = function () {
    logEvent({
        name: EventName.ClickLPSignUpFormSubmit,
    });
};
export var clickOpenSideMenuRootCategoryButton = function (slug) {
    logEvent({
        name: EventName.ClickOpenSideMenuRootCategoryButton,
        params: { slug: slug },
    });
};
export var clickVisitCategoryPageLink = function (slug) {
    logEvent({
        name: EventName.ClickVisitCategoryPageLink,
        params: { slug: slug },
    });
};
export var clickOpenSideMenuButton = function () {
    logEvent({ name: EventName.ClickOpenSideMenuButton });
};
export var clickVisitCategoryPageButton = function (categorySlug, place) {
    logEvent({
        name: EventName.ClickVisitCategoryPageButton,
        params: { category_slug: categorySlug, place: place },
    });
};
export var clickVisitArticlePageButton = function (place) {
    logEvent({
        name: EventName.ClickVisitArticlePageButton,
        params: { place: place },
    });
};
