import InlineLink from '@main/ui/atoms/InlineLink';
import Error from '@main/ui/components/Error';
import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import useHook from './hooks';
export var Component = function (_a) {
    var submit = _a.submit, control = _a.control, isSucceeded = _a.isSucceeded, isSubmitting = _a.isSubmitting, errorCode = _a.errorCode;
    return (<PageLayout caption="パスワードの再設定" breadcrumbs={[{ caption: 'トップ' }, { caption: 'パスワード再設定' }]} singleColumn>
      <div className="flex justify-center">
        <div className="mt-3 w-full rounded-lg sm:mt-5 sm:border sm:p-10">
          {!isSubmitting && errorCode && <Error errorCode={errorCode}/>}
          {isSucceeded && (<div className="mb-4 rounded border-2 border-blue-400 bg-blue-200 p-2 text-sm md:text-base">
              <p className="text-blue-900">
                パスワード再設定の案内をお送りしました。
              </p>
            </div>)}
          <form onSubmit={submit}>
            <div className="space-y-5">
              <Controller control={control} name="email" render={function (_a) {
            var field = _a.field, _b = _a.fieldState, isDirty = _b.isDirty, error = _b.error;
            return (<FormInputLayout {...field} label="メールアドレス" type="email" disabled={isSucceeded} placeholder="example@shands.jp" error={error} isDirty={isDirty}/>);
        }}/>
            </div>
            <div className="m-auto mt-8 h-12 w-1/3">
              <FormSubmitButton isSubmitting={isSubmitting} isDisabled={isSubmitting || isSucceeded} text="次へ"/>
            </div>
          </form>

          <div className="mt-10 flex flex-col space-y-2 text-center">
            <InlineLink href="/users/sign_in" text="ログインはこちら"/>
          </div>
        </div>
      </div>
    </PageLayout>);
};
var Container = function () { return <Component {...useHook()}/>; };
export default Container;
