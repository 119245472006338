import { z } from 'zod';
var baseFields = z.object({
    content: z.string().min(1, { message: 'お問い合わせ内容を入力してください' }),
});
var signedInFields = baseFields.extend({
    isSignedIn: z.literal(true),
});
var notSignedInFields = baseFields.extend({
    isSignedIn: z.literal(false),
    name: z.string().min(1, { message: 'お名前を入力してください' }),
    email: z
        .string()
        .email({ message: '正しいメールアドレスを入力してください。' }),
});
export var schema = z.union([signedInFields, notSignedInFields]);
