import dayjs from 'dayjs';
export var formatElapsedDate = function (m) {
    var today = dayjs();
    var diffInMinutes = today.diff(m, 'minutes');
    var diffInHours = today.diff(m, 'hours');
    var diffInDays = today.diff(m, 'days');
    if (diffInMinutes < 1) {
        return '数秒前';
    }
    if (diffInMinutes < 60) {
        return "".concat(diffInMinutes, "\u5206\u524D");
    }
    if (m.isSame(today, 'day')) {
        return "".concat(diffInHours, "\u6642\u9593\u524D");
    }
    if (diffInDays < 30) {
        return "".concat(diffInDays, "\u65E5\u524D");
    }
    if (m.isSame(today, 'year')) {
        return m.format('M/D');
    }
    return m.format('YYYY/M/D');
};
