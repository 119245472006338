import { faEdit, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Component = function (_a) {
    var content = _a.content, isSynced = _a.isSynced, setContent = _a.setContent, onCloseButtonClicked = _a.onCloseButtonClicked;
    return (<div className="fixed bottom-0 z-50 w-full overflow-hidden rounded-t border border-solid border-gray-300 bg-white shadow-md sm:right-[15%] sm:max-w-[400px]">
      <div className="flex justify-between border-b border-solid border-gray-200 p-2">
        <div className="flex items-center">
          <div className="px-2">
            <FontAwesomeIcon icon={faEdit} fixedWidth={true} size="lg" className="text-orange-600"/>
          </div>
          <div className="">
            <div className="mb-0.5 text-sm font-bold text-gray-800">
              メモを残す
            </div>
            <div className="text-xs text-gray-500">
              あなただけが見ることができます。
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <button onClick={onCloseButtonClicked} className="rounded py-1 px-2 active:bg-gray-200">
            閉じる
          </button>
        </div>
      </div>
      <div className="">
        <textarea rows={10} className="block h-[200px] w-full border-none bg-white py-2 px-3 text-base outline-none focus:border-none focus:shadow-none focus:outline-none" placeholder="フォアハンドは反対の腕の動作がコツ" value={content} onChange={function (e) { return setContent(e.target.value); }}></textarea>
      </div>
      <div className="flex h-[36px] items-center border-t border-solid border-gray-200 p-2 text-xs text-green-600">
        {isSynced && (<>
            <FontAwesomeIcon icon={faCheckCircle} fixedWidth={true}/>
            保存済み
          </>)}
      </div>
    </div>);
};
export default Component;
