// prettier-ignore
export var EventName = {
    ClickHeaderLogo: 'click_header_logo',
    ClickOpenSuggestionBoxButton: 'click_open_suggestion_box_button',
    ClickVisitPlaylistPageButton: 'click_visit_playlist_page_button',
    ClickVisitCompletePageButton: 'click_visit_complete_page_button',
    ClickVisitCommentPageButton: 'click_visit_comment_page_button',
    ClickVisitHistoryPageButton: 'click_visit_history_page_button',
    ClickVisitUsersPageButton: 'click_visit_users_page_button',
    ClickVisitHiraokaPageButton: 'click_visit_hiraoka_page_button',
    ClickVisitChallengePageButton: 'click_visit_challenge_page_button',
    ClickVisitLessonPageButton: 'click_visit_lesson_page_button',
    ClickVisitFacebookGroupButton: 'click_visit_facebook_group_button',
    ClickLPConversionButton: 'click_lp_conversion_button',
    ClickLPCheckCastVideosButton: 'click_lp_check_cast_videos_button',
    ClickLPFAQItem: 'click_lp_faq_item',
    StartLPSignUpFormInput: 'start_lp_sign_up_form_input',
    ClickLPSignUpFormSubmit: 'click_lp_sign_up_form_submit',
    ClickLPFooterHelpLink: 'click_lp_footer_help_link',
    ClickLPFooterCompanyDetailsLink: 'click_lp_footer_company_details_link',
    ClickLPFooterPrivacyLink: 'click_lp_footer_privacy_link',
    ClickLPFooterMembersRulesLink: 'click_lp_footer_members_rules_link',
    ClickLPFooterRulesLink: 'click_lp_footer_rules_link',
    ClickOpenSideMenuRootCategoryButton: 'click_open_sidemenu_root_category_button',
    ClickVisitCategoryPageLink: 'click_visit_category_page_link',
    ClickOpenSideMenuButton: 'click_open_sidemenu_button',
    ClickVisitCategoryPageButton: 'click_visit_category_page_button',
    SubmitSignUpForm: 'submit_sign_up_form',
    ClickVisitArticlePageButton: 'click_visit_article_page_button'
};
