var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDisclosure } from '@chakra-ui/react';
var useCustomHook = function (serverProps) {
    var _a = useDisclosure(), isModalOpen = _a.isOpen, showModal = _a.onOpen, closeModal = _a.onClose;
    var onGuideButtonClicked = function () {
        showModal();
    };
    return __assign(__assign({}, serverProps), { isModalOpen: isModalOpen, onGuideButtonClicked: onGuideButtonClicked, onCloseGuideButtonClicked: closeModal });
};
export default useCustomHook;
