var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react';
import { faQuestionCircle, faLightbulb, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '@main/app/features/date.helper';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SideMenu from '@main/ui/components/SideMenu';
import CompleteArticleOperation from '@main/ui/images/CompleteArticleOperation.gif';
import CompletePageExample from '@main/ui/images/CompletePageExample.png';
import TopPageLessonCourseExample from '@main/ui/images/TopPageLessonCourseExample.png';
import useHook from './hooks';
export var Component = function (_a) {
    var items = _a.items, totalPage = _a.totalPage, currentPage = _a.currentPage, isModalOpen = _a.isModalOpen, onGuideButtonClicked = _a.onGuideButtonClicked, onCloseGuideButtonClicked = _a.onCloseGuideButtonClicked, completedArticleCount = _a.completedArticleCount, publishedArticleCount = _a.publishedArticleCount, sideMenuItems = _a.sideMenuItems;
    return (<>
      <PageLayout caption="完了した動画" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '完了した動画' },
        ]} headerContent={<button onClick={onGuideButtonClicked} type="button" className="flex items-center text-gray-500 hover:text-gray-400 active:text-gray-500">
            <FontAwesomeIcon className="mr-1" icon={faQuestionCircle} fixedWidth={true}/>
            <div className="text-sm underline">使い方を見る</div>
          </button>} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
            <SideMenu sideMenuItems={sideMenuItems}/>
          </div>}>
        <div>
          {completedArticleCount > 0 && (<div className="mb-2 text-base text-gray-800">
              <span className="text-lg font-bold">
                {publishedArticleCount}本
              </span>
              中
              <span className="text-lg font-bold">
                {completedArticleCount}本
              </span>
              完了しています。
            </div>)}
        </div>
        <ArticleListLayout totalCount={completedArticleCount} currentPage={currentPage} totalPage={totalPage} notFoundContent={<div className="rounded bg-gray-200 p-5 text-base text-gray-600">
              完了した動画はまだありません。
            </div>}>
          {items.map(function (_a) {
            var article = _a.article, item = __rest(_a, ["article"]);
            return (<ArticleItem key={article.id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={"".concat(formatDate(item.completedAt), "\u306B\u5B8C\u4E86")}/>);
        })}
        </ArticleListLayout>
      </PageLayout>

      <Modal isOpen={isModalOpen} onClose={onCloseGuideButtonClicked}>
        <ModalOverlay />
        <ModalContent width="calc(100% - 20px)" maxWidth="800px">
          <ModalHeader>完了機能の使い方</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mb-1 text-base font-bold text-gray-700">
              <FontAwesomeIcon className="mr-1" icon={faLightbulb} fixedWidth={true}/>
              動画のページで「完了にする」ボタンをクリックすることで動画を完了状態にできます。
            </div>

            <div className="pl-6">
              <img alt="" loading="lazy" src={CompleteArticleOperation} className="mb-5 block w-full border border-solid border-gray-300 bg-gray-200"/>
            </div>

            <div className="mb-1 text-base font-bold text-gray-700">
              <FontAwesomeIcon className="mr-1" icon={faLightbulb} fixedWidth={true}/>
              完了した動画は本ページに一覧表示されます。
            </div>

            <div className="pl-6">
              <img alt="" loading="lazy" src={CompletePageExample} className="mb-5 block w-full border border-solid border-gray-300 bg-gray-200"/>
            </div>

            <div className="mb-1 text-base font-bold text-gray-700">
              <FontAwesomeIcon className="mr-1" icon={faLightbulb} fixedWidth={true}/>
              完了した動画が進行中のレッスンコースに含まれている場合、トップページにて進捗が表示されます。
            </div>

            <div className="pl-6">
              <img alt="" loading="lazy" src={TopPageLessonCourseExample} className="mb-5 block w-full border border-solid border-gray-300 bg-gray-200"/>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
