var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { normalize, } from '@main/app/adapters/user-feature-challenge';
var useCustomHook = function (serverProps) {
    var items = normalize(serverProps.userFeatureChallengeListProps);
    var totalCount = items.length;
    return __assign(__assign({}, serverProps), { items: items, totalCount: totalCount });
};
export default useCustomHook;
