var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useArticleMemo, useArticleComplete, } from '@main/app/features/article/article.hook';
import { usePlaylistSave } from '@main/app/features/playlist/playlist.hook';
import { useState, useRef, useCallback, useMemo } from 'react';
import { logEvent } from '@/utils/analytics';
import { propsSchema } from './props';
var useHook = function (props) {
    var _a, _b;
    var _c = propsSchema.parse(props), article = _c.article, shandsPost = _c.shandsPost, ownedPlaylists = _c.ownedPlaylists, restProps = __rest(_c, ["article", "shandsPost", "ownedPlaylists"]);
    var plDrawerTopRef = useRef(null);
    var isMobile = useBreakpointValue({ base: true, lg: false });
    var _d = useState((_b = (_a = restProps === null || restProps === void 0 ? void 0 : restProps.playlist) === null || _a === void 0 ? void 0 : _a.articles) !== null && _b !== void 0 ? _b : []), sortableItems = _d[0], setSortableItems = _d[1];
    var _e = useArticleComplete({
        id: article.id,
        initialIsCompleted: restProps.isCompleted,
    }), complete = _e.complete, isCompleted = _e.isCompleted, revertComplete = _e.revertComplete, isCompleteUpdating = _e.isUpdating;
    var _f = useArticleMemo({
        id: article.id,
        initialContent: restProps.memo,
    }), memo = _f.content, setMemo = _f.setContent, isMemoSynced = _f.isSynced, debouncedMemo = _f.debouncedContent;
    var _g = useState(false), isMemoShowing = _g[0], setIsMemoShowing = _g[1];
    var hasMemo = useMemo(function () { return debouncedMemo.trim() !== ''; }, [debouncedMemo]);
    var canShare = useMemo(function () { return shandsPost !== null; }, [shandsPost]);
    var _h = useDisclosure(), showModal = _h.onOpen, isModalOpen = _h.isOpen, closeModal = _h.onClose;
    var _j = useDisclosure(), showPlaylistsModal = _j.onOpen, isPlaylistsModalOpen = _j.isOpen, closePlaylistsModal = _j.onClose;
    var _k = useDisclosure({ defaultIsOpen: true }), showPlaylistDrawer = _k.onOpen, isPlaylistDrawerOpen = _k.isOpen, closePlaylistDrawer = _k.onClose;
    var usePlaylistSaveProps = usePlaylistSave({
        ownedPlaylists: ownedPlaylists,
        articleId: article.id.toString(),
        closeModal: closePlaylistsModal,
    });
    var playlistSaveModalProps = {
        showPlaylistsModal: showPlaylistsModal,
        closePlaylistsModal: closePlaylistsModal,
        isPlaylistsModalOpen: isPlaylistsModalOpen,
        usePlaylistSaveProps: usePlaylistSaveProps,
    };
    var onMemoClicked = useCallback(function () { return setIsMemoShowing(function (value) { return !value; }); }, []);
    var onCompleteClicked = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isCompleted) return [3 /*break*/, 2];
                    return [4 /*yield*/, revertComplete()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, complete()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [complete, isCompleted, revertComplete]);
    var openShareWindow = useCallback(function (windowId, url) {
        var width = 640;
        var height = 540;
        // 画面の中央に配置するための計算
        var left = (window.innerWidth - width) / 2 + window.screenX;
        var top = (window.innerHeight - height) / 2 + window.screenY;
        window.open(url, windowId, [
            'resizable',
            'scrollbars',
            "top=".concat(top),
            "left=".concat(left),
            "width=".concat(width),
            "height=".concat(height),
        ].join(','));
    }, []);
    var shareToTwitter = useCallback(function () {
        var _a;
        if (!shandsPost) {
            return;
        }
        var url = new URL('https://twitter.com/share');
        url.searchParams.append('via', 'Shakehands_Inc');
        url.searchParams.append('text', [(_a = shandsPost.title) !== null && _a !== void 0 ? _a : ''].join('\n'));
        url.searchParams.append('url', "https://www.shands.jp/archives/".concat(shandsPost.id));
        ['卓球', '卓球動画', 'シェークハンズ'].forEach(function (tag) {
            url.searchParams.append('hashtags', tag);
        });
        openShareWindow('twitter', url.toString());
        logEvent({
            eventName: 'click_sns_share_button',
            params: { medium: 'twitter', articleId: article.id },
        });
    }, [article, shandsPost, openShareWindow]);
    var shareToFacebook = useCallback(function () {
        if (!shandsPost) {
            return;
        }
        var url = new URL('https://www.facebook.com/sharer/sharer.php');
        url.searchParams.append('u', "https://www.shands.jp/archives/".concat(shandsPost.id));
        openShareWindow('facebook', url.toString());
        logEvent({
            eventName: 'click_sns_share_button',
            params: { medium: 'facebook', articleId: article.id },
        });
    }, [article, shandsPost, openShareWindow]);
    return __assign(__assign(__assign({}, restProps), playlistSaveModalProps), { memo: memo, article: article, hasMemo: hasMemo, setMemo: setMemo, isMobile: isMobile, canShare: canShare, closeModal: closeModal, isCompleted: isCompleted, isModalOpen: isModalOpen, isMemoSynced: isMemoSynced, isMemoShowing: isMemoShowing, onMemoClicked: onMemoClicked, sortableItems: sortableItems, plDrawerTopRef: plDrawerTopRef, shareToTwitter: shareToTwitter, shareToFacebook: shareToFacebook, setIsMemoShowing: setIsMemoShowing, setSortableItems: setSortableItems, onCompleteClicked: onCompleteClicked, showPlaylistDrawer: showPlaylistDrawer, isCompleteUpdating: isCompleteUpdating, closePlaylistDrawer: closePlaylistDrawer, isPlaylistDrawerOpen: isPlaylistDrawerOpen, onShowLessonCourseClicked: showModal });
};
export default useHook;
