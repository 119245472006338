import Button from '@main/ui/components/Button';
import useHook from './hook';
import backgroundImage from './images/background.png';
var Component = function () {
    var _a = useHook(), submit = _a.submit, isSubmitting = _a.isSubmitting;
    return (<div className="relative aspect-video w-full overflow-hidden bg-gray-300">
      <img alt="" src={backgroundImage} className="absolute inset-0 h-full w-full object-cover"/>

      <div className="absolute inset-0 flex h-full w-full items-center justify-center p-4">
        <div>
          <div className="mb-4 text-center text-base font-bold text-white sm:text-xl">
            無料体験で視聴できる動画数を超えました。
          </div>

          <div className="mb-6 text-center text-base font-bold text-white sm:text-xl">
            有料会員にアップグレードすることで、今すぐこちらの動画をご覧いただけます。
          </div>

          <div className="flex justify-center">
            <Button onClick={submit} theme="secondary" caption="今すぐアップグレードする" state={isSubmitting ? 'submitting' : 'enabled'}/>
          </div>
        </div>
      </div>
    </div>);
};
export default Component;
