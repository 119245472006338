var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './ui/global.css';
import Footer from '@main/ui/components/Footer';
import Header from '@main/ui/components/Header';
import IssueToKeyword from '@main/ui/containers/IssueToKeyword';
import LessonCourseChallengePanel from '@main/ui/containers/LessonCourseChallengePanel';
import SuggestionBoxContainer from '@main/ui/containers/SuggestionBoxContainer';
import { bootstrap } from '@/utils/bootstrap-react';
import * as pages from './ui/pages';
// 未整理のコンポーネント
var components = __assign({ Header: Header, Footer: Footer, IssueToKeyword: IssueToKeyword, SuggestionBoxContainer: SuggestionBoxContainer, LessonCourseChallengePanel: LessonCourseChallengePanel }, pages);
bootstrap(components);
