import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '@main/app/features/date.helper';
import ArticleListItem from '@main/ui/components/ArticleListItem';
import LessonCourseChallengePanel from '@main/ui/containers/LessonCourseChallengePanel';
var Component = function (_a) {
    var title = _a.title, items = _a.items, description = _a.description, thumbnailUrl = _a.thumbnailUrl, isChallenging = _a.isChallenging, lessonCourseId = _a.lessonCourseId, articleIdToCompletedAt = _a.articleIdToCompletedAt, showingArticle = _a.article;
    var getCompletedAt = function (articleId) {
        if (!(articleId in articleIdToCompletedAt)) {
            return <></>;
        }
        var completedAt = articleIdToCompletedAt[articleId];
        return (<div className="flex items-center text-sm font-bold text-orange-500">
        {formatDate(completedAt)}に完了
        <FontAwesomeIcon icon={faCheckCircle} fixedWidth={true} className="ml-0.5"/>
      </div>);
    };
    return (<div>
      <div className="mb-6 rounded border border-solid border-gray-200 p-4">
        <div className="mb-2 flex">
          <div className="mr-2 aspect-[16/9] w-[150px] overflow-hidden rounded border border-solid border-gray-300 bg-gray-200">
            <img alt="" loading="lazy" src={thumbnailUrl} className="h-full w-full object-cover"/>
          </div>
          <div className="flex-1">
            <div className="mb-1 text-base font-bold leading-tight text-gray-900">
              {title}
            </div>
            <div className="text-sm text-gray-500">全{items.length}動画</div>
          </div>
        </div>
        <div className="mb-2 text-base text-gray-900">{description}</div>

        <div>
          <LessonCourseChallengePanel startedAt={null} isChallenging={isChallenging} featureId={lessonCourseId}/>
        </div>
      </div>
      <div className="-my-2">
        {items.map(function (_a, index) {
            var article = _a.article, isCompleted = _a.isCompleted;
            return (<ArticleListItem id={article.id} href={"/articles/".concat(article.id, "?lesson_course=").concat(lessonCourseId)} key={article.id} index={index} length={article.length} title={article.title} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl} isSelected={article.id === showingArticle.id} isCompleted={isCompleted}>
            {getCompletedAt(article.id)}
          </ArticleListItem>);
        })}
      </div>
    </div>);
};
export default Component;
