var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import SpinnerButton from '@main/ui/components/SpinnerButton';
import classnames from 'classnames';
import { forwardRef, useMemo } from 'react';
var Component = forwardRef(function (_a, ref) {
    var caption = _a.caption, _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.theme, theme = _c === void 0 ? 'primary' : _c, _d = _a.state, state = _d === void 0 ? 'enabled' : _d, props = __rest(_a, ["caption", "type", "theme", "state"]);
    // state × theme に対応するクラス名を生成する
    var overrideProps = useMemo(function () {
        var classes = classnames('rounded px-6 py-3 text-center text-sm font-bold shadow-sm');
        switch (theme) {
            case 'primary':
                return {
                    spinnerColorClass: classnames('text-white'),
                    className: classnames(classes, 'text-white', {
                        // 背景色
                        'bg-gray-400': state === 'disabled',
                        'bg-[#060030]': state === 'enabled' || state === 'submitting',
                        'hover:opacity-80 active:opacity-100': state === 'enabled',
                    }),
                };
            case 'secondary':
                return {
                    spinnerColorClass: classnames('text-white'),
                    className: classnames(classes, 'text-white', {
                        // 背景色
                        'bg-gray-400': state === 'disabled',
                        'bg-orange-400': state === 'enabled' || state === 'submitting',
                        'hover:opacity-80 active:opacity-100': state === 'enabled',
                    }),
                };
            case 'danger':
                return {
                    spinnerColorClass: classnames('text-white'),
                    className: classnames(classes, 'text-white', {
                        // 背景色
                        'bg-gray-400': state === 'disabled',
                        'bg-red-600': state === 'enabled' || state === 'submitting',
                        'hover:bg-red-500 active:bg-red-700': state === 'enabled',
                    }),
                };
            case 'default':
                return {
                    spinnerColorClass: classnames('text-gray-700'),
                    className: classnames(classes, 'text-gray-700', {
                        // 背景色
                        'bg-gray-400': state === 'disabled',
                        'bg-white': state === 'enabled' || state === 'submitting',
                        'hover:bg-gray-50 active:bg-gray-200': state === 'enabled',
                        // ring
                        'ring-1 ring-inset ring-gray-400': state === 'enabled' || state === 'submitting',
                        // 文字色
                        'text-white': state === 'disabled',
                        'text-gray-700': state === 'enabled' || state === 'submitting',
                    }),
                };
        }
    }, [theme, state]);
    return (<SpinnerButton {...props} {...overrideProps} ref={ref} type={type} state={state}>
        {caption}
      </SpinnerButton>);
});
export default Component;
