var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { formatDate } from '@main/app/features/date.helper';
import PageLayout from '@main/ui/components/PageLayout';
import PlaylistItem from '@main/ui/components/PlaylistItem';
import PlaylistListLayout from '@main/ui/components/PlaylistListLayout';
import SideMenu from '@main/ui/components/SideMenu';
import useHook from './hooks';
export var Component = function (_a) {
    var items = _a.items, sideMenuItems = _a.sideMenuItems;
    return (<PageLayout caption="プレイリスト" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: 'プレイリスト' },
        ]} sidebarContent={<div className="mt-2 hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
          <SideMenu sideMenuItems={sideMenuItems}/>
        </div>}>
      <PlaylistListLayout totalCount={items.length} notFoundContent={<div className="rounded bg-gray-200 p-5 text-base text-gray-600">
            作成したプレイリストはまだありません。
          </div>}>
        {items.map(function (_a) {
            var playlist = _a.playlist, item = __rest(_a, ["playlist"]);
            return (<PlaylistItem key={playlist.id} id={playlist.id} title={playlist.title} thumbnailArticleId={item.thumbnailArticleId} articlesCount={item.articlesCount} thumbnailUrl={item.thumbnailUrl} comment={"".concat(formatDate(playlist.updatedAt), "\u306B\u66F4\u65B0")}/>);
        })}
      </PlaylistListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
