import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Component = function (_a) {
    var href = _a.href, icon = _a.icon, caption = _a.caption;
    return (<a href={href} style={{
            background: 'linear-gradient(180deg, #ff4b19 0%, #ff653a 92.71%, #f74b1c 100%)',
        }} className="flex w-full items-center justify-center rounded py-3 px-4 text-lg font-bold text-white hover:opacity-80 active:opacity-90">
      <FontAwesomeIcon fixedWidth size="lg" className="mr-2" icon={icon}/>

      {caption}
    </a>);
};
export default Component;
