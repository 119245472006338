import classnames from 'classnames';
var Component = function (_a) {
    var width = _a.width, colorClass = _a.colorClass;
    var height = width * 1.4142;
    colorClass = colorClass || 'bg-gray-500';
    return (<div className="mx-1" style={{ transform: 'translateX(25%)' }}>
      <div className="inline-block overflow-hidden" style={{ width: "".concat(width, "px") }}>
        <div className={classnames([
            colorClass,
            'rotate-45',
            '',
            'origin-top-left',
        ])} style={{ height: "".concat(height, "px") }}></div>
      </div>
    </div>);
};
export default Component;
