var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { from, ApolloLink, ApolloClient, InMemoryCache, createHttpLink, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { captureException } from '@sentry/react';
import { getCsrfToken } from '@/utils/csrf';
var httpLink = createHttpLink({ uri: '/graphql-internal' });
// GraphQLエラー発生時にSentryに通知する
// eslint-disable-next-line @typescript-eslint/naming-convention
var errorLink = onError(function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    if (networkError) {
        captureException(new Error("NetworkError: ".concat(JSON.stringify(networkError))));
    }
    if (graphQLErrors) {
        captureException(new Error("GraphQLError: ".concat(JSON.stringify(graphQLErrors))));
    }
});
// Railsが生成するCSRFトークンをリクエストヘッダーに含める
var csrfTokenLink = new ApolloLink(function (operation, forward) {
    operation.setContext(function (_a) {
        var _b = _a.headers, headers = _b === void 0 ? {} : _b;
        var token = getCsrfToken();
        return {
            headers: __assign(__assign({}, headers), { 'X-CSRF-Token': token !== null && token !== void 0 ? token : undefined }),
        };
    });
    return forward(operation);
});
export var apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([csrfTokenLink, errorLink, httpLink]),
});
