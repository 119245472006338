var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { chargeMenuLists, noChargeMenuLists, } from '@main/app/features/header/menu-list';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useHeaderResendSignUpConfirmationEmailMutation } from '@/types/graphql';
import { propsSchema } from './props';
var useHook = function (props) {
    var _a = propsSchema.parse(props), initialSearchKeyword = _a.initialSearchKeyword, isServiceMenuAvailable = _a.isServiceMenuAvailable, restProps = __rest(_a, ["initialSearchKeyword", "isServiceMenuAvailable"]);
    var toast = useToast();
    var headerRef = useRef(null);
    var mutateResendSignUpConfirmationEmail = useHeaderResendSignUpConfirmationEmailMutation()[0];
    // レイアウトシフトを最小限に抑えるため、適当な初期値を設定する
    var _b = useState('56px'), headerHeight = _b[0], setHeaderHeight = _b[1];
    var _c = useDisclosure(), isOpen = _c.isOpen, onOpen = _c.onOpen, onClose = _c.onClose;
    var _d = useState(initialSearchKeyword), searchKeyword = _d[0], setSearchKeyword = _d[1];
    var headerMenuLists = useMemo(function () { return (isServiceMenuAvailable ? chargeMenuLists : noChargeMenuLists); }, [isServiceMenuAvailable]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var isPwa = window.navigator.standalone;
    var canBack = window.history.length > 1;
    var historyBack = function () { return window.history.back(); };
    var resendConfirmationEmail = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = window.confirm('メールアドレスの開通確認メールを再送しますか？');
                    if (!result) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, mutateResendSignUpConfirmationEmail({
                            onCompleted: function () {
                                toast({
                                    duration: 3000,
                                    status: 'success',
                                    isClosable: true,
                                    position: 'bottom-left',
                                    title: 'メールを再送しました',
                                });
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        var resizedHandler = function () {
            if (!headerRef.current) {
                return;
            }
            var height = getComputedStyle(headerRef.current).height;
            setHeaderHeight(height);
        };
        resizedHandler();
        window.addEventListener('resize', resizedHandler);
        return function () { return window.removeEventListener('resize', resizedHandler); };
    }, []);
    return __assign({ isPwa: isPwa, isOpen: isOpen, onOpen: onOpen, onClose: onClose, canBack: canBack, headerRef: headerRef, historyBack: historyBack, headerHeight: headerHeight, searchKeyword: searchKeyword, headerMenuLists: headerMenuLists, setSearchKeyword: setSearchKeyword, isServiceMenuAvailable: isServiceMenuAvailable, resendConfirmationEmail: resendConfirmationEmail }, restProps);
};
export default useHook;
