import Paginator from '@main/ui/molecules/Paginator';
var Component = function (_a) {
    var children = _a.children, totalPage = _a.totalPage, totalCount = _a.totalCount, currentPage = _a.currentPage, notFoundContent = _a.notFoundContent;
    if (totalCount === 0) {
        return <>{notFoundContent}</>;
    }
    return (<>
      <div className="mb-8 grid grid-cols-2 gap-x-4 gap-y-10 sm:mb-16 md:grid-cols-4">
        {children}
      </div>
      <Paginator currentPage={currentPage} totalPage={totalPage}/>
    </>);
};
export default Component;
