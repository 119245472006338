var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import CompleteModal from './components/CompleteModal';
import SurveyHeader from './components/SurveyHeader';
import SurveyRadioGroup from './components/SurveyRadioGroup';
import useHook from './hook';
export var Component = function (props) {
    var _a = useHook(props), submit = _a.submit, control = _a.control, isSubmitting = _a.isSubmitting, genderOptions = _a.genderOptions, careerOptions = _a.careerOptions, howToKnowOptions = _a.howToKnowOptions, playStyleOptions = _a.playStyleOptions, playerTypeOptions = _a.playerTypeOptions, closeCompleteModal = _a.closeCompleteModal, expectationsOptions = _a.expectationsOptions, isCompleteModalOpen = _a.isCompleteModalOpen, playFrequencyOptions = _a.playFrequencyOptions;
    return (<>
      <PageLayout singleColumn caption="アンケート" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '設定', link: '/user' },
            { caption: 'アンケート', link: '/attribute/edit' },
        ]}>
        <div className="mb-7 text-base leading-relaxed text-zinc-700">
          よろしければあなたの卓球への関わり方をシェークハンズ運営まで教えていただけませんか。
          <br />
          ご入力いただいた情報はコンテンツの改善やおすすめ動画のご提案に利用させていただきます。
          ご入力いただいた情報はいつでも修正していただくことができます。
        </div>

        <form noValidate onSubmit={submit} className="grid gap-y-4">
          <div>
            <SurveyHeader title="生まれ年（西暦）"/>
            <Controller control={control} name="birthYear" render={function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (<>
                  <div className="mt-2 flex h-[45px] w-full rounded border border-solid border-gray-300 md:max-w-[149px]">
                    <input {...field} type="number" placeholder="（例）1990" 
            // フォームが縦に長いため、Enterで送信されると送信中かの状態がわかりにくい
            // そのため、Enterで送信されないようにする
            onKeyDown={function (event) {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }} className="w-full appearance-none bg-transparent pl-4 focus:outline-none focus-visible:outline-none"/>
                  </div>

                  {error && (<div className="mt-1.5 text-xs text-red-500">
                      {error.message}
                    </div>)}
                </>);
        }}/>
          </div>

          <div>
            <SurveyHeader title="性別"/>
            <SurveyRadioGroup name="gender" control={control} options={genderOptions}/>
          </div>

          <div>
            <SurveyHeader title="種別"/>
            <SurveyRadioGroup vertical name="playerType" control={control} options={playerTypeOptions}/>
          </div>

          <div>
            <SurveyHeader title="シェークハンズサービスに期待すること"/>
            <Controller control={control} name="expectations" render={function (_a) {
            var _b = _a.field, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div className="mt-2 flex flex-col gap-2">
                  {expectationsOptions.map(function (_a) {
                    var id = _a.id, name = _a.name;
                    var isChecked = value.includes(id);
                    return (<button key={id} type="button" onClick={function () {
                            if (isChecked) {
                                onChange(value.filter(function (v) { return v !== id; }));
                            }
                            else {
                                onChange(__spreadArray(__spreadArray([], value, true), [id], false));
                            }
                        }} className={classnames('flex h-12 w-full cursor-pointer items-center rounded border-solid px-4 ring-inset', isChecked
                            ? 'ring-2 ring-blue-900'
                            : 'ring-1 ring-gray-300')}>
                        <FontAwesomeIcon fixedWidth icon={isChecked ? faCheckSquare : faSquare} className={classnames('text-lg', isChecked ? 'text-blue-900' : 'text-gray-300')}/>
                        <div className="ml-2 flex h-full w-full items-center">
                          {name}
                        </div>
                      </button>);
                })}

                  {error && (<div className="mt-1.5 text-xs text-red-500">
                      {error.message}
                    </div>)}
                </div>);
        }}/>
          </div>

          <div>
            <SurveyHeader title="卓球歴"/>
            <SurveyRadioGroup vertical name="career" control={control} options={careerOptions}/>
          </div>

          <div>
            <SurveyHeader title="プレイ頻度"/>
            <SurveyRadioGroup vertical control={control} name="playFrequency" options={playFrequencyOptions}/>
          </div>

          <div>
            <SurveyHeader title="知ったきっかけ"/>
            <SurveyRadioGroup vertical name="howToKnow" control={control} options={howToKnowOptions}/>
          </div>

          <div>
            <SurveyHeader title="戦型"/>
            <SurveyRadioGroup vertical name="playStyle" control={control} options={playStyleOptions}/>
          </div>

          <div className="flex justify-center">
            <div className="h-12 w-full md:mt-7 md:w-56">
              <FormSubmitButton text="保存する" isDisabled={isSubmitting} isSubmitting={isSubmitting}/>
            </div>
          </div>
        </form>
      </PageLayout>

      <CompleteModal close={closeCompleteModal} isOpen={isCompleteModalOpen}/>
    </>);
};
export default Component;
