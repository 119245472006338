var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var baseClassNames = 'block py-3 px-6 text-sm font-bold text-white bg-[#060030] rounded hover:opacity-80 active:opacity-100';
var Link = function (_a) {
    var caption = _a.caption, rest = __rest(_a, ["caption"]);
    return (<a className={baseClassNames} {...rest}>
      {caption}
    </a>);
};
var Button = function (_a) {
    var caption = _a.caption, rest = __rest(_a, ["caption"]);
    return (<button className={baseClassNames} {...rest}>
      {caption}
    </button>);
};
export { Link, Button };
