var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Component = function (_a) {
    var icon = _a.icon, caption = _a.caption, buttonProps = __rest(_a, ["icon", "caption"]);
    return (<button {...buttonProps} className="h-12 w-12 rounded-full leading-none active:bg-gray-200">
    <FontAwesomeIcon fixedWidth icon={icon} className="text-xl text-gray-500"/>
    {caption && (<div className="mt-1 scale-90 text-xs leading-none">{caption}</div>)}
  </button>);
};
export default Component;
