var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var createPlaylistQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation createPlaylist($input: CreatePlaylistInput!) {\n    createPlaylist(input: $input) {\n      clientMutationId\n      playlist {\n        id\n        title\n      }\n    }\n  }\n"], ["\n  mutation createPlaylist($input: CreatePlaylistInput!) {\n    createPlaylist(input: $input) {\n      clientMutationId\n      playlist {\n        id\n        title\n      }\n    }\n  }\n"])));
export var addArticleToPlaylistQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n    addArticleToPlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"], ["\n  mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n    addArticleToPlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"])));
export var removeArticleFromPlaylistQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n    removeArticleFromPlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"], ["\n  mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n    removeArticleFromPlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"])));
export var updatePlaylistQuery = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation updatePlaylist($input: UpdatePlaylistInput!) {\n    updatePlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"], ["\n  mutation updatePlaylist($input: UpdatePlaylistInput!) {\n    updatePlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"])));
export var deletePlaylistQuery = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation deletePlaylist($input: DeletePlaylistInput!) {\n    deletePlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"], ["\n  mutation deletePlaylist($input: DeletePlaylistInput!) {\n    deletePlaylist(input: $input) {\n      clientMutationId\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
