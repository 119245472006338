var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Triangle from '@main/ui/atoms/Triangle';
import classnames from 'classnames';
var Component = function (_a) {
    var items = _a.items;
    var elementCount = items.length * 2 - 1;
    var elements = new Array(elementCount).fill(0);
    return (<div className="-mx-1 flex flex-wrap items-center py-2">
      {elements.map(function (_, index) {
            var itemIndex = Math.floor(index / 2);
            var item = items[itemIndex];
            if (index % 2 === 0) {
                return (<a href={item.link} key={item.caption} className={classnames(__spreadArray([
                        'block',
                        'mx-1',
                        'text-gray-700',
                        'no-underline'
                    ], (itemIndex < items.length - 1 ? ['hover:underline'] : []), true))}>
              {item.caption}
            </a>);
            }
            else {
                return (<Triangle key={"".concat(item.caption, "-divider")} width={8} colorClass="bg-gray-600"/>);
            }
        })}
    </div>);
};
export default Component;
