import InlineLink from '@main/ui/atoms/InlineLink';
import PageLayout from '@main/ui/components/PageLayout';
import availableCreditCards from './images/available_credit_cards.png';
var InfoTableRow = function (_a) {
    var labelText = _a.labelText, children = _a.children;
    return (<div className="flex w-full divide-x divide-zinc-300">
      <div className="flex basis-1/6 items-center justify-center bg-zinc-100 p-2 text-sm">
        {labelText}
      </div>
      <div className="flex basis-5/6 items-center p-2 pl-3 text-sm">
        {children}
      </div>
    </div>);
};
var Page = function () {
    return (<PageLayout caption="特定商取引法に基づく表示" breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '特定商取引法に基づく表示', link: '/tokusyo' },
        ]}>
      <div className="w-full">
        <div className="mt-5 flex w-full flex-col divide-y divide-zinc-300 border border-solid border-zinc-300">
          <InfoTableRow labelText="事業者名">
            株式会社シェークハンズ
          </InfoTableRow>
          <InfoTableRow labelText="所在地">
            東京都千代田区神田猿楽町2-7-17 織本ビル301
          </InfoTableRow>
          <InfoTableRow labelText="電話番号">03-5577-6174</InfoTableRow>
          <InfoTableRow labelText="責任者">小谷 彰彦</InfoTableRow>
          <InfoTableRow labelText="お問い合わせ">
            <InlineLink target="_blank" href="/contact/new" text="こちらよりお問い合わせ下さい"/>
          </InfoTableRow>
          <InfoTableRow labelText="利用料">
            当サイト上で表示している有料会員サービス利用額
          </InfoTableRow>
          <InfoTableRow labelText="決済">
            <div className="flex flex-col">
              <div>以下のクレジット決済が利用できます。</div>
              <div className="my-2 w-full border border-solid border-zinc-200 md:w-[600px]">
                <img src={availableCreditCards} alt="利用可能なクレジットカードブランド" className="block h-full w-full object-cover"/>
              </div>
              <div>
                &#8251;
                有料サービスによっては一部、銀行振込のみによる決済を行う場合がございます。各サービスの決済方法選択画面をご参照ください。
              </div>
            </div>
          </InfoTableRow>
          <InfoTableRow labelText="サービス開始時期">
            有料会員サービスについては有料登録後すぐに、その他の有料会員サービスについては当社所定の購入手続完了後すぐにご利用可能です。
          </InfoTableRow>
        </div>
      </div>
    </PageLayout>);
};
export default Page;
