import useHook from './hook';
var Component = function () {
    var _a = useHook(), showModal = _a.showModal, ModalComponent = _a.ModalComponent;
    return (<>
      <div>
        <button className="fixed right-[100px] bottom-5 h-16 w-16 rounded-full bg-blue-400 shadow-md shadow-neutral-500 outline-none hover:opacity-90 active:bg-blue-500 active:opacity-100 md:bottom-9 md:right-36" type="button" onClick={function () {
            showModal({});
        }}>
          <div className="text-2xl font-bold leading-none text-white">AI</div>
        </button>
      </div>

      <ModalComponent />
    </>);
};
export default Component;
