import { faCheckCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '@main/app/features/date.helper';
import ArticleItem from '@main/ui/components/ArticleItem';
import ArticleListLayout from '@main/ui/components/ArticleListLayout';
import PageLayout from '@main/ui/components/PageLayout';
import SearchResult from '@main/ui/components/SearchResult';
import CategoriesTable from '@main/ui/pages/AuthPremiumCategoriesShow/components/CategoriesTable';
import useHook from './hooks';
export var Component = function (_a) {
    var items = _a.items, totalCount = _a.totalCount, currentPage = _a.currentPage, totalPage = _a.totalPage, categoryName = _a.categoryName, childCategories = _a.childCategories, breadcrumbs = _a.breadcrumbs, articleCountMap = _a.articleCountMap, parentCategory = _a.parentCategory;
    var sidebarContent = (parentCategory || childCategories.length > 0) && (<div className="mt-2">
      {parentCategory && (<a key={parentCategory.id} href={"/categories/".concat(parentCategory.slug)} className="mb-3 flex items-center rounded-lg bg-gray-100 py-3 px-4 active:bg-gray-200">
          <FontAwesomeIcon fixedWidth icon={faArrowLeft} className="mr-2 text-xl text-gray-500"/>
          <div className="text-base">{parentCategory.name}に戻る</div>
        </a>)}
      {childCategories.length > 0 && (<div className="rounded-lg bg-gray-100 p-4">
          <div className="mb-3 flex items-center">
            <FontAwesomeIcon fixedWidth icon={faCheckCircle} className="mr-1 text-lg text-orange-500"/>
            <div className="text-base font-bold">動画をさらに絞り込む</div>
          </div>

          <div>
            {childCategories.map(function (category) { return (<a key={category.slug} href={category.slug} className="flex items-center justify-between rounded-lg p-2 active:bg-gray-200">
                <div className="mr-2 text-base">{category.name}</div>
                {category.id in articleCountMap && (<div className="whitespace-nowrap text-xs text-gray-600">
                    {articleCountMap[category.id]}本
                  </div>)}
              </a>); })}
          </div>
        </div>)}
    </div>);
    return (<PageLayout caption={"".concat(categoryName, "\u306E\u52D5\u753B\u4E00\u89A7")} breadcrumbs={breadcrumbs} headerContent={totalCount > 0 && (<SearchResult totalCount={totalCount} currentPage={currentPage} totalPage={totalPage}/>)} sidebarContent={sidebarContent}>
      {childCategories.length > 0 && (<div className="mb-7 lg:hidden">
          <div className="mb-3 flex items-center font-bold">
            <div className="mr-1 text-sm">{categoryName}をさらに絞り込む</div>
            <FontAwesomeIcon fixedWidth icon={faCheckCircle} className="text-orange-500"/>
          </div>
          <CategoriesTable categories={childCategories} articleCountMap={articleCountMap}/>
        </div>)}

      <ArticleListLayout totalCount={totalCount} currentPage={currentPage} totalPage={totalPage} notFoundContent={<div className="rounded bg-gray-200 p-5 text-base text-gray-600">
            {categoryName}の動画はまだありません。
          </div>}>
        {items.map(function (_a) {
            var article = _a.article, isCompleted = _a.isCompleted;
            return (<ArticleItem key={article.id} id={article.id} title={article.title} thumbnailUrl={article.thumbnailUrl} movieLength={article.length} coachName={article.coachName} coachImageUrl={article.coachImageUrl} comment={"".concat(formatDate(article.publishedAt))} isCompleted={isCompleted}/>);
        })}
      </ArticleListLayout>
    </PageLayout>);
};
var Container = function (props) { return (<Component {...useHook(props)}/>); };
export default Container;
