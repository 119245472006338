import Image from '@main/ui/atoms/Image';
import SectionHeader from '@main/ui/atoms/SectionHeader';
import Text, { FontSize, LineHeight } from '@main/ui/atoms/Text';
import Triangle from '@main/ui/atoms/Triangle';
import HorizontalScrollList from '@main/ui/molecules/HorizontalScrollList';
import { clickVisitArticlePageButton, clickVisitLessonPageButton, } from '@/utils/analytics/log-event';
var Component = function (_a) {
    var features = _a.features;
    return (<>
      {features.length > 0 && (<div>
          <SectionHeader caption="レッスンコース">
            <a href="/lesson_course" onClick={function () {
                clickVisitLessonPageButton('topmenu');
            }} className="flex items-center p-1 text-xs font-thin leading-none text-gray-500 no-underline">
              もっと見る
              <Triangle width={8} colorClass="bg-gray-500"/>
            </a>
          </SectionHeader>

          <HorizontalScrollList items={features.map(function (feature) { return (<a key={feature.id} href={"/features/".concat(feature.id)} className="block no-underline" onClick={function () {
                    return clickVisitArticlePageButton('recommended_lesson_course');
                }}>
                <div className="mb-2">
                  <Image url={feature.thumbnailUrl}/>
                </div>
                <div>
                  <Text text={feature.title} fontSize={FontSize.Small} lineHeight={LineHeight.Base}/>
                </div>
              </a>); })}/>
        </div>)}
    </>);
};
export default Component;
