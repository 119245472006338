import { Checkbox, Radio, RadioGroup } from '@chakra-ui/react';
import FormInputLayout from '@main/ui/components/FormInputLayout';
import FormSubmitButton from '@main/ui/components/FormSubmitButton';
import PageLayout from '@main/ui/components/PageLayout';
import PasswordInput from '@main/ui/components/PasswordInput';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import useHook from './hook';
var Component = function () {
    var _a = useHook(), email = _a.email, submit = _a.submit, control = _a.control, isSubmitting = _a.isSubmitting, isSubmitSuccessful = _a.isSubmitSuccessful;
    return (<PageLayout singleColumn caption="クラブ会員のお申し込み">
      {isSubmitSuccessful ? (<div className="my-2 rounded border border-blue-700 bg-blue-100 p-2 text-center text-blue-700">
          {email}に確認メールをお送りしました。
          <br />
          メールに記載の手順に沿って手続きを行ってください。
        </div>) : (<form noValidate onSubmit={submit} className="mt-5 rounded border-0 border-gray-300 sm:border sm:py-6 sm:px-8">
          <div className="grid gap-y-4">
            <div className="flex w-full gap-x-2 lg:gap-x-4">
              <div className="basis-1/2">
                <Controller name="lastName" control={control} render={function (_a) {
                var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
                return (<div>
                      <FormInputLayout {...field} type="text" label="姓" error={error} placeholder="卓球" isDirty={isDirty}/>
                    </div>);
            }}/>
              </div>

              <div className="basis-1/2">
                <Controller name="firstName" control={control} render={function (_a) {
                var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
                return (<div>
                      <FormInputLayout {...field} label="名" placeholder="太郎" type="text" error={error} isDirty={isDirty}/>
                    </div>);
            }}/>
              </div>
            </div>

            <div className="w-full">
              <Controller name="email" control={control} render={function (_a) {
                var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
                return (<div>
                    <FormInputLayout {...field} label="メールアドレス" placeholder="t.takkyu@shands.jp" type="email" error={error} isDirty={isDirty} autoComplete="email"/>
                  </div>);
            }}/>
            </div>

            <div className="w-full">
              <Controller name="password" control={control} render={function (_a) {
                var field = _a.field, _b = _a.fieldState, error = _b.error, isDirty = _b.isDirty;
                return (<div>
                    <PasswordInput {...field} label="パスワード" placeholder="******" subtitle="英数字6文字以上" error={error} isDirty={isDirty}/>
                  </div>);
            }}/>
            </div>

            <div className="mt-1 w-full">
              <Controller control={control} name="billingPlan" render={function (_a) {
                var error = _a.fieldState.error, _b = _a.field, onBlur = _b.onBlur, onChange = _b.onChange, value = _b.value;
                return (<>
                    <div>会員種別</div>
                    <RadioGroup value={value} className="mt-1 flex flex-col gap-y-1" onBlur={onBlur} onChange={onChange}>
                      {[
                        {
                            label: '一般',
                            value: 'billing_plan_club_standard',
                        },
                        {
                            label: 'プラチナ',
                            value: 'billing_plan_club_platinum',
                        },
                    ].map(function (option) { return (<label key={option.value} className={classnames('flex h-12 w-full cursor-pointer items-center rounded border-2 px-4', value === option.value
                            ? 'border-blue-900'
                            : 'border-gray-100')}>
                          <Radio value={option.value}/>

                          <div className="ml-2 flex h-full w-full items-center">
                            {option.label}
                          </div>
                        </label>); })}
                    </RadioGroup>

                    {error && (<div className="mt-1.5 text-xs text-red-500">
                        {error.message}
                      </div>)}
                  </>);
            }}/>
            </div>

            <div className="w-full">
              <Controller control={control} name="acceptTerms" render={function (_a) {
                var _b = _a.field, onBlur = _b.onBlur, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                return (<div>
                    <div>
                      <a target="_blank" rel="noreferrer" className="underline underline-offset-4" href="https://shands.notion.site/2460260ca5bd4d32856749935934c12e">
                        会員規約
                      </a>
                      への同意
                    </div>
                    <div className="mt-2 flex h-12 w-full rounded border-2 border-gray-100 bg-white">
                      <Checkbox onBlur={onBlur} checked={value} className="h-full w-full pl-4" onChange={function (event) { return onChange(event.target.checked); }}>
                        会員規約に同意します。
                      </Checkbox>
                    </div>

                    {error && (<div className="mt-1.5 text-xs text-red-500">
                        {error.message}
                      </div>)}
                  </div>);
            }}/>
            </div>

            {/* <p className="text-left text-base">
              次の画面でお支払い方法をご登録ください。
              お支払い方法のご登録後、選択していただいたプランの今月分の利用料が直ちに請求され、以降は毎月1日に当月分の利用料が請求されます。
            </p> */}

            <div className="flex w-full justify-center md:w-auto">
              <div className="h-12 w-full md:w-56">
                <FormSubmitButton text="次へ" isDisabled={isSubmitting} isSubmitting={isSubmitting}/>
              </div>
            </div>
          </div>
        </form>)}
    </PageLayout>);
};
export default Component;
